import { assign, isArray } from 'lodash';
import { Container, AnimatedSprite, Sprite } from '@/pixi';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { Lobby } from './Lobby';
import { Boost } from './Boost';

export class ControlsHeader {
  constructor({
    menuAsset, // Required
    onMenuClick, // Required, Function
    lobbyEnabled = true,
    lobbyAsset, // Required if lobbyEnabled is true
    lobbyParams, // Required if lobbyEnabled is true
    boostParams, // Required
    playTapSound, // Optional, Function
    appWidth = 1080,
    padding = 24,
  }) {
    this.menuAsset = menuAsset;
    this.onMenuClick = onMenuClick;
    this.lobbyEnabled = lobbyEnabled;
    this.lobbyAsset = lobbyAsset;
    this.lobbyParams = lobbyParams;
    this.boostParams = boostParams;
    this.appWidth = appWidth;
    this.padding = padding;
    this.playTapSound = playTapSound;

    this.container = new Container();
    this.uiMenu = undefined;
    this.uiBoost = undefined;
    this.uiLobby = undefined;
    this.lobbyDialog = undefined;

    this.setup();
    this.setPosition();
    this.setActions();
  }

  setup() {
    this.uiMenu = isArray(this.menuAsset.resource) ? new AnimatedSprite(this.menuAsset.resource) : new Sprite(this.menuAsset.resource);
    this.uiMenu.anchor.set(1, 0);
    this.uiMenu.x = this.appWidth - this.padding;
    this.uiMenu.y = this.padding;
    this.container.addChild(this.uiMenu);

    if (this.lobbyEnabled) {
      this.uiLobby = isArray(this.lobbyAsset.resource) ? new AnimatedSprite(this.lobbyAsset.resource) : new Sprite(this.lobbyAsset.resource);
      this.uiLobby.x = this.uiMenu.x - this.uiMenu.width - this.uiLobby.width - this.padding;
      this.uiLobby.y = this.padding;
      this.container.addChild(this.uiLobby);

      this.lobbyDialog = new Lobby(this.lobbyParams);
    }
    if (this.boostParams.boostTenantId) {
      this.uiBoost = new Boost(assign(this.boostParams, assign(this.boostParams, { padding: this.padding })));
    }
  }

  setPosition() {
    this.lobbyDialog?.setPosition();
    this.uiBoost?.setBadgePosition();
    this.uiBoost?.setModulesContainerPosition();
  }

  setActions() {
    this.uiMenu.eventMode = 'static';
    this.uiMenu.cursor = 'pointer';
    this.uiMenu.on('pointertap', () => {
      this.playTapSound?.();
      this.onMenuClick();
      triggerAnalyticsEvent({ name: 'MenuButtonClick' });
    });

    if (this.uiLobby) {
      this.uiLobby.eventMode = 'static';
      this.uiLobby.cursor = 'pointer';
      this.uiLobby.on('pointertap', () => {
        this.playTapSound?.();
        this.lobbyDialog.show();
        triggerAnalyticsEvent({ name: 'LobbyButtonClick' });
      });
    }
  }

  disableMenuClick() {
    this.uiMenu.eventMode = 'none';
    this.uiMenu.alpha = 0.5;
  }

  enableMenuClick() {
    this.uiMenu.eventMode = 'static';
    this.uiMenu.alpha = 1;
  }
}
