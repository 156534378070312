import { Container, Sprite } from '@/pixi';
import { getTextureIndexByChar } from '@/utility/Utility';
import { slotState } from './SlotState';

export class SlotNumberCurrency {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.currency = this.options.currency;
    this.characterTextures = this.options.assets.generalCounter.resource;

    this.create();
  }

  create() {
    const currencyCharacters = this.currency.split('');
    let lastPositionX = 0;

    currencyCharacters.forEach((char) => {
      const textureIndex = getTextureIndexByChar(this.characterTextures, char);
      const charSprite = new Sprite(this.characterTextures[textureIndex]);

      charSprite.x = lastPositionX;
      lastPositionX += charSprite.getBounds().width;

      this.container.addChild(charSprite);
    });
  }

  setScale(value) {
    this.container.scale.set(value);
  }

  hide() {
    this.container.visible = false;
  }
}
