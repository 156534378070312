import { Container, BitmapText } from '@/pixi';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { each, isEmpty, toUpper } from 'lodash';
import { slotState } from './SlotState';
import { SlotButton } from './SlotButton';
import { registerEventListener, triggerEvent } from '../utility/Utility';

export class SlotDialogWarning {
  constructor() {
    this.options = slotState.options;

    this.messageContainer = new Container();
    this.titleContainer = new Container();
    this.container = new Container();
    this.dialog = new Container();

    this.title = slotState.options.translations.resetWarningTitle;
    this.message = slotState.options.translations.resetWarningMessageBetAmount;
    this.messageBoldPart = slotState.options.translations.resetWarningMessageBoldPart;

    this.dialogPadding = this.options.uiDialogPadding;
    this.dialogHeight = slotState.container.height / 3;
    this.dialogWidth = slotState.container.width - this.dialogPadding * 2;

    this.lineEnd = undefined;

    this.buttons = [
      {
        text: slotState.options.translations.resetWarningContinueLabel,
        backgroundColor: this.options.colors.highlightBrighter,
        borderColor: this.options.colors.highlightBrighter,
        textColor: this.options.colors.highlightText,
        onClick: () => this.handleAction('continue'),
      },
      {
        text: slotState.options.translations.resetWarningCancelLabel,
        backgroundColor: '#000000',
        borderColor: this.options.colors.highlightBrighter,
        textColor: 0xFFFFFF,
        onClick: () => this.handleAction('cancel'),
      },
    ];

    this.setup();
  }

  setup() {
    this.dialogBackground = new Graphics();
    this.dialogBackground.beginFill('#040404');
    this.dialogBackground.drawRoundedRect(0, 0, this.dialogWidth, this.dialogHeight, 40);
    this.dialogBackground.endFill();
    this.dialogBackground.alpha = 1;

    this.dialog.addChild(this.dialogBackground);

    this.titleContainer.addChild(new BitmapText(this.title, {
      fontName: this.options.fontNameSemiBold,
      fontSize: 62,
      tint: 0xFFFFFF,
      align: 'center',
      maxWidth: this.dialogWidth,
    }));

    this.dialog.addChild(this.titleContainer);

    this.displayCorrectMessage();
    this.messageStyle();

    this.dialog.addChild(this.messageContainer);

    each(this.buttons, (button) => {
      this.dialog.addChild(this.createButton(button));
    });

    this.container.addChild(this.dialog);
  }

  setPosition() {
    const scale = slotState.container.scale.y;
    const appWidth = this.options.size().width / scale;
    const appHeight = this.options.size().height / scale;
    const rowsHeight = this.dialogHeight / 5;

    this.dialog.width = this.dialogWidth;
    this.dialog.height = this.dialogHeight;

    this.dialog.x = appWidth / 2 - this.dialogWidth / 2;
    this.dialog.y = appHeight / 2 - this.dialogHeight / 2;

    this.titleContainer.x = this.dialogWidth / 2 - this.titleContainer.width / 2;
    this.titleContainer.y = rowsHeight - this.titleContainer.height / 2;

    this.messageContainer.y = rowsHeight + this.messageContainer.height + this.dialogPadding;

    const buttons = this.dialog?.children?.slice(-this.buttons.length);
    each(buttons, (item, index) => {
      const currentButton = this.dialog.children.length - buttons.length + index;
      this.dialog.children[currentButton].x = index * item.width + this.dialogPadding * (index + 1.5);
      this.dialog.children[currentButton].y = this.dialogHeight - this.dialogPadding * 1.5 - item.height;
    });
  }

  messageStyle() {
    const firstLine = this.message.split('/')[0];
    const secondLine = this.message.split('/')[1].split(this.messageBoldPart);
    const separatedMessage = [`${firstLine}${this.lineEnd} `, ...secondLine];
    const styledMessage = [];

    each(separatedMessage, (text) => {
      const styledPart = { text: text === '' ? this.messageBoldPart : text,
        style: {
          fontSize: 48,
          tint: 0xFFFFFF,
          align: 'center',
          fontName: text === '' ? this.options.fontNameSemiBold : this.options.fontNameLight,
        } };

      styledMessage.push(styledPart);
    });

    each(styledMessage, (messagePart) => {
      this.messageContainer.addChild(new BitmapText(messagePart.text, messagePart.style));
    });

    const wordsSpacing = this.messageContainer.children[0].fontSize / 5;

    let { children } = this.messageContainer;

    children = this.handleTextOverflow(children, wordsSpacing);

    const secondLineWidth = ((children[1].text === ' ' ? 0 : children[1].textWidth)) + children[2].textWidth + wordsSpacing * 2;

    children[0].x = this.dialogWidth / 2 - children[0].textWidth / 2;

    children[1].y = children[1].textHeight + wordsSpacing;
    children[1].x = this.dialogWidth / 2 - secondLineWidth / 2;

    children[2].y = children[1].textHeight + wordsSpacing;
    children[2].x = children[1].x + children[1].textWidth + wordsSpacing;
  }

  displayCorrectMessage() {
    const isCollect = slotState.collect?.multiplier > 1;
    const isProgress = slotState.progress?.current?.unitValue > 0 && slotState.featureToPurchase !== 'buyProgress';

    if (isCollect && isProgress) {
      const messageType = slotState.featureToPurchase ? 'resetWarningMessageFeatureBuy' : 'resetWarningMessageBetAmount';
      this.message = slotState.options.translations[messageType];
      this.lineEnd = '/';
    } else if (isCollect) {
      const messageType = slotState.featureToPurchase ? 'resetWarningMessageCollectFeatureBuy' : 'resetWarningMessageCollectBetAmount';
      this.message = slotState.options.translations[messageType];
      this.messageBoldPart = slotState.options.translations.resetWarningMessageBoldPartCollect;
      this.lineEnd = '';
    } else if (isProgress) {
      const messageType = slotState.featureToPurchase ? 'resetWarningMessageProgressFeatureBuy' : 'resetWarningMessageProgressBetAmount';
      this.message = slotState.options.translations[messageType];
      this.lineEnd = '';
    }
  }

  createButton(button) {
    const btnWidthRow = (this.dialogWidth - this.dialogPadding * (this.buttons.length + 2)) / this.buttons.length;
    const btnWidth = btnWidthRow;

    const buttonContainer = new SlotButton({
      width: btnWidth,
      height: 115,
      radius: 20,
      fontName: this.options.fontNameSemiBold,
      backgroundColor: button.backgroundColor,
      borderWidth: 6,
      borderColor: button.borderColor,
      textColor: button.textColor,
      text: toUpper(button.text),
      letterSpacing: 5,
      textSize: 42,
      playTapSound: slotState.playTapSound?.(),
      onClick: button.onClick,
    });

    return buttonContainer.container;
  }

  handleAction(action) {
    triggerEvent('WarningDialogClosed', action);

    if (!slotState.featureToPurchase && action === 'continue') {
      slotState.warningDialogShown = true;
    }

    slotState.dialogWarning.hide?.();

    this.updateMessage();
  }

  handleTextOverflow(children, wordsSpacing) {
    let secondLineWidth = ((children[1].text === ' ' ? 0 : children[1].textWidth)) + children[2].textWidth + wordsSpacing * 2;
    let isTextOverflow = (children[0].textWidth > this.dialogWidth - this.dialogPadding * 2) || (secondLineWidth > this.dialogWidth - this.dialogPadding * 2);

    const childrenCopy = children;

    while (isTextOverflow) {
      each(childrenCopy, (text, index) => {
        childrenCopy[index].fontSize -= 1;
        childrenCopy[index].updateText();
      });

      secondLineWidth = ((children[1].text === ' ' ? 0 : children[1].textWidth)) + children[2].textWidth + wordsSpacing * 2;
      isTextOverflow = (childrenCopy[0].textWidth > this.dialogWidth - this.dialogPadding * 2) || (secondLineWidth > this.dialogWidth - this.dialogPadding * 2);
    }

    return childrenCopy;
  }

  async getWarningResponse() {
    const userResponse = await this.showWarningDialog();
    return userResponse !== false;
  }

  async showWarningDialog() {
    const isCollectBuy = slotState.featureToPurchase === 'buyCollect';
    const isProgressBuy = slotState.featureToPurchase === 'buyProgress' && (isEmpty(slotState.collect) || slotState.collect?.multiplier === 1);

    if (isCollectBuy || isProgressBuy) return undefined;

    let isUserApproved;

    if ((!slotState.warningDialogShown || slotState.featureToPurchase) && (slotState.progress?.current?.unitValue > 0 || slotState.collect?.multiplier > 1)) {
      this.updateMessage();

      slotState.dialogWarning.show();

      await new Promise((resolve) => {
        registerEventListener('WarningDialogClosed', (event) => {
          isUserApproved = event.detail === 'continue';
          resolve(true);
        });
      });

      slotState.resetFeaturesOnSpin = isUserApproved;
    }

    slotState.featureToPurchase = undefined;

    return isUserApproved;
  }

  updateMessage() {
    this.messageContainer.removeChildren();
    this.displayCorrectMessage();
    this.messageStyle();
  }
}
