import { Container, BitmapText, Graphics, Sprite, Texture } from '@/pixi';
import { triggerEvent } from '@/utility/Utility';

// Make sure to add Notification.container on app root container, and to call Notification.setPosition on app resize
export class Notification {
  constructor({
    buttonComponent,
    errorHappenedText,
    errorMessage,
    fontName,
    getAppHeight,
    getAppWidth,
    iconCloseAsset,
    isClosable,
    isReloadable,
    onClose,
    playTapSound,
    reloadBackgroundAsset,
    reloadGameToPlayText,
    reloadLogoAsset,
    reloadText,
    text,
    type = 'info', // Possible values: 'info', 'success', 'error', 'warning'
  }) {
    this.buttonComponent = buttonComponent;
    this.container = new Container();
    this.dialogPadding = 100;
    this.errorMessage = errorMessage;
    this.fontName = fontName;
    this.getAppHeight = getAppHeight;
    this.getAppWidth = getAppWidth;
    this.iconClose = undefined;
    this.iconCloseAsset = iconCloseAsset;
    this.isClosable = isClosable;
    this.isReloadable = isReloadable;
    this.notificationContainer = new Container();
    this.reloadBackgroundAsset = reloadBackgroundAsset;
    this.reloadLogo = undefined;
    this.reloadLogoAsset = reloadLogoAsset;
    this.snackBar = undefined;
    this.snackBarLabel = undefined;
    this.text = text;
    this.type = type;

    this.playTapSound = playTapSound;
    this.onClose = onClose;

    this.errorHappendLabel = undefined;
    this.reloadGameToPlayLabel = undefined;

    this.errorHappenedText = errorHappenedText;
    this.reloadGameToPlayText = reloadGameToPlayText;
    this.reloadText = reloadText;

    this.setup();
  }

  setup() {
    if (this.isReloadable) {
      this.createReloadPage();
    } else {
      this.createNotification();
    }

    this.setPosition();

    this.container.addChild(this.overlay, this.notificationContainer);
  }

  createNotification() {
    const padding = 25;
    const colors = {
      info: {
        background: 0xff7800,
        text: 0xffffff,
      },
      success: {
        background: 0x000000,
        text: 0xffffff,
      },
      error: {
        background: 0xd13131,
        text: 0xffffff,
      },
      warning: {
        background: 0xffe843,
        text: 0x000000,
      },
    };

    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0.3;

    this.snackBarLabel = new BitmapText(this.text, {
      fontName: this.fontName,
      fontSize: 48,
      tint: colors[this.type].text,
      align: 'center',
    });
    this.snackBarLabel.maxWidth = this.getAppWidth() - padding * 6;
    this.snackBarLabel.x = padding;
    this.snackBarLabel.y = padding;

    if (this.isClosable) {
      this.iconClose = new Sprite(this.iconCloseAsset.resource);
      this.iconClose.x = this.snackBarLabel.x + this.snackBarLabel.width + padding;
      this.iconClose.y = padding;
      this.iconClose.tint = colors[this.type].text;
    }

    const snackBarWidth = this.isClosable
      ? this.snackBarLabel.width + this.iconClose.width + padding * 3
      : this.snackBarLabel.width + padding * 2;

    this.snackBar = new Graphics()
      .beginFill(colors[this.type].background)
      .drawRoundedRect(0, 0, snackBarWidth, this.snackBarLabel.height + padding * 2, 20)
      .endFill();

    // Set actions

    this.overlay.eventMode = 'dynamic';

    if (this.isClosable) {
      this.iconClose.eventMode = 'static';
      this.iconClose.cursor = 'pointer';
      this.iconClose.on('pointertap', () => {
        this.playTapSound?.();
        this.onClose?.();
        this.container.destroy({ children: true });
        triggerEvent('NotificationClosed', { errorMessage: this.errorMessage });
      });
    }

    this.notificationContainer.addChild(this.snackBar, this.snackBarLabel);

    if (this.isClosable) {
      this.notificationContainer.addChild(this.iconClose);
    }
  }

  createReloadPage() {
    this.overlay = new Sprite(this.reloadBackgroundAsset.resource);

    this.reloadLogo = new Sprite(this.reloadLogoAsset.resource);
    this.reloadLogo.width = 400;
    this.reloadLogo.height = 400;

    this.button = this.createButton();

    this.errorHappendLabel = new BitmapText(this.errorHappenedText, {
      fontName: this.fontName,
      fontSize: 75,
    });

    this.reloadGameToPlayLabel = new BitmapText(this.reloadGameToPlayText, {
      fontName: this.fontName,
      fontSize: 35,
      tint: '#99a9b2',
    });

    this.notificationContainer.addChild(this.reloadLogo, this.errorHappendLabel, this.reloadGameToPlayLabel, this.button.container);
  }

  createButton() {
    const ButtonComponent = this.buttonComponent;

    const button = new ButtonComponent({
      width: this.getAppWidth() - this.dialogPadding,
      height: 200,
      radius: 20,
      textSize: 52,
      fontName: this.fontName,
      text: this.reloadText,
      backgroundColor: '#ffe843',
      textColor: '#0f0f0f',
      onClick: () => { this.reload(); },
    });

    return button;
  }

  setPosition() {
    if (!this.container.destroyed) {
      this.overlay.width = this.getAppWidth();
      this.overlay.height = this.getAppHeight();

      if (!this.isReloadable) {
        this.notificationContainer.x = this.getAppWidth() / 2 - this.snackBar.width / 2;
        this.notificationContainer.y = this.getAppHeight() / 1.5 - this.snackBar.height / 2;
      } else {
        this.reloadLogo.anchor.set(0.5);
        this.reloadLogo.x = this.getAppWidth() / 2;
        this.reloadLogo.y = this.getAppHeight() / 2 - this.dialogPadding * 4;

        this.errorHappendLabel.anchor.set(0.5);
        this.errorHappendLabel.x = this.getAppWidth() / 2;
        this.errorHappendLabel.y = this.getAppHeight() / 2 + this.dialogPadding;

        this.reloadGameToPlayLabel.anchor.set(0.5);
        this.reloadGameToPlayLabel.x = this.getAppWidth() / 2;
        this.reloadGameToPlayLabel.y = this.getAppHeight() / 2 + this.dialogPadding * 2;

        this.button.container.x = this.getAppWidth() - this.button.width - this.dialogPadding / 2;
        this.button.container.y = this.getAppHeight() / 2 + this.dialogPadding * 4;
      }
    }
  }

  reload() {
    window.location.reload();
  }
}
