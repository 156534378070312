import { RoundsHistoryTable } from './shared/RoundsHistoryTable';
import { slotState } from './SlotState';

export class SlotHistoryTable extends RoundsHistoryTable {
  constructor(tableParams) {
    super({
      tableParams,
      width: slotState.options.calcWidth,
      textColor: slotState.options.colors.text,
      fontNameLight: slotState.options.fontNameLight,
      fontNameBold: slotState.options.fontNameBold,
      translations: slotState.options.translations,
      currency: slotState.options.currency,
      currencyDisplayEnabled: slotState.options.currencyDisplayEnabled,
      playTapSound: () => { slotState.playTapSound(); },
      getMoneyLabel: (...data) => slotState.getMoneyLabel(...data),
    });
  }
}
