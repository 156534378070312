import { Container, BitmapText, Sprite, Texture } from '@/pixi';
import { Slider } from './Slider';
import { Button } from './Button';
import { Checkbox } from './Checkbox';

export class DialogContentAutoplay {
  #container;
  #spinValuesContainer;
  #stopLabel;
  #stopCheckbox;
  #lossLabel;
  #lossCheckbox;
  #lossSlider;
  #winLabel;
  #winCheckbox;
  #winSlider;
  #splitLine;
  #startButton;
  #padding;
  #fontName;
  #color;
  #highlightColor;
  #translations;
  #formatValue;
  #getBetAmount;
  #onStart;
  #buttonParams;
  #checkboxParams;
  #spinValueMargin;
  #fontSize;
  #contentWidth;
  #spinValues;
  #selectedSpinValue;
  #stopOnAnyWin;
  #lossLimit;
  #winLimit;
  #lossLimitEnabled;
  #winLimitEnabled;

  constructor({
    padding = 48,
    fontName = 'RobotoNormal',
    color = 0xdfe0e5,
    highlightColor = 0xffe843,
    stopLabelText = 'Stop on any win',
    lossLimitLabelText = 'Loss limit',
    winLimitLabelText = 'Win limit',
    startButtonText = 'Start',
    winLimitEnabled = true,
    lossLimitEnabled = true,
    formatValue, // Optional, Function
    getBetAmount, // Required, Function
    onStart, // Required, Function
    buttonParams, // Optional, Object
    checkboxParams, // Required, Object
  }) {
    this.#padding = padding;
    this.#fontName = fontName;
    this.#color = color;
    this.#highlightColor = highlightColor;
    this.#translations = {
      stopLabelText,
      lossLimitLabelText,
      winLimitLabelText,
      startButtonText,
    };
    this.#formatValue = formatValue;
    this.#getBetAmount = getBetAmount;
    this.#onStart = onStart;
    this.#buttonParams = buttonParams;
    this.#checkboxParams = checkboxParams;
    this.#spinValueMargin = 16;
    this.#fontSize = 48;
    this.#spinValues = [5, 10, 20, 50, 100];
    [this.#selectedSpinValue] = this.#spinValues;
    this.#stopOnAnyWin = false;
    this.#lossLimitEnabled = lossLimitEnabled;
    this.#winLimitEnabled = winLimitEnabled;

    if (lossLimitEnabled) {
      this.#lossLimit = {
        enabled: false,
        amount: 0,
      };
    }

    if (winLimitEnabled) {
      this.#winLimit = {
        enabled: false,
        amount: 0,
      };
    }

    // Setup
    const labelStyle = {
      fontName: this.#fontName,
      fontSize: this.#fontSize,
      tint: this.#color,
    };

    this.#container = new Container();
    this.#container.name = 'DialogContentAutoplay';

    this.#spinValuesContainer = new Container();
    this.#spinValuesContainer.x = this.#padding / 1.5;
    this.#spinValuesContainer.y = this.#padding * 1.5;
    this.#container.addChild(this.#spinValuesContainer);

    this.#stopLabel = new BitmapText(this.#translations.stopLabelText, labelStyle);
    this.#stopLabel.x = this.#spinValuesContainer.x * 2;
    this.#stopLabel.anchor.set(0, 0.5);
    this.#stopCheckbox = new Checkbox({
      ...this.#checkboxParams,
      onChange: this.#onStopCheckboxChange.bind(this),
    });
    this.#stopCheckbox.container.pivot.y = this.#stopCheckbox.container.height / 2;
    this.#container.addChild(this.#stopLabel, this.#stopCheckbox.container);

    if (lossLimitEnabled) {
      this.#lossLabel = new BitmapText(this.#translations.lossLimitLabelText, labelStyle);
      this.#lossLabel.x = this.#stopLabel.x;
      this.#lossLabel.anchor.set(0, 0.5);
      this.#lossCheckbox = new Checkbox({
        ...this.#checkboxParams,
        onChange: this.#onLossCheckboxChange.bind(this),
      });
      this.#lossCheckbox.container.pivot.y = this.#lossCheckbox.container.height / 2;
      this.#container.addChild(this.#lossLabel, this.#lossCheckbox.container);
    }

    if (winLimitEnabled) {
      this.#winLabel = new BitmapText(this.#translations.winLimitLabelText, labelStyle);
      this.#winLabel.x = lossLimitEnabled ? this.#lossLabel.x : this.#stopLabel.x;
      this.#winLabel.anchor.set(0, 0.5);
      this.#winCheckbox = new Checkbox({
        ...this.#checkboxParams,
        onChange: this.#onWinCheckboxChange.bind(this),
      });
      this.#winCheckbox.container.pivot.y = this.#winCheckbox.container.height / 2;
      this.#container.addChild(this.#winLabel, this.#winCheckbox.container);
    }

    this.#splitLine = new Sprite(Texture.WHITE);
    this.#splitLine.height = 2;
    this.#splitLine.alpha = 0.1;
    this.#container.addChild(this.#splitLine);

    this.#startButton = new Button({
      ...this.#buttonParams,
      text: this.#translations.startButtonText,
      textSize: 52,
      width: 600,
      height: 150,
      radius: 20,
      onClick: this.#onStartButtonClick.bind(this),
    });
    this.#startButton.container.pivot.x = this.#startButton.container.width / 2;
    this.#container.addChild(this.#startButton.container);
  }

  get container() {
    return this.#container;
  }

  #setValue(value) {
    this.#selectedSpinValue = value;
    this.#createValuesButtons();
  }

  #onSpinValueClick(value) {
    this.#setValue(value);

    if (this.#lossLimitEnabled) {
      this.#lossLimit.amount = 0;
      this.#createLossSlider();
    }

    if (this.#winLimitEnabled) {
      this.#winLimit.amount = 0;
      this.#createWinSlider();
    }
  }

  #onStopCheckboxChange() {
    this.#stopOnAnyWin = !this.#stopOnAnyWin;
  }

  #onLossCheckboxChange() {
    this.#lossLimit.enabled = !this.#lossLimit.enabled;
    this.#lossSlider.setDisabledState(!this.#lossLimit.enabled);
  }

  #onWinCheckboxChange() {
    this.#winLimit.enabled = !this.#winLimit.enabled;
    this.#winSlider.setDisabledState(!this.#winLimit.enabled);
  }

  #onStartButtonClick() {
    this.#onStart(this.#selectedSpinValue, this.#stopOnAnyWin, this.#lossLimit, this.#winLimit);

    // Call hide from Dialog.js
    this.dialog.hide();
  }

  #createValuesButtons() {
    let lastPositionX = 0;
    const buttonWidth = (this.#contentWidth - this.#spinValuesContainer.x * 2 - this.#spinValueMargin * (this.#spinValues.length - 1))
      / this.#spinValues.length;

    // Destroy all buttons
    while (this.#spinValuesContainer.children[0]) {
      this.#spinValuesContainer.children[0].destroy({ children: true, texture: true });
    }

    this.#spinValues.forEach((value, index) => {
      const color = value === this.#selectedSpinValue ? this.#highlightColor : this.#color;
      const button = new Button({
        ...this.#buttonParams,
        text: value,
        textSize: this.#fontSize,
        width: buttonWidth,
        height: buttonWidth * 0.55,
        radius: 48,
        backgroundColor: 0x000000,
        textColor: color,
        borderWidth: 3,
        borderColor: color,
        onClick: value !== this.#selectedSpinValue ? () => this.#onSpinValueClick(value) : undefined,
      });

      button.container.x = lastPositionX + (index > 0 ? this.#spinValueMargin : 0);
      lastPositionX = button.container.x + button.width;

      this.#spinValuesContainer.addChild(button.container);
    });
  }

  #createLossSlider() {
    const max = this.#getBetAmount() * this.#selectedSpinValue;

    this.#lossSlider?.container.destroy({ texture: true, children: true });
    this.#lossSlider = new Slider({
      value: this.#lossLimit.amount,
      max,
      step: max / 50,
      trackWidth: this.#spinValuesContainer.width - this.#spinValuesContainer.x * 2,
      disabled: !this.#lossLimit.enabled,
      trackBgColor: 0x1b1b1b,
      highlightColor: this.#highlightColor,
      formatValue: this.#formatValue,
      onChange: (value) => {
        this.#lossLimit.amount = value;
      },
    });
    this.#lossSlider.container.x = this.#lossLabel.x;
    this.#lossSlider.container.y = this.#lossLabel.y + this.#lossLabel.height / 2 + this.#padding / 2;
    this.#container.addChild(this.#lossSlider.container);
  }

  #createWinSlider() {
    const max = this.#getBetAmount() * this.#selectedSpinValue * 100;

    this.#winSlider?.container.destroy({ texture: true, children: true });
    this.#winSlider = new Slider({
      value: this.#winLimit.amount,
      max,
      step: max / 50,
      trackWidth: this.#spinValuesContainer.width - this.#spinValuesContainer.x * 2,
      disabled: !this.#winLimit.enabled,
      trackBgColor: 0x1b1b1b,
      highlightColor: this.#highlightColor,
      formatValue: this.#formatValue,
      onChange: (value) => {
        this.#winLimit.amount = value;
      },
    });
    this.#winSlider.container.x = this.#winLabel.x;
    this.#winSlider.container.y = this.#winLabel.y + this.#winLabel.height / 2 + this.#padding / 2;
    this.#container.addChild(this.#winSlider.container);
  }

  setPosition({ width }) {
    this.#contentWidth = width;

    this.#createValuesButtons();

    this.#stopLabel.y = this.#spinValuesContainer.y + this.#spinValuesContainer.height + this.#padding * 1.5 + this.#stopLabel.height / 2;
    this.#stopCheckbox.container.x = this.#contentWidth - this.#spinValuesContainer.x * 2 - this.#stopCheckbox.container.width;
    this.#stopCheckbox.container.y = this.#stopLabel.y;

    if (this.#lossLimitEnabled) {
      this.#lossLabel.y = this.#stopLabel.y + this.#stopLabel.height + this.#padding;
      this.#lossCheckbox.container.x = this.#stopCheckbox.container.x;
      this.#lossCheckbox.container.y = this.#lossLabel.y;

      this.#createLossSlider();
    }

    if (this.#winLimitEnabled) {
      this.#winLabel.y = this.#lossLimitEnabled
        ? this.#lossSlider.container.y + this.#lossSlider.container.height + this.#winLabel.height / 2 + this.#padding
        : this.#stopLabel.y + this.#stopLabel.height + this.#padding;
      this.#winCheckbox.container.x = this.#lossLimitEnabled ? this.#lossCheckbox.container.x : this.#stopCheckbox.container.x;
      this.#winCheckbox.container.y = this.#winLabel.y;

      this.#createWinSlider();
    }

    if (this.#lossLimitEnabled || this.#winLimitEnabled) {
      this.#splitLine.y = this.#winLimitEnabled
        ? this.#winSlider.container.y + this.#winSlider.container.height + this.#padding * 1.5
        : this.#lossSlider.container.y + this.#lossSlider.container.height + this.#padding * 1.5;
      this.#splitLine.width = this.#contentWidth;
    }

    this.#startButton.container.x = this.#contentWidth / 2;
    this.#startButton.container.y = (this.#lossLimitEnabled || this.#winLimitEnabled)
      ? this.#splitLine.y + this.#padding
      : this.#stopLabel.y + this.#padding;
  }

  show() {
    if (this.#selectedSpinValue !== this.#spinValues[0]) {
      this.#setValue(this.#spinValues[0]);
    }

    this.#stopCheckbox.set(false);
    this.#stopOnAnyWin = false;

    if (this.#lossLimitEnabled) {
      this.#lossCheckbox.set(false);
      this.#lossLimit.enabled = false;
      this.#lossLimit.amount = 0;
      this.#createLossSlider();
    }

    if (this.#winLimitEnabled) {
      this.#winCheckbox.set(false);
      this.#winLimit.enabled = false;
      this.#winLimit.amount = 0;
      this.#createWinSlider();
    }
  }
}
