import { Container, Sprite, AnimatedSprite, BitmapText, Texture, Assets } from '@/pixi';
import { triggerAnalyticsEvent, triggerEvent, isSpriteVideo } from '@/utility/Utility';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';

export class SlotOnboardScreen {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.background = undefined;
    this.onboardIntro = undefined;
    this.uiOnboardSound = undefined;
    this.label = undefined;
    this.labelText = `${slotState.options.translations.onboardScreenLabel.toUpperCase()}`;
    this.textSize = 44;

    this.setup();
    this.setActions();
  }

  setup() {
    const { onboardIntro, uiOnboardSound } = this.options.assets;
    const textStyle = {
      fontName: this.options.fontNameBold,
      fontSize: this.textSize,
      tint: this.options.colors.highlightText,
    };

    this.background = new Sprite(Texture.WHITE);
    this.background.tint = this.options.colors.highlight;
    this.container.addChild(this.background);

    // Show video or image if available
    if (onboardIntro?.resource) {
      this.onboardIntro = new Sprite(onboardIntro.resource);

      if (isSpriteVideo(this.onboardIntro)) {
        const { source } = this.onboardIntro.texture.baseTexture.resource;

        source.loop = true;
        source.play();
      }
    // Fallback if video and image are not avilable
    } else {
      this.onboardIntro = new Container();
    }

    this.container.addChild(this.onboardIntro);

    this.uiOnboardSound = new AnimatedSprite(uiOnboardSound.resource);
    this.uiOnboardSound.x = this.options.calcWidth / 2;
    this.uiOnboardSound.anchor.set(0.5);
    this.uiOnboardSound.gotoAndStop(audio.isSoundOff ? 1 : 0);
    this.container.addChild(this.uiOnboardSound);

    this.label = new BitmapText(this.labelText, textStyle);
    this.label.x = this.options.calcWidth / 2;
    this.label.anchor.set(0.5);
    this.container.addChild(this.label);
  }

  setActions() {
    this.uiOnboardSound.eventMode = 'static';
    this.uiOnboardSound.cursor = 'pointer';
    this.uiOnboardSound.on('pointertap', this.toggleMute.bind(this));

    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
    this.container.on('pointertap', this.closeOnboardScreen.bind(this));
  }

  // Called from Slot.js on init and after resize
  setPosition() {
    const scale = this.container.parent.scale.y;
    const { width, height } = this.options.size();
    const backgroundWidth = width / scale;
    const backgroundHeight = height / scale;
    const aspectRatio = height / width;

    this.background.width = backgroundWidth;
    this.background.height = backgroundHeight;

    if (aspectRatio < 1.777) {
      // Rescale and center onboardIntro
      this.onboardIntro.scale.set(aspectRatio * 0.55);
      this.onboardIntro.x = (backgroundWidth - this.onboardIntro.width) / 2;
      this.uiOnboardSound.scale.set(0.8);
      this.label.scale.set(0.8);
    } else {
      // Set default
      this.onboardIntro.scale.set(1);
      this.onboardIntro.x = 0;
      this.uiOnboardSound.scale.set(1);
      this.label.scale.set(1);
    }

    const bottomAreaHeight = backgroundHeight - this.onboardIntro.height;
    this.uiOnboardSound.y = this.onboardIntro.height + bottomAreaHeight / 2 - this.uiOnboardSound.height * 0.2;

    this.label.y = this.uiOnboardSound.y + this.uiOnboardSound.height * 0.8;
  }

  toggleMute(e) {
    e.stopPropagation();

    triggerAnalyticsEvent({
      name: audio.isSoundOff ? 'SoundButtonOnClick' : 'SoundButtonOffClick',
    });

    audio.toggleMute();
    slotState.playTapSound();
    this.uiOnboardSound.gotoAndStop(audio.isSoundOff ? 1 : 0);
  }

  closeOnboardScreen() {
    slotState.playTapSound();

    this.container.destroy({ children: true });

    Assets.unload(this.options.assets.onboardIntro.name);

    slotState.onboardScreen = undefined;

    triggerEvent('OnboardScreenChanged', {
      isOpened: false,
    });
    if (!slotState.dialog && !slotState.activePromotion) {
      triggerEvent('ShowBoostBadge');
    }
  }
}
