import animate from 'gsap';
import { clone } from 'lodash';
import * as particles from '@pixi/particle-emitter';
import { SlotWinlineTrailEmitter } from './SlotWinlineTrailEmitter';
import { SlotWinlineAmount } from './SlotWinlineAmount';
import { slotState } from './SlotState';

export class SlotWinlineTrail {
  constructor(container, amountContainer) {
    this.timeline = undefined;
    this.emitter = undefined;
    this.options = slotState.options;
    this.container = container;
    this.amountContainer = amountContainer;
    this.create();
  }

  create() {
    this.emitter = new particles.Emitter(
      this.container,
      {
        autoUpdate: true,
        emit: false,
        ...SlotWinlineTrailEmitter(
          this.options.winlineParticleType,
          this.options.winlineColors,
        ),
      },
    );
  }

  async play(winLine, positions, speed) {
    const that = this;

    return new Promise((resolve) => {
      this.timeline = animate.timeline({
        paused: true,
        onStart() {
          that.amountContainer.alpha = 1;
        },
        onUpdate() {
          const progress = this.progress();
          that.emitter.emit = progress > 0.025 && progress < 0.9;
        },
      });

      const positionsCount = positions.length;
      const animatedPositions = positions.slice(1);
      const animatedPositionsDuration = speed / animatedPositions.length;

      animatedPositions.forEach((position, positionIndex) => {
        const animatePosition = clone(positionIndex > 0 ? positions[positionIndex] : positions[0]);

        this.timeline.fromTo(animatePosition, {
          x: positionIndex > 0 ? positions[positionIndex].x : positions[0].x,
          y: positionIndex > 0 ? positions[positionIndex].y : positions[0].y,
        }, {
          x: position.x,
          y: position.y,
          duration: animatedPositionsDuration,
          onStart() {
            if (positionIndex === Math.floor(animatedPositions.length / 2)) {
              const pointMiddle = clone(animatedPositions[positionIndex - 1]);
              /*
              Calculate middle position if even reels.
              */
              if (positionsCount % 2 === 0) {
                const pointNext = animatedPositions[positionIndex];
                pointMiddle.x += (pointNext.x - pointMiddle.x) / 2;
              }

              that.amountContainer.addChild(
                new SlotWinlineAmount({
                  options: that.options,
                  winAmount: winLine.winLineCollectAmount > 0 ? winLine.winLineCollectAmount : winLine.winLineAmount,
                  position: pointMiddle,
                  scale: 0.25,
                  isWithBackground: false,
                }).container,
              );
            }
          },
          onUpdate() {
            const target = this.targets()[0];
            that.emitter.updateSpawnPos(target.x, target.y - that.container.y);
          },
        });
      });

      this.timeline.to(that.amountContainer, {
        duration: 0.1,
        pixi: {
          alpha: 0,
        },
        onStart() {
          that.emitter.updateSpawnPos(positions[0].x, positions[0].y);
        },
        onComplete() {
          that.amountContainer.removeChildren();
          resolve(true);
        },
      });

      this.container.visible = true;
      this.timeline.play();
    });
  }

  stop() {
    this.container.visible = false;
    this.emitter.emit = false;

    if (this.timeline && this.timeline.isActive()) {
      this.timeline.kill();
      this.timeline = undefined;
    }
  }
}
