import { assign, clone, defaultTo, each, findIndex, isEmpty, isNil, map, merge, startsWith, uniq, isEqual } from 'lodash';
import { SlotType } from '../models';
import { fixMultiSprite } from './Utility';
import { Parser } from './Parser';

export class SlotParser extends Parser {
  constructor(params, defaults) {
    super(params, defaults);
    this.bonusSymbol = -1;
    this.bonusSymbolPrefix = 'b';
    this.collectSymbols = undefined;
    this.wildSymbol = 0;
    this.mergeOverrides = true;
    this.finalConfig = null;
    this.finalAssets = null;

    this.parseSlotState();
  }

  parseSlotState() {
    if (isNil(this.params.state)) {
      this.options = this.params;
      return this.options;
    }

    let type = SlotType.Normal;
    const options = clone(this.params);
    const { activePromotion, availableFreeRounds, collect, dynamicBonusReels, freeRoundBetAmount, freeRoundMultiplier, freeRoundWinAmount, isAvailableFreeRound, isIncreasingFreeRoundMultiplier, player, progress,
      state, totalFreeRoundsCount } = options.state;
    const { config, settings } = options.state.game;
    const showMultiplier = isAvailableFreeRound || freeRoundMultiplier > 1;
    const showCollectMultiplier = !isNil(config.collect);

    this.finalConfig = {
      activePaylines: settings.paylinesNumber,
      bonus: config.bonus,
      bonusSymbol: this.bonusSymbol,
      collect: config.collect,
      dynamicMultiplierSymbols: defaultTo(config.bonus?.dynamicMultiplierSymbols, []),
      isBonusBuyEnabled: options.state.isBonusBuyEnabled,
      isBonusSymbol: !isNil(config.bonus),
      isCascade: defaultTo(config.isCascade, defaultTo(options.isCascade, false)),
      isProgressSymbol: !isNil(progress) && !isEmpty(progress) && !isEmpty(progress.current.unitSymbols),
      isProgressBuyEnabled: options.state.isProgressBuyEnabled,
      isWildSymbol: findIndex(config.paytable, { symbol: this.wildSymbol }) > -1,
      isWinGradingSupported: false,
      isCollectSymbol: !isNil(config.collect),
      isCollectBuyEnabled: options.state.isCollectBuyEnabled,
      isIncreasingFreeRoundMultiplier,
      jackpot: config.jackpot,
      paytable: config.paytable,
      paylineSet: config.paylineSet,
      reels: config.reels,
      rows: config.rows,
      stops: config.stops,
      symbols: config.symbols,
      symbolsList: [],
      symbolsListProgress: [],
      symbolsListTrash: [],
      symbolsListNoDetail: [-1, -2, -3, -4],
      wildSymbol: this.wildSymbol,
      isOpenBonus: options.state.isOpenBonus,
      wildMultiplier: config.wildMultiplier,
    };

    if (this.finalConfig.isProgressSymbol) {
      this.finalConfig.symbolsList.push(...progress.current.unitSymbols);
      this.finalConfig.symbolsListProgress.push(...progress.current.unitSymbols);
      this.finalConfig.symbolsListNoDetail.push(...progress.current.unitSymbols);
    }

    if (this.finalConfig.isCollectSymbol) {
      type = SlotType.Collect;
      this.collectSymbols = config.collect;

      this.finalConfig.symbolsList.push(config.collect.cashSymbol);
      this.finalConfig.symbolsList.push(config.collect.collectSymbol);

      /*
      Ignore collect multiplier symbol if no multiplier range defined,
      since it's not present in the distribution anyways
      */
      if (!isEqual(config.collect.multiplierRange, [0, 0])) {
        this.finalConfig.symbolsList.push(config.collect.multiplierSymbol);
      }
    }

    if (this.finalConfig.isBonusSymbol) {
      this.finalConfig.symbolsList.push(this.bonusSymbol);
      this.finalConfig.bonusSymbolWaitOn = config.bonus.symbolsMin - 1;
    }

    if (this.finalConfig.isWildSymbol) {
      this.finalConfig.symbolsList.push(this.wildSymbol);
    }

    if (this.finalConfig.isCascade) {
      type = SlotType.Cascade;
    }

    this.finalConfig.specialSymbols = this.finalConfig.symbolsList.length;
    for (let i = 1; i <= this.finalConfig.symbols - this.finalConfig.specialSymbols; i += 1) {
      this.finalConfig.symbolsList.push(i);
    }

    this.finalConfig.symbolsListTrash = this.finalConfig.symbolsList.filter((symbol) => {
      const isNotDynamic = symbol >= 0 && this.finalConfig.dynamicMultiplierSymbols.indexOf(symbol) < 0;
      const isNotInPaytable = findIndex(this.finalConfig.paytable, (paytable) => paytable.symbol === symbol) < 0;

      return isNotDynamic && isNotInPaytable;
    });

    if (this.finalConfig.symbolsListTrash.length > 0) {
      this.finalConfig.symbolsListNoDetail.push(...this.finalConfig.symbolsListTrash);
    }

    if (this.finalConfig.dynamicMultiplierSymbols.length > 0) {
      this.finalConfig.symbolsListNoDetail.push(...this.finalConfig.dynamicMultiplierSymbols);
    }

    fixMultiSprite(options.resources);

    this.finalAssets = {
      /* Common local resources */
      particleLine: options.resources.particleLine,
      particleSpark: options.resources.particleSpark,
      /* Spritesheet resources */
      icons: this.getResource(options, 'p2_icons'),
      progress: this.getResource(options, 'p2_progress-0'),
      grading: this.getResource(options, 'p2_grading-0'),
      ui: this.getResource(options, 'p2_assets-0'),
      uiOverlay: this.getResource(options, 'p2_assets_overlay-0'),
      /* Sounds and video resources */
      soundAmbient: options.resources.slotAmbient,
      soundAmbientFree: options.resources.freeSpinsLoop,
      soundBonusMultiply: defaultTo(options.resources.freeSpinsMultiply, options.resources.reelsMultiplierHit),
      soundBonusWin: options.resources.slotBonusGameWin,
      soundProgressComplete: defaultTo(options.resources.progressComplete, options.resources.progressCompleteDefault),
      soundProgressScale: defaultTo(options.resources.progressScale, options.resources.progressScaleDefault),
      soundProgressWin: defaultTo(options.resources.progressWin, options.resources.progressWinDefault),
      soundProgressMove: defaultTo(options.resources.progressMove, options.resources.progressMoveDefault),
      soundMultiplierScale: defaultTo(options.resources.multiplierScale, options.resources.progressScale),
      soundMultiplierMove: defaultTo(options.resources.multiplierMove, options.resources.progressMove),
      soundMultiplierWin: defaultTo(options.resources.multiplierWin, options.resources.progressWin),
      soundReelBonusWait: defaultTo(options.resources.reelBonusWait, options.resources.reelBonusWaitDefault),
      soundReelBonusShow: options.resources.slotBonusGameShow,
      soundReelMultiplierHit: options.resources.reelsMultiplierHit,
      soundReelSpin: defaultTo(options.resources.slotReelsSpinning, options.resources.reelSpinDefault),
      soundReelStop: options.resources.slotReelStop,
      soundReelWinline: options.resources.slotWinline,
      soundReelsStart: options.resources.slotSpinStart,
      soundReelsStop: options.resources.slotSpinStop,
      soundTap: options.resources.slotClick,
      soundWinGradingLoop: options.resources.winGradingLoop,
      soundWinGradingTitleHit: options.resources.winGradingTitleHit,
      soundWinGradingOutro: options.resources.winGradingOutro,
      soundFreeSpinsIntro: options.resources.freeSpinsPopupIntro,
      soundFreeSpinsOutro: options.resources.freeSpinsPopupOutro,
      soundPickPrizeIntro: options.resources.slotBonusGameStart,
      soundPickPrizeOutro: options.resources.slotBonusGameEnd,
      /* Special resources with multiple animations or textures */
      generalCounter: [],
      reelBonus: [],
      symbolHit: [],
      symbols: [],
      bonusBuy: [],
      winGrades: [],
    };

    /*
    Set resources textures from ui to nested
    resources node.
    */
    if (this.finalAssets.ui) {
      assign(this.finalAssets.ui, {
        resources: map(this.finalAssets.ui.resource.textures, (texture, textureKey) => ({
          name: textureKey.replace('.png', ''),
          resource: texture,
        })),
      });
    }

    assign(this.finalAssets, {
      /* Image and video resources from ui or fallback to specific file. */
      bonusBuyBackground: this.getResource(options, 'bonus_buy_background'),
      generalComma: this.getResource(options, 'general_comma', this.finalAssets),
      generalDot: this.getResource(options, 'general_dot', this.finalAssets),
      generalMultiplier: this.getResource(options, 'general_multi', this.finalAssets),
      generalPlus: this.getResource(options, 'general_plus', this.finalAssets),
      logo: this.getResource(options, 'logo', this.finalAssets),
      progressBar: this.getResource(options, 'progress_bar', this.finalAssets, 'progress'),
      reelsBackground: this.getResource(options, 'reels_background', this.finalAssets),
      reelsBackgroundMask: this.getResource(options, 'reels_background_mask', this.finalAssets),
      reelsBackgroundFree: this.getResource(options, 'fs_reels_background', this.finalAssets, undefined, true),
      reelsFooterBackground: this.getResource(options, 'message_background', this.finalAssets),
      reelsFooterBackgroundFree: this.getResource(options, 'fs_message_background', this.finalAssets, undefined, true),
      reelsMultiplierBackground: this.getResource(options, 'multiplier_background', this.finalAssets),
      reelsMultiplierCollectBackground: this.getResource(options, 'multiplier_collect_background', this.finalAssets),
      symbolDetailBackground: this.getResource(options, 'symbol_detail_background', this.finalAssets),
      videoBackground: defaultTo(this.getResource(options, 'background_animated'), this.getResource(options, 'background', this.finalAssets)),
      videoBackgroundFree: defaultTo(this.getResource(options, 'free_spins_background_animated'), defaultTo(this.getResource(options, 'free_spins_background', this.finalAssets), this.getResource(options, 'background', this.finalAssets))),
      onboardIntro: defaultTo(this.getResource(options, 'onboardIntro'), this.getResource(options, 'onboardIntroBackground')),
      freeSpinsIntroBackground: this.getResource(options, 'free_spins_intro_background'),
      freeSpinsIntroBackground2: this.getResource(options, 'free_spins_intro_background_1'),
      freeSpinsIntroBackground3: this.getResource(options, 'free_spins_intro_background_2'),
      freeSpinsOutroBackground: defaultTo(this.getResource(options, 'free_spins_outro_background'), this.getResource(options, 'free_spins_intro_background')),
      pickPrizeIntroBackground: this.getResource(options, 'pick_prize_intro_background'),
      pickPrizeOutroBackground: defaultTo(this.getResource(options, 'pick_prize_outro_background'), this.getResource(options, 'pick_prize_intro_background')),
      /* Image animation resources. */
      iconClose: this.getResource(options, 'iconClose', this.finalAssets, 'icons', true),
      iconHistory: this.getResource(options, 'iconHistory', this.finalAssets, 'icons', true),
      iconInfo: this.getResource(options, 'iconInfo', this.finalAssets, 'icons', true),
      iconOff: this.getResource(options, 'iconOff', this.finalAssets, 'icons', true),
      iconOn: this.getResource(options, 'iconOn', this.finalAssets, 'icons', true),
      iconPaytable: this.getResource(options, 'iconPaytable', this.finalAssets, 'icons', true),
      iconSettings: this.getResource(options, 'iconSettings', this.finalAssets, 'icons', true),
      generalCounter: this.getResourceAnimation(this.finalAssets, 'general_counter'),
      reelBonus: this.getResourceAnimation(this.finalAssets, 'reel_bonus_overlay'),
      symbolHit: this.getResourceAnimation(this.finalAssets, 'symbol_hit_overlay'),
      totalWinBackground: this.getResourceAnimation(this.finalAssets, 'total_win_background'),
      uiBackground: this.getResourceAnimation(this.finalAssets, 'uiShadowBackground'),
      uiAutoplay: this.getResourceAnimation(this.finalAssets, 'uiAutoplay'),
      uiAutoplayStop: this.getResourceAnimation(this.finalAssets, 'uiAutoplayStop'),
      uiBalanceIcon: this.getResourceAnimation(this.finalAssets, 'uiBalanceIcon'),
      uiBet: this.getResourceAnimation(this.finalAssets, 'uiBet'),
      uiBetIcon: this.getResourceAnimation(this.finalAssets, 'uiBetIcon'),
      uiBuy: this.getResourceAnimation(this.finalAssets, 'uiBuy'),
      uiLobby: this.getResourceAnimation(this.finalAssets, 'uiLobby'),
      uiMenu: this.getResourceAnimation(this.finalAssets, 'uiMenu'),
      uiSpin: this.getResourceAnimation(this.finalAssets, 'uiSpin'),
      uiOnboardSound: this.getResourceAnimation(this.finalAssets, 'uiOnboardSound'),
      pickPrizeTitle: this.getResourceAnimation(this.finalAssets, 'pick_prize_title'),
    });

    this.parseSlotSymbols();
    this.parseWinGrades();
    this.parseBonusBuy(options);

    if (this.mergeOverrides && options.config) {
      this.finalConfig = merge(this.finalConfig, options.config);
    }

    if (this.mergeOverrides && options.assets) {
      this.finalAssets = merge(this.finalAssets, options.assets);
    }

    delete options.state;
    delete options.resources;

    this.options = merge(this.defaults, assign(options, {
      type,
      assets: this.finalAssets,
      config: this.finalConfig,
      state,
      settings,
      collect: isEmpty(collect) ? undefined : collect,
      player,
      progress: isEmpty(progress) ? undefined : progress,
      showMultiplier,
      showCollectMultiplier,
      multiplierValue: freeRoundMultiplier,
      activePromotion,
      availableFreeRounds,
      totalFreeRoundsCount,
      freeRoundBetAmount,
      freeRoundWinAmount,
      dynamicBonusReels,
    }));

    return this.options;
  }

  parseSlotSymbols() {
    this.finalConfig.symbolsList.forEach((symbol) => {
      let symbolPrefix = symbol.toString();

      if (symbol === this.bonusSymbol) {
        symbolPrefix = this.bonusSymbolPrefix;
      }

      if (this.collectSymbols) {
        if (symbol === this.collectSymbols.collectSymbol) {
          symbolPrefix = 'c';
        } else if (symbol === this.collectSymbols.cashSymbol) {
          symbolPrefix = 'cc';
        } else if (symbol === this.collectSymbols.multiplierSymbol) {
          symbolPrefix = 'cm';
        }
      }

      if (this.finalConfig.symbolsListProgress && this.finalConfig.symbolsListProgress.indexOf(symbol) > -1) {
        symbolPrefix = `p${symbol}`;
      }

      const staticPrefix = `symbol_${symbolPrefix}`;
      const staticImage = `symbol_${symbolPrefix}.png`;
      const sequencePrefix = `${staticPrefix}_`;
      const assetsResource = this.finalAssets.ui.resource;
      const assets = [];
      const animation = assetsResource.animations[staticPrefix];

      each(this.finalAssets.ui.resources, (resource) => {
        const isSequence = startsWith(resource.name, sequencePrefix);

        if (isSequence) {
          const sortIndex = resource.name.split(sequencePrefix)[1];
          assign(resource, { sortIndex: Number(sortIndex) });
          assets.push(resource);
        }
      });

      if (assets.length <= 0 && assetsResource.textures[staticImage]) {
        assets.push({
          name: staticPrefix,
          resource: assetsResource.textures[staticImage],
        });
      }

      const symbolAsset = {
        value: symbol,
        animation,
        assets,
      };

      if (this.collectSymbols && symbol === this.collectSymbols.collectSymbol && assetsResource.animations.symbol_cw) {
        assign(symbolAsset, {
          animationWin: assetsResource.animations.symbol_cw,
        });
      }

      this.finalAssets.symbols.push(symbolAsset);
    });
  }

  parseWinGrades() {
    this.finalAssets.winGrades.push(
      this.getResourceAnimation(this.finalAssets, 'grading_title_big'),
      this.getResourceAnimation(this.finalAssets, 'grading_title_mega'),
      this.getResourceAnimation(this.finalAssets, 'grading_title_ultra'),
      this.getResourceAnimation(this.finalAssets, 'grading_title_sensation'),
    );
    this.finalAssets.winCoin = this.getResourceAnimation(this.finalAssets, 'grading_coin');
    this.finalConfig.isWinGradingSupported = uniq(this.finalAssets.winGrades).length === 4;
  }

  parseBonusBuy(options) {
    if (this.finalConfig && this.finalConfig.bonus && this.finalConfig.bonus.multipliers) {
      const symbolsMap = [null, null, 'one', 'two', 'three'];
      each(this.finalConfig.bonus.multipliers, (multiplierValue, multiplierKey) => {
        const minSymbolsCount = Object.keys(this.finalConfig.bonus.multipliers)[0];
        const symbolsCount = Number(multiplierKey);
        const optionIndex = Number(minSymbolsCount) === 2 ? symbolsCount : symbolsCount - 1;
        const resource = this.getResource(options, `buy_option_${symbolsMap[optionIndex]}`, this.finalAssets);

        if (resource) {
          this.finalAssets.bonusBuy.push({
            symbolsCount,
            asset: this.getResource(options, `buy_option_${symbolsMap[optionIndex]}`, this.finalAssets),
          });
        }
      });
    }
    // Add progress buy asset
    if (this.finalConfig && this.params.state.game.settings.progressBuyEnabled) {
      const resource = this.getResource(options, 'buy_option_four', this.finalAssets);
      if (resource) {
        this.finalAssets.bonusBuy.push({
          asset: resource,
        });
      }
    }
    // Add Collect buy asset
    if (this.finalConfig && this.params.state.game.settings.collectBuyEnabled) {
      const resource = this.getResource(options, 'buy_option_five', this.finalAssets);
      if (resource) {
        this.finalAssets.bonusBuy.push({
          asset: resource,
        });
      }
    }
  }
}
