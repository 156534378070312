import animate from 'gsap';
import { defaultTo, isNil } from 'lodash';
import { Container, Sprite, Texture } from '../pixi';
import { registerEventListener, triggerEvent } from '../utility/Utility';
import { SlotNumber } from './SlotNumber';
import { SlotNumberCurrency } from './SlotNumberCurrency';
import { slotState } from './SlotState';

export class SlotReelsFooter {
  constructor() {
    this.options = slotState.options;
    this.container = undefined;
    this.background = undefined;
    this.backgroundFree = undefined;
    this.winAmountContainer = undefined;
    this.winAmountValue = defaultTo(this.options.winAmountValue, 0);
    this.winAmount = undefined;
    this.winAmountScale = 0.15;
    this.winAmountCurrency = undefined;
    this.winAmountCurrencyPadding = 5;
    this.timeline = undefined;
    this.backgroundTimeline = undefined;
    this.backgroundTimelineFree = undefined;
    this.toggleBackgroundDuration = 0.5;

    this.create();
    this.show();
    this.setListeners();
    this.setupBackgroundTimelines();
    this.setBackground();
  }

  get size() {
    return this.container.getBounds();
  }

  create() {
    const backgroundAsset = this.options.assets.reelsFooterBackground;
    const backgroundAssetFree = this.options.assets.reelsFooterBackgroundFree;

    this.container = new Container();

    this.background = new Sprite(backgroundAsset ? backgroundAsset.resource : Texture.EMPTY);
    this.backgroundFree = new Sprite(backgroundAssetFree ? backgroundAssetFree.resource : Texture.EMPTY);
    this.background.width = slotState.reelBackground.sprite.width;
    this.background.visible = slotState.isInFreeRounds;
    this.backgroundFree.width = this.background.width;
    this.backgroundFree.visible = !slotState.isInFreeRounds;
    this.container.addChild(this.backgroundFree);
    this.container.addChild(this.background);

    this.winAmountContainer = new Container();

    this.winAmount = new SlotNumber(this.winAmountValue, '+');
    this.winAmount.container.scale.set(this.winAmountScale);
    this.winAmountContainer.addChild(this.winAmount.container);

    if (this.options.currencyDisplayEnabled && slotState.areCurrencyTexturesAvailable) {
      this.winAmountCurrency = new SlotNumberCurrency();
      this.winAmountCurrency.setScale(this.winAmountScale);
      this.winAmountContainer.addChild(this.winAmountCurrency.container);
    }

    this.container.addChild(this.winAmountContainer);
  }

  show() {
    this.container.y = slotState.reels.container.y + slotState.reelBackground.container.getLocalBounds().height;

    if (this.winAmountCurrency) {
      this.winAmountCurrency.container.x = this.winAmount.container.width + this.winAmountCurrencyPadding;
    }

    this.centerWinAmountContainer();
    this.winAmountContainer.alpha = this.winAmountValue > 0 ? 1 : 0;
  }

  centerWinAmountContainer() {
    this.winAmountContainer.x = this.background.width / 2;
    this.winAmountContainer.y = this.background.height / 2;
    this.winAmountContainer.pivot.x = this.winAmountContainer.width / 2;
    this.winAmountContainer.pivot.y = this.winAmountContainer.height / 2;
  }

  setListeners() {
    const source = 'SlotReelsFooter';

    registerEventListener('ReelsStarting', () => {
      if (slotState.pendingWin > 0) {
        this.updateWinAmount({
          value: slotState.pendingWin,
          hideAfterShow: true,
        });
      } else {
        this.hideWinAmount();
      }
    }, source);

    registerEventListener('ReelsEnded', () => {
      this.stopWinAmount();
    }, source);

    registerEventListener('ToggleFreeRounds', () => {
      this.toggleBackground();
    }, source);

    registerEventListener('WinGradingEnded', () => {
      this.showWinAmount();
      slotState.setPendingWin(undefined);
    }, source);
  }

  hideWinAmount() {
    this.winAmountContainer.visible = false;
    this.winAmountContainer.alpha = 0;
  }

  showWinAmount() {
    this.winAmountContainer.visible = true;
  }

  stopWinAmount() {
    this.winAmountContainer.alpha = 0;
  }

  updateWinAmount({
    duration,
    timeline,
    timelinePosition,
    value,
    hideAfterShow = false,
  }) {
    if (isNil(value)) {
      return;
    }

    this.winAmountContainer.visible = true;
    this.winAmountContainer.alpha = 0;
    this.winAmountValue = value;
    this.winAmount.create(this.winAmountValue);

    if (this.winAmountCurrency) {
      this.winAmountCurrency.container.x = this.winAmount.container.width + this.winAmountCurrencyPadding;
    }

    this.centerWinAmountContainer();

    const that = this;
    const winTimeline = defaultTo(timeline, animate.timeline());

    winTimeline.to(this.winAmountContainer, {
      duration: defaultTo(duration, 0.25),
      alpha: 1,
      onStart() {
        that.winAmountContainer.visible = !slotState.isPaused && slotState.pendingWin > 0;
      },
      onComplete() {
        if (!slotState.isPaused) {
          slotState.setPendingWin(undefined);
          triggerEvent('WinEnded', {
            winAmount: that.winAmountValue,
          });
        }
      },
    }, timelinePosition);

    if (hideAfterShow) {
      winTimeline.to(this.winAmountContainer, {
        duration: defaultTo(duration, 0.25),
        alpha: 0,
      }, '>1.0');
    }
  }

  setupBackgroundTimelines() {
    if (isNil(this.background) || isNil(this.backgroundFree)) {
      return;
    }

    this.setupBackgroundTimeline();
    this.setupBackgroundTimelineFree();
  }

  setupBackgroundTimeline() {
    const that = this;

    this.backgroundTimeline = animate.timeline({
      paused: true,
      onStart() {
        that.background.visible = true;
        that.backgroundFree.visible = true;
      },
      onComplete() {
        that.background.visible = false;
      },
    });

    this.backgroundTimeline.fromTo(this.background, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleBackgroundDuration,
      pixi: {
        alpha: 0,
      },
    });

    this.backgroundTimeline.fromTo(this.backgroundFree, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleBackgroundDuration,
      pixi: {
        alpha: 1,
      },
    }, '<');
  }

  setupBackgroundTimelineFree() {
    const that = this;

    this.backgroundTimelineFree = animate.timeline({
      paused: true,
      onStart() {
        that.background.visible = true;
        that.backgroundFree.visible = true;
      },
      onComplete() {
        that.backgroundFree.visible = false;
      },
    });

    this.backgroundTimelineFree.fromTo(this.background, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleBackgroundDuration,
      pixi: {
        alpha: 1,
      },
    });

    this.backgroundTimelineFree.fromTo(this.backgroundFree, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleBackgroundDuration,
      pixi: {
        alpha: 0,
      },
    }, '<');
  }

  setBackground() {
    this.background.alpha = 1;
    this.background.visible = true;

    if (this.backgroundFree) {
      this.backgroundFree.visible = false;
    }
  }

  toggleBackground() {
    if (isNil(this.backgroundFree)) {
      this.setBackground();
      return;
    }

    if (this.backgroundFree.visible) {
      this.backgroundTimelineFree.play(0);
    } else if (this.background.visible && slotState.isInFreeRounds && !slotState.isPromotion && !slotState.activePromotion) {
      this.backgroundTimeline.play(0);
    } else {
      this.setBackground();
    }
  }
}
