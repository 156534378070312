import { Container, Sprite, Texture, BitmapText } from '@/pixi';
import { ScrollBox } from './ScrollBox';

export class Table {
  #container;
  #header;
  #rows;
  #summary;
  #columnsAlign;
  #width;
  #rowHeight;
  #textColor;
  #textFontSize;
  #tableFontName;
  #summaryFontName;
  #columnPadding;
  #rowsColors;
  #onRowClickCallback;
  #playTapSound;
  #rowTypes;

  constructor({
    headerItems, // Optional, Array.<String>
    items, // Optional, Array.<Object>, Object example: { columns: ['a', 'b', ...], ... },
    summaryItems, // Optional, Array.<String>
    columnsAlign = 'left', // Can also be Array.<String>
    width, // Required, Number
    rowHeight = 140,
    textColor = 0xdfe0e5,
    textFontSize = 34,
    tableFontName = 'RobotoLight',
    summaryFontName = 'RobotoBold',
    columnPadding = 72,
    rowsColors = [0x000000, 0x191919],
    onRowClickCallback, // Optional, Function
    playTapSound, // Optional, Function
  }) {
    this.#columnsAlign = columnsAlign;
    this.#width = width;
    this.#rowHeight = rowHeight;
    this.#textColor = textColor;
    this.#textFontSize = textFontSize;
    this.#tableFontName = tableFontName;
    this.#summaryFontName = summaryFontName;
    this.#columnPadding = columnPadding;
    this.#rowsColors = rowsColors;
    this.#onRowClickCallback = onRowClickCallback;
    this.#playTapSound = playTapSound;
    this.#rowTypes = {
      header: 'header',
      dataRow: 'dataRow',
      summary: 'summary',
    };

    // Setup

    this.#container = new Container();
    this.#container.name = 'Table';

    if (headerItems) {
      this.#header = this.#createRow(headerItems, 0, this.#rowTypes.header);
      this.#container.addChild(this.#header);
    }

    this.#rows = new ScrollBox({});
    this.#container.addChild(this.#rows.container);

    if (summaryItems) {
      this.addSummary(summaryItems);
    }

    if (items?.length) {
      this.addRows(items);
    }
  }

  get container() {
    return this.#container;
  }

  set onScrollToBottom(callback) {
    this.#rows.onScroll = ({ scrolledToBottom }) => {
      if (scrolledToBottom) {
        callback();
      }
    };
  }

  #createRow(columns, rowIndex, rowType = this.#rowTypes.dataRow) {
    const row = new Container();

    row.name = rowType;

    const background = new Sprite(Texture.WHITE);
    background.tint = rowIndex % 2 === 0 && rowType === this.#rowTypes.dataRow ? this.#rowsColors[1] : this.#rowsColors[0];
    background.width = this.#width;
    background.height = this.#rowHeight;

    row.addChild(background);

    columns.forEach((field, columnIndex) => {
      if (field) {
        const column = this.#createColumn(field, columnIndex, rowType, columns.length);

        row.addChild(column);
      }
    });

    row.y = rowIndex * this.#rowHeight;

    return row;
  }

  #createColumn(text, index, rowType, numberOfColumns) {
    const column = new BitmapText(rowType === this.#rowTypes.header ? text.toUpperCase() : text, {
      fontName: rowType === this.#rowTypes.summary ? this.#summaryFontName : this.#tableFontName,
      fontSize: this.#textFontSize,
      color: this.#textColor,
    });
    const columnWidth = (this.#width - (this.#columnPadding * (numberOfColumns + 1))) / numberOfColumns;
    const align = Array.isArray(this.#columnsAlign) ? this.#columnsAlign[index] : this.#columnsAlign;

    if (rowType === this.#rowTypes.header) {
      column.alpha = 0.5;
    }

    if (align === 'right') {
      column.x = this.#columnPadding + columnWidth + (index * (columnWidth + this.#columnPadding));
      column.anchor.set(1, 0.5);
    } else if (align === 'center') {
      column.x = this.#columnPadding + columnWidth / 2 + (index * (columnWidth + this.#columnPadding));
      column.anchor.set(0.5, 0.5);
    // left
    } else {
      column.x = this.#columnPadding + (index * (columnWidth + this.#columnPadding));
      column.anchor.set(0, 0.5);
    }

    column.y = this.#rowHeight / 2;
    column.maxWidth = columnWidth;

    if (column.width > columnWidth) {
      column.scale.set(columnWidth / column.width);
    }

    return column;
  }

  removeAllRows() {
    this.#rows.removeAllItems();
  }

  addRows(items) {
    const rowsItems = [];

    items.forEach((item, rowIndex) => {
      const row = this.#createRow(item.columns, rowIndex);

      if (this.#onRowClickCallback) {
        row.eventMode = 'static';
        row.cursor = 'pointer';
        row.on('pointertap', () => {
          this.#onRowClickCallback(item);

          this.#playTapSound?.();
        });
      }

      rowsItems.push(row);
    });

    this.#rows.addItems(rowsItems);
  }

  addSummary(summaryItems) {
    const oldSummary = this.#container.getChildByName(this.#rowTypes.summary);

    if (oldSummary) {
      this.container.removeChild(oldSummary);
    }

    this.#summary = this.#createRow(summaryItems, 0, this.#rowTypes.summary);

    if (oldSummary) {
      this.#summary.y = oldSummary.y;
    }

    this.#container.addChild(this.#summary);
  }

  setPosition(maxHeight) {
    let rowsHeight = maxHeight;

    if (this.#header) {
      rowsHeight -= this.#header.height;

      this.#rows.container.y = this.#header.height;
    }

    if (this.#summary) {
      this.#summary.y = maxHeight - this.#summary.height;

      rowsHeight -= this.#summary.height;
    }

    this.#rows.setDimensions({
      height: rowsHeight,
    });
  }
}
