import animate from 'gsap';
import { defaultTo, isObject } from 'lodash';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { BitmapText, Container } from '@/pixi';

export class Button {
  constructor({
    width,
    height,
    text,
    letterSpacing = 0,
    value,
    onClick,
    isEnabled = true,
    isVisible = true,
    radius = 0,
    textSize = 36,
    backgroundColor = 0xffffff,
    borderWidth,
    borderColor = 0xffffff,
    textColor = 0x000000,
    fontName = 'RobotoNormal',
    disabledAlpha = 0.75,
    playTapSound,
  }) {
    this.width = width;
    this.height = height;
    this.text = text;
    this.letterSpacing = letterSpacing;
    this.value = value;
    this.radius = radius;
    this.textSize = textSize;
    this.textColor = textColor;
    this.fontName = fontName;
    this.onClick = onClick;
    this.isVisible = isVisible;
    this.isEnabled = isEnabled;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
    this.borderWidth = borderWidth;
    this.disabledAlpha = disabledAlpha;
    this.playTapSound = playTapSound;

    this.container = new Container();
    this.background = undefined;
    this.label = undefined;

    this.setup();
  }

  click() {
    if (this.isEnabled) {
      this.playTapSound?.();
      this.onClick();
    }
  }

  createBackground() {
    const background = new Graphics();

    background.settings = {
      width: this.width,
      height: this.height,
      radius: this.radius,
    };

    background.clear();

    if (this.borderWidth) {
      if (isObject(this.borderColor)) {
        background.lineStyle(this.borderWidth, this.borderColor.color, this.borderColor.alpha, 0);
      } else {
        background.lineStyle(this.borderWidth, this.borderColor, 1, 0);
      }
    }

    if (isObject(this.backgroundColor)) {
      background.beginTextureFill({
        color: this.backgroundColor.color,
        alpha: this.backgroundColor.alpha,
      });
    } else {
      background.beginFill(this.backgroundColor);
    }

    if (this.width === this.height && this.width === this.radius) {
      const radius = this.radius / 2;
      background.drawCircle(radius, radius, radius);
    } else {
      background.drawRoundedRect(0, 0, this.width, this.height, this.radius);
    }

    background.endFill();

    return background;
  }

  setup() {
    this.background = this.createBackground(this.width, this.height, this.radius);

    this.label = new BitmapText(this.text, {
      fontName: this.fontName,
      fontSize: this.textSize,
      tint: this.textColor,
      letterSpacing: this.letterSpacing,
    });

    this.label.x = this.background.width / 2;
    this.label.y = this.background.height / 2;
    this.label.anchor.set(0.5);

    this.container.alpha = this.isEnabled ? 1 : this.disabledAlpha;

    if (!this.isVisible) {
      this.container.alpha = 0;
    }

    if (this.onClick) {
      this.container.eventMode = 'static';
      this.container.cursor = this.isEnabled ? 'pointer' : 'not-allowed';
      this.container.on('pointertap', this.click.bind(this));
    }

    this.container.addChild(this.background);
    this.container.addChild(this.label);
  }

  setEnabled(value) {
    this.isEnabled = value;
    this.container.cursor = this.isEnabled ? 'pointer' : 'not-allowed';
    this.container.alpha = this.isEnabled ? 1 : this.disabledAlpha;
  }

  setText(text) {
    this.text = text;
    this.label.text = this.text;
  }

  setValue(value) {
    this.value = value;
  }

  async show(delay) {
    if (!this.isVisible) {
      this.isVisible = true;
      await animate.fromTo(this.container, {
        pixi: {
          alpha: 0,
          y: this.container.y - (this.container.height / 3),
        },
      }, {
        duration: 0.25,
        delay: defaultTo(delay, 0),
        pixi: {
          alpha: this.isEnabled ? 1 : this.disabledAlpha,
          y: this.container.y,
        },
      });
    }
  }

  hide() {
    this.isVisible = false;
    this.container.alpha = 0;
  }
}
