import { Table } from './Table';

export class RoundsHistoryTable extends Table {
  #historySummaryLabel;
  #currency;
  #currencyDisplayEnabled;
  #getMoneyLabel;
  #roundBetPropertyName;
  #roundWinPropertyName;

  constructor({
    tableParams,
    width = 1080,
    textColor = 0xdfe0e5,
    fontNameLight = 'RobotoLight',
    fontNameBold = 'RobotoBold',
    translations,
    currency,
    currencyDisplayEnabled,
    playTapSound,
    getMoneyLabel,
    roundBetPropertyName = 'betAmount',
    roundWinPropertyName = 'totalWinAmount',
  }) {
    const { historyTimeLabel, historyCurrencyLabel, historyBetLabel, historyWinLabel } = translations;

    super({
      headerItems: [historyTimeLabel, historyCurrencyLabel, historyBetLabel, historyWinLabel],
      width,
      textColor,
      tableFontName: fontNameLight,
      summaryTableFontName: fontNameBold,
      playTapSound,
      ...tableParams,
    });

    this.#historySummaryLabel = translations.historySummaryLabel;
    this.#currency = currency;
    this.#currencyDisplayEnabled = currencyDisplayEnabled;
    this.#getMoneyLabel = getMoneyLabel;
    this.#roundBetPropertyName = roundBetPropertyName;
    this.#roundWinPropertyName = roundWinPropertyName;
  }

  #formatDate(isoDate) {
    const dateObject = new Date(isoDate);
    const time = dateObject.toTimeString().slice(0, 8);
    const date = dateObject.toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit' });

    return `${time} ${date}`;
  }

  addRoundsRows(rounds) {
    const rows = rounds.map((round) => ({
      columns: [
        this.#formatDate(round.createdAt),
        this.#currency,
        this.#getMoneyLabel(round[this.#roundBetPropertyName]),
        this.#getMoneyLabel(round[this.#roundWinPropertyName]),
      ],
      round,
    }));

    this.addRows(rows);
  }

  addRoundsSummary({ betAmount, totalWinAmount }) {
    const summaryItems = [
      this.#historySummaryLabel,
      '',
      this.#getMoneyLabel(betAmount, this.#currencyDisplayEnabled),
      this.#getMoneyLabel(totalWinAmount, this.#currencyDisplayEnabled),
    ];

    this.addSummary(summaryItems);
  }
}
