import animate, { Power2 } from 'gsap';
import { assign, countBy, isNil, isObject, range, remove, shuffle } from 'lodash';
import { getPaylineCenter } from '@/utility/Utility';
import { Container } from '../pixi';
import { SlotSpinSpeedType } from '../models';
import { SlotSymbolCascade } from './SlotSymbolCascade';
import { slotState } from './SlotState';

export class SlotReelsCascade {
  constructor() {
    this.timeline = undefined;
    this.container = undefined;
    this.revealContainer = undefined;
    this.leaveContainer = undefined;
    this.options = slotState.options;
    this.autoReveal = slotState.options.autoReveal;
    this.reelPadding = slotState.options.reelPadding;
    this.reelBackground = slotState.reelBackground;
    this.reelWidth = 0;
    this.reelHeight = 0;
    this.reelRowHeight = 0;
    this.reelWindow = undefined;
    this.reelWindowCount = undefined;
    this.revealWindow = [];
    this.revealWindowExpandedCount = 0;
    this.leaveWindow = [];
    this.symbolDuration = 0.5;
    this.symbolDurationTurbo = 0.25;
    this.symbolDelay = 0.075;
    this.symbolDelayTurbo = 0.05;
    this.leaveEnterDelay = '-=0.65';
    this.leaveEnterDelayTurbo = '-=0.35';
    this.explodeWinAmountDuration = 1.0;
    this.isMasked = false;

    this.setup();
  }

  setup() {
    this.timeline = animate.timeline({ paused: true });

    this.revealContainer = new Container();
    this.leaveContainer = new Container();
    this.container = new Container();

    this.leaveContainer.sortableChildren = true;
    this.revealContainer.sortableChildren = true;
    this.container.y = slotState.reelsHeader.height;

    this.reelWidth = (this.reelBackground.width - (this.reelPadding[0] * 2)) / this.options.config.reels;
    this.reelHeight = (this.reelBackground.height - (this.reelPadding[1] * 2));
    this.reelRowHeight = this.reelHeight / this.options.config.rows;

    this.container.addChild(this.leaveContainer);
    this.container.addChild(this.revealContainer);

    if (this.autoReveal) {
      this.reveal();
    }
  }

  getRevealWindow() {
    const reelWindow = [];
    const { symbolsList } = this.options.config;
    let symbolIndex = 0;

    range(this.options.config.reels).forEach((_, reelIndex) => {
      reelWindow.push([]);

      range(this.options.config.rows).forEach(() => {
        reelWindow[reelIndex].push(symbolsList[symbolIndex]);
        symbolIndex += 1;

        if (symbolIndex >= symbolsList.length - 1) {
          symbolIndex = 0;
        }
      });

      reelWindow[reelIndex] = shuffle(reelWindow[reelIndex]);
    });

    return reelWindow;
  }

  generateRevealWindow() {
    this.revealWindow = [];
    this.revealContainer.removeChildren();

    this.reelWindow.forEach((reel, reelIndex) => {
      reel.forEach((symbolValue, rowIndex) => {
        const reverseRowIndex = this.options.config.rows - rowIndex - 1;
        const symbol = new SlotSymbolCascade(symbolValue);
        const x = (reelIndex * this.reelWidth) + (this.reelWidth / 2) + this.reelPadding[0];
        const y0 = (reverseRowIndex * this.reelRowHeight) + (this.reelRowHeight / 2) + this.reelPadding[1];
        const y1 = y0 + this.reelBackground.height;
        const y = y0 - this.reelBackground.height;

        symbol.setScaleToSize(this.reelWidth, this.reelRowHeight);
        symbol.setPosition(x, y);
        symbol.setReveal({ y: y0 });
        symbol.setLeave({ y: y1 });
        symbol.setVisible(false);

        this.revealWindow.push(symbol);
        this.revealContainer.addChild(symbol.container);
      });
    });

    this.revealWindowExpandedCount = countBy(this.revealWindow, (n) => n.isExpanded).true;
  }

  getSymbolDuration(spinSpeed) {
    if (spinSpeed === SlotSpinSpeedType.Turbo) {
      return this.symbolDurationTurbo;
    }

    return this.symbolDuration;
  }

  getSymbolDelay(spinSpeed) {
    if (spinSpeed === SlotSpinSpeedType.Lightning) {
      return 0;
    } if (spinSpeed === SlotSpinSpeedType.Turbo) {
      return this.symbolDelayTurbo;
    }

    return this.symbolDelay;
  }

  getSymbolEasing(spinSpeed) {
    if (spinSpeed === SlotSpinSpeedType.Lightning) {
      return {
        leave: undefined,
        reveal: undefined,
      };
    }

    return {
      leave: Power2.easeIn,
      reveal: Power2.easeOut,
    };
  }

  getLeaveEnterDelay(spinSpeed) {
    if (spinSpeed === SlotSpinSpeedType.Lightning) {
      return undefined;
    } if (spinSpeed === SlotSpinSpeedType.Turbo) {
      return this.leaveEnterDelayTurbo;
    }

    return this.leaveEnterDelay;
  }

  setReelWindowCount() {
    let total = 0;
    this.reelWindowCount = [];

    this.reelWindow.forEach((n) => {
      const count = n.length;

      this.reelWindowCount.push({
        count,
        start: total,
        total: total + count,
      });

      total += count;
    });
  }

  addResolveTimeline(timeline, spinOptions, reelWindowIndex, symbolDuration, symbolDelay, symbolEasing) {
    const that = this;
    const winlines = spinOptions.winLines;

    if (winlines) {
      const revealWindowCount = this.revealWindow.length;
      const reelWindowWinlines = winlines.filter((n) => n.reelWindowIndex === reelWindowIndex);

      if (reelWindowWinlines.length) {
        const symbolsExplodedList = [];

        reelWindowWinlines.forEach((reelWinLine) => {
          const paylineCenter = getPaylineCenter(reelWinLine.payline, this.options.config.reels, this.options.config.rows);

          reelWinLine.payline.forEach((paylineReel, reelIndex) => {
            paylineReel.forEach((reelSymbolIndex) => {
              const symbolIndex = this.reelWindowCount[reelIndex].start + reelSymbolIndex;
              const symbol = this.revealWindow[symbolIndex];
              const symbolExplodeDelay = symbolsExplodedList.length > 0 ? '<' : '<+=0.5';
              const symbolWinAmount = paylineCenter[0] === reelIndex && paylineCenter[1] === reelSymbolIndex ? reelWinLine.winLineAmount : undefined;

              timeline.add(symbol.explode(symbolWinAmount, this.explodeWinAmountDuration, () => {
                remove(this.leaveWindow, symbol);
              }), symbolExplodeDelay);

              if (isNil(symbolsExplodedList[reelIndex])) {
                symbolsExplodedList[reelIndex] = [];
              }

              symbolsExplodedList[reelIndex].push(symbolIndex);
            });
          });
        });

        let symbolsMoved = 0;

        this.reelWindow.forEach((reel, reelIndex) => {
          reel.forEach((reelSymbol, reelSymbolIndex) => {
            const symbolsExploded = symbolsExplodedList[reelIndex];
            const symbolIndex = this.reelWindowCount[reelIndex].start + reelSymbolIndex;
            const symbol = this.revealWindow[symbolIndex];

            if (symbolsExploded && symbolIndex > symbolsExploded[0] && symbolsExploded.indexOf(symbolIndex) < 0) {
              const delay = symbolsMoved > 0 ? `<=${(symbolDelay / 2)}` : `>+=${this.explodeWinAmountDuration}`;

              timeline.to(symbol.container, {
                duration: symbolDuration,
                ease: symbolEasing,
                pixi: {
                  y: `+=${this.reelRowHeight * symbolsExploded.length}`,
                },
                onStart() {
                  that.setMask();
                },
                onUpdate() {
                  const target = this.targets()[0];
                  target.visible = target.y > 0;
                },
                onComplete() {
                  if (symbolIndex === revealWindowCount - 1) {
                    that.removeMask();
                  }
                },
              }, delay);

              symbolsMoved += 1;
            }
          });
        });

        this.addResolveTimeline(timeline, spinOptions, reelWindowIndex + 1, symbolDuration, symbolDelay, symbolEasing);
      } else {
        this.timeline.add(this.generateLeaveTimeline(symbolDuration, symbolDelay, symbolEasing));
      }
    } else {
      this.timeline.add(this.generateLeaveTimeline(symbolDuration, symbolDelay, symbolEasing));
    }
  }

  generateLeaveTimeline(symbolDuration, symbolDelay, symbolEasing) {
    const that = this;
    const leaveTimeline = animate.timeline();

    this.leaveWindow.forEach((symbol, symbolIndex) => {
      const delay = symbolIndex % this.options.config.rows === 0 ? 0 : symbolDelay;

      leaveTimeline.to(symbol.container, {
        duration: symbolDuration,
        ease: symbolEasing.leave,
        pixi: {
          y: `+=${this.reelHeight}`,
        },
        onUpdate() {
          const target = this.targets()[0];
          target.visible = target.y < that.reelHeight;
        },
        onComplete() {
          symbol.setVisible(false);
          that.leaveWindow.shift();
          that.leaveContainer.removeChild(symbol.container);
        },
      }, `<=${delay}`);
    });

    return leaveTimeline;
  }

  generateTimeline(spinOptions) {
    const that = this;
    const revealTimeline = animate.timeline();
    const spinSpeed = SlotSpinSpeedType[spinOptions.spinSpeedType];
    const symbolDuration = this.getSymbolDuration(spinSpeed);
    const symbolDelay = this.getSymbolDelay(spinSpeed);
    const symbolEasing = this.getSymbolEasing(spinSpeed);
    const leaveEnterDelay = this.getLeaveEnterDelay(spinSpeed);
    const revealWindowCount = this.revealWindow.length;

    this.timeline.clear();
    this.setMask();

    this.revealWindow.forEach((symbol, symbolIndex) => {
      const delay = symbolIndex % this.options.config.rows === 0 ? 0 : (symbolDelay / 2);

      revealTimeline.to(symbol.container, {
        duration: symbolDuration,
        ease: symbolEasing.reveal,
        pixi: symbol.reveal,
        onStart() {
          symbol.setVisible(true);
        },
        onUpdate() {
          const target = this.targets()[0];
          target.visible = target.y > 0;
        },
        onComplete() {
          that.leaveWindow.push(symbol);
          that.leaveContainer.addChild(symbol.container);

          if (symbolIndex === revealWindowCount - 1) {
            that.removeMask();
          }
        },
      }, `<=${delay}`);
    });

    this.addResolveTimeline(revealTimeline, spinOptions, 0, symbolDuration, symbolDelay, symbolEasing);
    this.timeline.add(revealTimeline, leaveEnterDelay);
  }

  setMask() {
    if (!this.isMasked) {
      const mask = this.reelBackground.getMask();

      this.revealContainer.mask = mask;
      this.leaveContainer.mask = mask;

      if (this.reelBackground.spriteMask) {
        this.reelBackground.spriteMask.visible = true;
      }

      this.isMasked = true;
    }
  }

  removeMask() {
    if (this.isMasked && this.revealWindowExpandedCount > 0) {
      this.revealContainer.mask = null;
      this.leaveContainer.mask = null;

      if (this.reelBackground.spriteMask) {
        this.reelBackground.spriteMask.visible = false;
      }

      this.isMasked = false;
    }
  }

  async spin(spinOptions, spinResult) {
    if (isObject(spinResult)) {
      spinOptions = assign(slotState.getOptionsFromSpinResult(spinResult), spinOptions); // eslint-disable-line no-param-reassign
    }

    slotState.setLastRound({});

    this.reelWindow = spinOptions.reelWindow;

    this.setReelWindowCount();
    this.generateRevealWindow();
    this.generateTimeline(spinOptions);

    await this.timeline.play();
  }

  async stop() {
    this.timeline.progress(1);
  }

  async reveal() {
    this.reelWindow = this.getRevealWindow();
    this.generateRevealWindow();

    this.generateTimeline({
      spinSpeedType: SlotSpinSpeedType.Normal,
    });

    await this.timeline.play();
  }
}
