import { has, toLower } from 'lodash';
import { registerEventListener } from '@/utility/Utility';
import { ControlsHeader } from '@/components/shared/ControlsHeader';
import { SlotJackpotBar } from './SlotJackpotBar';
import { slotState } from './SlotState';

export class SlotControlsHeader extends ControlsHeader {
  constructor() {
    super({
      menuAsset: slotState.options.assets.uiMenu,
      onMenuClick: () => { this.showMenuDialog(); },
      lobbyEnabled: slotState.options.lobbyEnabled,
      lobbyAsset: slotState.options.assets.uiLobby,
      lobbyParams: {
        locale: slotState.options.locale,
        tenantGameId: slotState.options.tenantGameId,
        isDemo: slotState.options.isDemo,
        boostTenantId: slotState.options.boostTenantId,
        currency: slotState.options.currency,
        playerToken: slotState.options.playerToken,
        // These two options are only sent from casino-client-slots application
        platform: slotState.options.platform,
        token: slotState.options.token,
      },
      boostParams: {
        authorizationToken: slotState.options.token,
        boostTenantId: slotState.options.boostTenantId,
        locale: slotState.options.locale,
        profileId: slotState.player?.partnerPlayerId,
      },
      appWidth: slotState.options.calcWidth,
      padding: slotState.options.uiPadding,
      playTapSound: () => { slotState.playTapSound(); },
    });
    this.uiJackpot = undefined;

    const isJackpotActive = has(slotState.options.config, 'jackpot') && toLower(slotState.options.config.jackpot.jackpotType) !== 'none';

    if (isJackpotActive) {
      this.uiJackpot = new SlotJackpotBar();
      this.container.addChild(this.uiJackpot.container);
    }
    this.setListeners();
  }

  disableInSpin() {
    this.disableMenuClick();
  }

  enableAfterSpin() {
    if (!slotState.isInFreeRounds && !slotState.isAutoplay) {
      this.enableMenuClick();
    }
  }

  showMenuDialog() {
    slotState.dialogMenu.show();
  }

  setListeners() {
    registerEventListener('ShowWinGrading', () => {
      // need to add custom style to fit grading
      this.uiBoost?.disable('0.2');
    });
    registerEventListener('WinGradingEnded', () => {
      this.uiBoost?.enable();
    });
    registerEventListener('StartBonusGame', () => {
      this.uiBoost?.hide();
    });
    registerEventListener('DialogChanged', (event) => {
      const { isOpened, isFullscreen, isPromotionDialog, type } = event.detail;

      // when intro is closed in pick prize games boost should be hidden, will be removed when pick pize is moved to webgl
      if (!this.uiBoost || (!isOpened && (type?.includes('PickPrize') && type?.includes('Intro')))) return;

      if (!isPromotionDialog && !isOpened && isFullscreen) {
        this.uiBoost.show();
      } else if ((isPromotionDialog || isOpened) && isFullscreen) {
        this.uiBoost.hide();
      } else if (isOpened && !isFullscreen) {
        this.uiBoost.disable();
      } else if (!isOpened && !isFullscreen) {
        this.uiBoost.enable();
      }
    });
    registerEventListener('OpenMenu', (event) => {
      const { tabIndex } = event.detail;
      slotState.dialogMenu.show(tabIndex);
    });
  }
}
