import animate from 'gsap';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container } from '@/pixi';

export class Loader {
  #container;
  #donut;
  #arc;

  constructor({
    size = 80,
    backgroundColor = 0x303030,
    color = 0xffe843,
  }) {
    const lineStyleWidth = 2;
    const lineStyleAlignment = 0.5;
    const outerLineWidth = lineStyleWidth * lineStyleAlignment;
    const loaderSize = size - outerLineWidth;
    const holePercentage = 0.87;

    this.#container = new Container();
    this.#container.name = 'Loader';

    this.#donut = new Graphics()
      .beginFill(backgroundColor)
      .lineStyle(lineStyleWidth, backgroundColor, 1, lineStyleAlignment)
      .drawCircle(0, 0, loaderSize)
      .beginHole()
      .drawCircle(0, 0, loaderSize * holePercentage)
      .endHole()
      .endFill();

    this.#arc = new Graphics()
      .lineStyle(loaderSize * (1 - holePercentage) + outerLineWidth * 2, color, 1, 0)
      .arc(0, 0, loaderSize + outerLineWidth, 0, 2);

    this.#container.addChild(this.#donut, this.#arc);

    // Set infinite animation
    animate.fromTo(this.#arc, {
      pixi: {
        angle: 0,
      },
    }, {
      duration: 1.1,
      repeat: -1,
      ease: 'none',
      pixi: {
        angle: 360,
      },
    });
  }

  get container() {
    return this.#container;
  }
}
