import { Notification } from './shared/Notification';
import { slotState } from './SlotState';

export class SlotNotification extends Notification {
  constructor(params) {
    super({
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      iconCloseAsset: slotState.options.assets.iconClose,
      onClose() {
        slotState.notification = undefined;
      },
      playTapSound: () => { slotState.playTapSound(); },
      fontName: slotState.options.fontNameNormal,
      ...params,
    });
  }
}
