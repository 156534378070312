import animate, { Power4 } from 'gsap';
import { SlotSymbol } from './SlotSymbol';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';

export class SlotCollect {
  constructor() {
    this.options = slotState.options;
    this.timeline = undefined;
    this.scaleDuration = 0.5;
    this.moveDuration = 0.75;
    this.moveMultiplierToCashDuration = [0.5, 0.5];
    this.multiplierHitCashDelay = 0.5;
    this.multiplierHitCashDuration = 0.5;
    this.cashHitCollectDuration = 0.75;
    this.endPauseDuration = 0.5;
  }

  createSymbolClone(symbolReelPosition) {
    const reelIndex = symbolReelPosition[1];
    const rowIndex = symbolReelPosition[2];
    const symbol = slotState.reels.getSymbol(reelIndex, rowIndex);
    const symbolBounds = symbol.container.getLocalBounds();
    const symbolClone = new SlotSymbol(symbol.symbolValue, true, symbol.symbolNumber);

    symbolClone.stopToLast();
    symbolClone.scaleTo(symbol.container.width, symbol.container.height);
    symbolClone.sprite.visible = false;
    symbolClone.container.position.x = (symbolBounds.width * reelIndex) + (symbolBounds.width / 2) + this.options.reelPadding[0];
    symbolClone.container.position.y = slotState.reelsHeader.container.height + (symbolBounds.height * rowIndex) + (symbolBounds.height / 2) + this.options.reelPadding[1];
    symbolClone.container.visible = false;

    slotState.reelsOverlay.add(symbolClone);

    return {
      symbol,
      symbolClone,
    };
  }

  stop() {
    slotState.reelsOverlay.hideEmitter();

    if (this.timeline && this.timeline.isActive()) {
      this.timeline.progress(1);
      this.timeline.kill();
    }
  }

  async start(reelsValue) {
    this.stop();

    const { reelCollect } = reelsValue;
    const previousMultiplier = slotState.reelsMultipliers.collectMultiplierValue;
    const isMultiplierChanged = reelCollect.multiplier !== previousMultiplier;
    const isCashWon = reelCollect.isWon;
    const isChanged = isCashWon || isMultiplierChanged;

    if (!isChanged) {
      return false;
    }

    return new Promise((resolve) => {
      const that = this;
      const skipDisableSymbols = [];

      this.timeline = animate.timeline({
        paused: true,
        onStart() {
          slotState.reels.winLines.disableSymbols(skipDisableSymbols);
          audio.play(that.options.assets.soundProgressScale);
        },
        onComplete() {
          slotState.reelsOverlay.clear();
          resolve(true);
        },
      });

      if (isMultiplierChanged) {
        const targetMultiplierPosition = slotState.reelsMultipliers.getCollectMultiplierPosition();

        reelCollect.multiplierSymbolReelPosition.forEach((multiplierPosition) => {
          const { symbol, symbolClone } = this.createSymbolClone(multiplierPosition);

          skipDisableSymbols.push(`${multiplierPosition[1]}-${multiplierPosition[2]}`);

          this.timeline.to(symbolClone.container, {
            pixi: {
              scale: 1.2,
            },
            ease: Power4.easeIn,
            duration: this.scaleDuration,
            onStart() {
              symbolClone.container.visible = true;
              symbol.number.hide();
            },
          });

          this.timeline.to(symbolClone.container, {
            pixi: {
              x: targetMultiplierPosition.x,
              y: targetMultiplierPosition.y,
              scale: 0,
            },
            ease: Power4.easeIn,
            duration: this.moveDuration,
            onStart() {
              audio.play(that.options.assets.soundProgressMove);
            },
            onComplete() {
              audio.play(that.options.assets.soundReelMultiplierHit);
              slotState.reelsOverlay.showEmitter(symbolClone.container.position);
              return slotState.reelsMultipliers.increaseCollectMultiplier(symbolClone.number.amount);
            },
          });
        });
      }

      if (isCashWon && reelCollect.multiplier > 1) {
        this.timeline.to(slotState.reelsMultipliers.container, {
          pixi: {
            alpha: 1,
          },
          duration: this.multiplierHitCashDelay,
        });

        reelCollect.cashSymbolReelPosition.forEach((cashPosition) => {
          const symbol = slotState.reels.getSymbol(cashPosition[1], cashPosition[2]);

          skipDisableSymbols.push(`${cashPosition[1]}-${cashPosition[2]}`);

          slotState.reelsMultipliers.applyCollectMultiplier({
            duration: this.moveMultiplierToCashDuration,
            timeline: this.timeline,
            timelinePosition: '<',
            value: reelCollect.multiplier,
            endPoint: {
              x: (slotState.reels.reelWidth * cashPosition[1]) + (slotState.reels.reelWidth / 2) + this.options.reelPadding[0],
              y: -((slotState.reels.reelRowHeight * (this.options.config.rows - cashPosition[2])) - (slotState.reels.reelRowHeight / 2) + this.options.reelPadding[1]),
            },
            onComplete() {
              slotState.reelsOverlay.showEmitter({
                x: (slotState.reels.reelWidth * cashPosition[1]) + (slotState.reels.reelWidth / 2) + that.options.reelPadding[0],
                y: slotState.reelsHeader.container.height + (slotState.reels.reelRowHeight * cashPosition[2]) + (slotState.reels.reelRowHeight / 2) + that.options.reelPadding[1],
              });
            },
          });

          symbol.number.progressTo({
            amount: symbol.number.amount * reelCollect.multiplier,
            amountFrom: symbol.number.amount,
            duration: this.multiplierHitCashDuration,
            timeline: this.timeline,
            onUpdate() {
              symbol.number.center();
            },
          });
        });
      }

      if (isCashWon) {
        const totalCollectSymbols = reelsValue.reelCollect.collectSymbolReelPosition.length;

        reelsValue.reelCollect.collectSymbolReelPosition.forEach((collectSymbolPosition, collectSymbolPositionIndex) => {
          const collectSymbol = slotState.reels.getSymbol(collectSymbolPosition[0], collectSymbolPosition[1]);

          collectSymbol.playWin();
          skipDisableSymbols.push(`${collectSymbolPosition[0]}-${collectSymbolPosition[1]}`);

          reelCollect.cashSymbolReelPosition.forEach((cashPosition, cashIndex) => {
            skipDisableSymbols.push(`${cashPosition[1]}-${cashPosition[2]}`);
            const { symbol, symbolClone } = this.createSymbolClone(cashPosition);

            this.timeline.to(symbolClone.container, {
              pixi: {
                scale: 1.2,
              },
              ease: Power4.easeIn,
              duration: this.scaleDuration,
              onStart() {
                symbolClone.number.create(symbol.number.amount);
                symbolClone.container.visible = true;

                if (totalCollectSymbols === collectSymbolPositionIndex + 1) {
                  symbol.number.hide();
                  symbol.numberCurrency?.hide();
                }
              },
              onUpdate() {
                symbolClone.number.center();
              },
            }, cashIndex > 0 ? '<' : undefined);

            this.timeline.to(symbolClone.container, {
              duration: this.cashHitCollectDuration,
              ease: Power4.easeIn,
              pixi: {
                scale: 0,
                x: (slotState.reels.reelWidth * collectSymbolPosition[0]) + (slotState.reels.reelWidth / 2) + this.options.reelPadding[0],
                y: slotState.reelsHeader.container.height + (slotState.reels.reelRowHeight * collectSymbolPosition[1]) + (slotState.reels.reelRowHeight / 2) + this.options.reelPadding[1],
              },
              onComplete() {
                audio.play(that.options.assets.soundBonusMultiply);

                if (cashIndex === reelCollect.cashSymbolReelPosition.length - 1) {
                  return slotState.reelsMultipliers.updateCollectMultiplier(1);
                }

                return undefined;
              },
            });
          });
        });

        this.timeline.to(slotState.reelsMultipliers.container, {
          pixi: {
            alpha: 1,
          },
          duration: this.endPauseDuration,
        });
      }

      this.timeline.play();
    });
  }
}
