import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Point, BitmapText, Rectangle, Sprite, Texture } from '@/pixi';

export class Slider {
  constructor({
    trackWidth = 500,
    trackHeight = 6,
    trackBgColor = 0xffffff,
    highlightColor = 0x555,
    min = 0,
    max = 100,
    step = 1,
    value = 0,
    disabled = false,
    fontName = 'RobotoNormal',
    onChange,
    formatValue,
  }) {
    this.container = new Container();
    this.valuePreview = undefined;
    this.track = undefined;
    this.trackWidth = trackWidth;
    this.trackHeight = trackHeight;
    this.trackBgColor = trackBgColor;
    this.thumb = undefined;
    this.thumbSize = trackHeight * 4.5;
    this.highlightTrack = undefined; // Selected part of slider
    this.highlightColor = highlightColor;
    this.trackMask = undefined; // Mask used to hide track behind thumb when slider is disabled
    this.min = min;
    this.max = max;
    this.step = step;
    this.value = value;
    this.disabled = disabled;
    this.fontName = fontName;
    this.onChange = onChange;
    this.formatValue = formatValue;
    this.points = [];
    this.values = [];

    this.setup();
  }

  setup() {
    const pointY = this.trackHeight / 2;

    for (let x = this.min; x <= this.max; x = parseFloat((x + this.step).toFixed(5))) {
      // Add value for every step
      this.values.push(x);
      // Add point for every step
      this.points.push(new Point(
        ((this.trackWidth - this.thumbSize * 2) * x) / this.max,
        pointY,
      ));
    }

    this.valuePreview = new BitmapText(this.formatValue ? this.formatValue(this.value) : this.value, {
      fontName: this.fontName,
      fontSize: this.trackHeight * 7,
      tint: this.highlightColor,
    });
    this.valuePreview.x = this.trackWidth;
    this.valuePreview.anchor.set(1, 0);
    this.container.addChild(this.valuePreview);

    this.track = new Graphics().beginFill(this.trackBgColor).drawRect(0, 0, this.trackWidth, this.trackHeight);
    this.track.y = this.valuePreview.y + this.valuePreview.height * 1.7;
    this.track.pivot.y = this.track.height / 2;
    this.container.addChild(this.track);

    const pointIndex = this.values.findIndex((value) => value === this.value);
    this.thumb = new Graphics().beginFill(this.highlightColor).lineStyle(2, this.highlightColor)
      .drawCircle(this.thumbSize, this.thumbSize, this.thumbSize);
    this.thumb.x = !this.disabled ? this.points[pointIndex].x : 0;
    this.thumb.y = this.track.y - this.thumb.height / 2;
    this.container.addChild(this.thumb);

    this.highlightTrack = new Sprite(Texture.WHITE);
    this.highlightTrack.height = this.trackHeight;
    this.highlightTrack.width = this.thumb.x;
    this.highlightTrack.y = this.track.y;
    this.highlightTrack.anchor.set(0, 0.5);
    this.highlightTrack.tint = this.highlightColor;
    this.container.addChild(this.highlightTrack);

    this.trackMask = new Graphics().drawRect(this.thumb.width, -this.trackHeight / 2, this.trackWidth - this.thumb.width, this.trackHeight);
    this.trackMask.y = this.track.y;
    this.container.addChild(this.trackMask);

    if (this.disabled) {
      this.container.alpha = 0.5;
      this.track.mask = this.trackMask;
    }

    this.track.eventMode = !this.disabled ? 'static' : 'none';
    this.track.cursor = 'pointer';
    this.track.hitArea = new Rectangle(0, -this.thumb.height / 2, this.track.width, this.thumb.height);

    const globalEventHandler = this.trackEventHandler.bind(this);

    this.track.on('pointerdown', (e) => {
      this.trackEventHandler(e);
      this.track.on('globalpointermove', globalEventHandler);
    });

    this.track.on('pointerup', () => {
      this.track.off('globalpointermove', globalEventHandler);
    });

    this.track.on('pointerupoutside', () => {
      this.track.off('globalpointermove', globalEventHandler);
    });

    this.thumb.eventMode = 'passive';
    this.highlightTrack.eventMode = 'passive';
  }

  trackEventHandler(e) {
    const { x } = this.container.toLocal(e.global);
    let pointIndex;

    if (x < 0) pointIndex = 0;
    else if (x > this.trackWidth) pointIndex = this.points.length - 1;
    else pointIndex = Math.round(x / ((this.trackWidth * this.step) / this.max));

    this.setValueAndThumbPosition(pointIndex);
  }

  setValueAndThumbPosition(pointIndex) {
    if (this.value !== this.values[pointIndex]) {
      this.thumb.x = this.points[pointIndex].x;
      this.highlightTrack.width = this.thumb.x;
      this.value = this.values[pointIndex];
      this.valuePreview.text = this.formatValue ? this.formatValue(this.value) : this.value;
      this.onChange?.(this.value);
    }
  }

  setDisabledState(value) {
    this.disabled = value;
    this.container.alpha = value ? 0.5 : 1;
    this.track.eventMode = value ? 'none' : 'static';
    this.track.mask = value ? this.trackMask : null;

    if (value) {
      this.setValueAndThumbPosition(0);
    }
  }
}
