import animate from 'gsap';
import { defaultTo, isNil } from 'lodash';
import { AnimatedSprite, Container, Ticker } from '../pixi';
import { SlotNumber } from './SlotNumber';
import { SlotBurstEmitter } from './SlotBurstEmitter';
import { SlotNumberCurrency } from './SlotNumberCurrency';
import { slotState } from './SlotState';
import { registerEventListener, triggerEvent } from '../utility/Utility';

export class SlotWinlineAmount {
  constructor({
    options,
    winAmount,
    position,
    scale,
    isWithBackground = false,
    animationDuration = 0.5,
    multiplierApplyCount,
    multiplierValue,
    applyMultiplierOnWin,
  }) {
    this.options = options;
    this.isWithBackground = isWithBackground;
    this.isAmountProgress = isWithBackground;
    this.isBackgroundReversed = false;
    this.isWinGradingShown = false;
    this.timeline = undefined;
    this.scale = defaultTo(scale, 1);
    this.container = new Container();
    this.numberContainer = new Container();
    this.textContainer = new Container();
    this.backgroundContainer = new Container();
    this.emitterContainer = new Container();
    this.number = new SlotNumber(0);
    this.numberCurrency = undefined;
    this.winGrades = undefined;

    this.setListeners();
    this.create();

    if (position) {
      this.show({
        winAmount,
        position,
        animationDuration,
        multiplierApplyCount,
        multiplierValue,
        applyMultiplierOnWin,
      });
    }
  }

  create() {
    this.textContainer.removeChildren();
    this.textContainer.addChild(this.number.container);

    if (this.options.currencyDisplayEnabled && slotState.areCurrencyTexturesAvailable) {
      this.numberCurrency = new SlotNumberCurrency();
      this.numberCurrency.setScale(0.5);
      this.textContainer.addChild(this.numberCurrency.container);
    }

    this.textContainer.pivot.set(0.5);

    if (isNil(this.options.assets.totalWinBackground)) {
      this.isWithBackground = false;
    }

    if (this.isWithBackground) {
      this.background = new AnimatedSprite(this.options.assets.totalWinBackground.resource);
      this.background.scale.set(2);
      this.background.anchor.set(0.5);
      this.background.loop = false;
      this.background.animationSpeed = 0.4;
      this.backgroundContainer.addChild(this.background);
    }

    this.numberContainer.addChild(this.backgroundContainer);
    this.numberContainer.addChild(this.emitterContainer);
    this.numberContainer.addChild(this.textContainer);
    this.container.addChild(this.numberContainer);

    if (this.isAmountProgress) {
      this.emitter = new SlotBurstEmitter(this.emitterContainer, this.options.winlineColors);
    }
  }

  onAmountUpdate() {
    const textSize = this.textContainer.getLocalBounds();
    const textScale = this.textContainer.scale;

    this.textContainer.position.set(-(textSize.width / 2) * textScale.x, -(textSize.height / 2) * textScale.y);
    this.emitterContainer.position.set(this.textContainer.position.x + (this.textContainer.width / 2), this.textContainer.position.y + (this.textContainer.height / 2));

    if (this.numberCurrency) {
      this.numberCurrency.container.x = this.number.container.width / 2 - this.numberCurrency.container.width / 2;
      this.numberCurrency.container.y = this.number.container.height;
    }

    this.textContainer.visible = true;

    if (this.isAmountProgress
      && !this.isWinGradingShown
      && this.winGrades
      && this.number.amount >= this.winGrades.limit
    ) {
      this.isWinGradingShown = true;

      if (slotState.isWinGradingEnabled) {
        triggerEvent('ShowWinGrading', {
          winGrades: this.winGrades,
          textScale: this.scale,
        });
      } else {
        triggerEvent('WinGradingLimitReached', {
          from: this.winGrades.limit,
        });
      }
    }
  }

  setListeners() {
    const source = 'SlotWinlineAmount';

    registerEventListener('ReelsStarting', () => {
      this.stop();
    }, source);
  }

  stop() {
    if (this.timeline) {
      this.timeline.progress(1, true);
      this.timeline.kill();
      this.timeline = undefined;
    }

    if (this.isWithBackground) {
      this.background.gotoAndStop(0);
    }

    if (this.emitter) {
      this.emitter.hide();
    }
  }

  show({
    animationDuration,
    applyMultiplierOnWin,
    isFree,
    isFreeRoundsEnd,
    isFreeRoundsWon,
    isSyncWinAmount,
    multiplierValue = 1,
    position,
    winAmount,
    resolve,
  }) {
    this.stop();
    this.textContainer.visible = false;
    this.textContainer.alpha = 1;
    this.numberContainer.visible = true;
    this.numberContainer.scale.set(1);
    this.container.x = position.x;
    this.container.y = position.y;
    this.container.alpha = 1;
    this.container.scale.set(this.scale);

    const that = this;
    const numberAmount = this.isAmountProgress ? 0 : winAmount;

    this.number.create(numberAmount);

    if (this.isAmountProgress) {
      this.timeline = animate.timeline();
      this.winGrades = slotState.getWinGrades();
      this.isWinGradingShown = false;

      if (this.isBackgroundReversed) {
        this.background.textures.reverse();
        this.isBackgroundReversed = false;
      }

      const winGrades = slotState.isWinGradingEnabled ? this.winGrades : undefined;
      const winGradesLimit = winGrades ? winGrades.limit : winAmount;
      const durationExit = animationDuration[1] / 6;
      const durationExitDelay = animationDuration[1];
      const durationSegment = animationDuration[0] / 4;
      const baseWinAmount = winAmount / multiplierValue;
      const multiplierFlows = [];

      if (winGrades) {
        slotState.togglePaused();
      }

      if (applyMultiplierOnWin && multiplierValue > 1 && baseWinAmount < winGradesLimit) {
        multiplierFlows.push({
          applyMethod: slotState.reelsMultipliers.applyMultiplier.bind(slotState.reelsMultipliers),
          amount: [0, baseWinAmount, winAmount],
          scale: 1.2,
          duration: [durationSegment * 1.5, durationSegment * 1.5, durationSegment * 0.5, durationSegment * 1.5],
        });
      } else {
        multiplierFlows.push({
          amount: [0, winGradesLimit],
          scale: 1.0,
          duration: [animationDuration[0]],
        });
      }

      multiplierFlows.forEach((multiplierFlow, multiplierIndex) => {
        if (multiplierIndex === 0) {
          this.number.progressTo({
            amount: multiplierFlow.amount[1],
            amountFrom: multiplierFlow.amount[0],
            timeline: this.timeline,
            duration: multiplierFlow.duration[0],
            scale: multiplierFlow.scale,
            parent: this.numberContainer,
            onStart: () => {
              if (this.isWithBackground) {
                this.background.gotoAndPlay(0);
              }
            },
            onUpdate: this.onAmountUpdate.bind(this),
          });
        }

        if (multiplierFlow.applyMethod) {
          multiplierFlow.applyMethod({
            duration: multiplierFlow.duration[1],
            timeline: this.timeline,
            timelinePosition: `-=${multiplierFlow.duration[2]}`,
            resetValue: applyMultiplierOnWin,
          });

          this.number.progressTo({
            amount: multiplierFlow.amount[2],
            amountFrom: multiplierFlow.amount[1],
            timeline: this.timeline,
            duration: multiplierFlow.duration[3],
            scale: multiplierFlow.scale,
            parent: this.numberContainer,
            onUpdate: this.onAmountUpdate.bind(this),
            onStart: () => {
              if (this.emitter) {
                this.emitter.show();
              }
            },
            onComplete: () => {
              if (this.emitter) {
                this.emitter.hide();
              }
            },
          });
        }
      });

      let backgroundDuration = 0;
      if (that.isWithBackground) {
        backgroundDuration = that.background.totalFrames / (Ticker.shared.maxFPS * 0.4);
      }

      this.timeline.to(this.container, {
        duration: durationExitDelay - backgroundDuration,
      }, '>');

      if (backgroundDuration > 0) {
        this.timeline.to(this.container, {
          duration: backgroundDuration,
          onStart() {
            if (that.isWithBackground) {
              if (that.isBackgroundReversed === false) {
                that.background.textures.reverse();
                that.isBackgroundReversed = true;
              }

              that.background.gotoAndPlay(0);
            }
          },
        }, '>');
      }

      this.timeline.to(this.container, {
        duration: durationExit,
        pixi: {
          alpha: 0,
        },
        onComplete() {
          if (isSyncWinAmount) {
            resolve(true);
          }
        },
      }, '>');

      slotState.reelsFooter.updateWinAmount({
        isFree,
        isFreeRoundsEnd,
        isFreeRoundsWon,
        value: winAmount,
        duration: durationExit,
        timeline: this.timeline,
        timelinePosition: '<',
      });
    } else {
      this.onAmountUpdate();
    }
  }
}
