import animate from 'gsap';
import { defaultTo, find, findIndex, floor, isNil, map, random, sum } from 'lodash';
import { Container, AnimatedSprite, Sprite } from '../pixi';
import { SlotSymbolHit } from './SlotSymbolHit';
import { SlotNumber } from './SlotNumber';
import { SlotNumberCurrency } from './SlotNumberCurrency';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';
import { triggerEvent } from '../utility/Utility';

export class SlotSymbol {
  constructor(symbolValue, isDisabled, symbolNumber) {
    this.options = slotState.options;
    this.symbolValue = symbolValue;
    this.symbolIndex = findIndex(this.options.assets.symbols, { value: symbolValue });
    this.symbolNumber = defaultTo(symbolNumber, 0);
    this.isAnimated = false;
    this.isDisabled = isDisabled;
    this.expandedScale = 1.2;
    this.container = new Container();
    this.hit = undefined;
    this.sprite = undefined;
    this.spriteWin = undefined;
    this.number = undefined;
    this.numberCurrency = undefined;

    this.createSprite();
    this.createHit();
    this.createNumber();

    this.container.$ref = this;
    this.setActions();
  }

  get isAutoplay() {
    return this.isSpecial && this.isAnimated;
  }

  get isExpanded() {
    return this.options.expandedSymbols.indexOf(this.symbolValue) > -1;
  }

  get isLoop() {
    return this.isAutoplay;
  }

  get isSpecial() {
    return this.symbolValue <= 0;
  }

  get width() {
    return this.container.width;
  }

  get height() {
    return this.container.height;
  }

  createSprite() {
    const assetSymbol = this.options.assets.symbols[this.symbolIndex];
    const textures = defaultTo(assetSymbol.animation, map(assetSymbol.assets, (asset) => asset.resource));

    if (textures.length > 1) {
      this.isAnimated = true;
      this.sprite = new AnimatedSprite(textures);
      this.sprite.animationSpeed = 0.4;
      this.sprite.loop = this.isLoop;

      if (this.isAutoplay) {
        this.sprite.play();
      }
    } else {
      this.sprite = new Sprite(textures[0]);
    }

    this.sprite.anchor.set(0.5);
    this.container.addChild(this.sprite);

    if (assetSymbol.animationWin) {
      this.spriteWin = new AnimatedSprite(assetSymbol.animationWin);
      this.spriteWin.visible = false;
      this.spriteWin.loop = true;
      this.spriteWin.animationSpeed = 0.4;
      this.spriteWin.anchor.set(0.5);
      this.container.addChild(this.spriteWin);
    }
  }

  createHit() {
    if (this.options.assets.symbolHit) {
      this.hit = new SlotSymbolHit();
      this.container.addChild(this.hit.container);
      this.hit.positionTo(this.sprite.x, this.sprite.y);
    }
  }

  createNumber() {
    if (this.options.config.isCollectSymbol && this.symbolValue < -1) {
      const { collect } = this.options.config;
      const scaleFactor = 0.25;

      if (this.symbolValue === collect.cashSymbol) {
        if (this.symbolNumber === 0) {
          const min = collect.cashRange[0] || 1;
          const max = slotState.betAmount > 2 ? slotState.betAmount * 20 : 20;
          this.symbolNumber = floor(random(min, max) / slotState.betAmount) * slotState.betAmount;
        }

        this.number = new SlotNumber(this.symbolNumber, undefined, 2);
        this.number.scale(scaleFactor);
        this.number.scale((this.sprite.width / this.number.container.width) * scaleFactor * 0.8);
        this.number.center();
        this.container.addChild(this.number.container);

        if (this.options.currencyDisplayEnabled && slotState.areCurrencyTexturesAvailable) {
          this.numberCurrency = new SlotNumberCurrency();
          this.numberCurrency.setScale(this.container.scale.x / 10);
          this.numberCurrency.container.x = this.container.x - this.numberCurrency.container.width / 2;
          this.numberCurrency.container.y = -this.number.container.getBounds().bottom + this.number.container.height;
          this.container.addChild(this.numberCurrency.container);
        }
      } else if (this.symbolValue === collect.multiplierSymbol) {
        if (this.symbolNumber === 0) {
          const min = collect.multiplierRange[0];
          const max = collect.multiplierRange[1] / 2.5;
          this.symbolNumber = floor(random(min, max));
        }

        this.number = new SlotNumber(this.symbolNumber, 'x', 0);
        this.number.scale(scaleFactor);
        this.number.center();
        this.container.addChild(this.number.container);
      }
    } else if (this.options.config.isWildSymbol && this.symbolValue === 0 && this.symbolNumber > 1) {
      this.number = new SlotNumber(this.symbolNumber, 'x', 0);
      this.number.scale(this.symbolNumber >= 100 ? 0.2 : 0.25);
      this.number.center();
      this.container.addChild(this.number.container);
    }
  }

  setEnabled(isEnabled) {
    const paytable = find(this.options.config.paytable, { symbol: this.symbolValue });
    const interactive = this.symbolValue >= 0 && slotState.options.config.symbolsListNoDetail.indexOf(this.symbolValue) < 0 && sum(paytable.hits) > 0 && isEnabled;

    this.container.eventMode = interactive ? 'static' : 'none';
    this.container.cursor = 'pointer';
  }

  setActions() {
    this.setEnabled(!this.isDisabled);

    this.container.on('pointertap', (event) => {
      this.showSymbolDetail(event);
    });
  }

  showSymbolDetail(event) {
    audio.play(this.options.assets.soundTap);

    const position = this.container.parent.parent.toLocal(event.global);

    triggerEvent('SymbolDetailShow', {
      symbolIndex: this.symbolIndex,
      symbolValue: this.symbolValue,
      symbolPosition: {
        x: position.x,
        y: position.y,
      },
    });
  }

  scaleTo(x, y) {
    this.sprite.scale.x = x / this.sprite.width;
    this.sprite.scale.y = y / this.sprite.height;

    if (this.spriteWin) {
      this.spriteWin.scale.x = this.sprite.scale.x;
      this.spriteWin.scale.y = this.sprite.scale.y;
    }

    if (this.hit) {
      this.hit.scaleTo(this.sprite.width, this.sprite.height);
    }
  }

  positionTo(y) {
    this.container.y = y;
  }

  play() {
    if (this.isAnimated && !this.isLoop) {
      this.sprite.gotoAndPlay(0);
    }
  }

  playWin() {
    if (this.spriteWin) {
      this.sprite.visible = false;
      this.spriteWin.visible = true;
      this.spriteWin.gotoAndPlay(0);
    }
  }

  playScale() {
    if (this.isExpanded) {
      const that = this;

      animate.to(this.sprite, {
        duration: 0.2,
        pixi: {
          scale: this.sprite.scale.x * this.expandedScale,
        },
        onUpdate() {
          if (that.spriteWin) {
            that.spriteWin.scale.x = that.sprite.scale.x;
            that.spriteWin.scale.y = that.sprite.scale.y;
          }

          if (that.hit) {
            that.hit.scaleTo(that.sprite.width, that.sprite.height);
          }
        },
      });
    }
  }

  setPosition(x, y) {
    this.container.x = x;
    this.container.y = y;
  }

  stop() {
    if (this.isAnimated && !this.isLoop) {
      this.sprite.gotoAndStop(0);
    }
  }

  stopWin() {
    if (this.spriteWin) {
      this.spriteWin.visible = false;
      this.spriteWin.gotoAndStop(0);
    }
  }

  stopToLast() {
    if (this.isAnimated) {
      this.sprite.gotoAndStop(this.sprite.totalFrames - 1);
    }
  }

  stopToFrame(frame) {
    if (this.isAnimated) {
      const stopFrame = isNil(frame) ? this.sprite.totalFrames - 1 : frame;
      this.sprite.gotoAndStop(stopFrame);
    }
  }

  showHit(delay) {
    this.play();

    if (this.hit) {
      this.hit.show(delay);
    }
  }

  hideHit() {
    this.stop();

    if (this.hit) {
      this.hit.hide();
    }
  }
}
