import { Container } from '@/pixi';
import { Button } from './Button';

export class Stepper {
  constructor({
    values,
    onIncrease,
    onDecrease,
    isEnabled = true,
    fontName,
    highlightColor = 0xffffff,
    highlightTextColor = 0x000000,
    playTapSound,
  }) {
    this.buttonWidth = 140;
    this.fontName = fontName;
    this.highlightColor = highlightColor;
    this.highlightTextColor = highlightTextColor;
    this.container = new Container();
    this.onIncrease = onIncrease;
    this.onDecrease = onDecrease;
    this.label = undefined;
    this.decrease = undefined;
    this.increase = undefined;
    this.values = values;
    this.valueIndex = 0;
    this.value = this.values[this.valueIndex];
    this.playTapSound = playTapSound;

    this.setup();
    this.setEnabled(isEnabled);
  }

  setup() {
    this.label = new Button({
      width: this.buttonWidth * 5,
      height: this.buttonWidth,
      radius: this.buttonWidth,
      backgroundColor: { color: this.highlightColor, alpha: 0.25 },
      fontName: this.fontName,
      text: this.value.label,
      value: this.value.value,
      textColor: this.highlightTextColor,
      textSize: 48,
    });

    this.decrease = new Button({
      width: this.buttonWidth,
      height: this.buttonWidth,
      radius: this.buttonWidth,
      borderWidth: 4,
      borderColor: { color: 0xffffff, alpha: 0.25 },
      backgroundColor: this.highlightColor,
      fontName: this.fontName,
      textColor: this.highlightTextColor,
      text: '–',
      textSize: 64,
      disabledAlpha: 0.5,
      playTapSound: this.playTapSound,
      onClick: () => {
        if (this.isEnabled && this.onDecrease && this.valueIndex > 0) {
          this.onDecrease(this.values[this.valueIndex - 1].value);
        }
      },
    });

    this.increase = new Button({
      width: this.buttonWidth,
      height: this.buttonWidth,
      radius: this.buttonWidth,
      text: '+',
      textSize: 64,
      backgroundColor: this.highlightColor,
      borderWidth: 4,
      borderColor: { color: 0xffffff, alpha: 0.25 },
      fontName: this.fontName,
      textColor: this.highlightTextColor,
      disabledAlpha: 0.5,
      playTapSound: this.playTapSound,
      onClick: () => {
        if (this.isEnabled && this.onIncrease && this.values.length > this.valueIndex + 1) {
          this.onIncrease(this.values[this.valueIndex + 1].value);
        }
      },
    });

    this.increase.container.x = this.label.container.width - this.increase.container.width;

    this.container.addChild(this.label.container);
    this.container.addChild(this.decrease.container);
    this.container.addChild(this.increase.container);
  }

  increaseValue() {
    if (this.values.length > this.valueIndex + 1) {
      this.valueIndex += 1;
      this.setValue();
    }
  }

  decreaseValue() {
    if (this.valueIndex > 0) {
      this.valueIndex -= 1;
      this.setValue();
    }
  }

  setValue(value) {
    if (value) {
      this.valueIndex = this.values.findIndex((v) => v.value === value);
    }

    this.value = this.values[this.valueIndex];
    this.label.setText(this.value.label);
    this.label.setValue(this.value.value);
  }

  setEnabled(value) {
    this.isEnabled = value;
    this.decrease.setEnabled(this.isEnabled && this.valueIndex !== 0);
    this.increase.setEnabled(this.isEnabled && this.values.length > this.valueIndex + 1);
  }
}
