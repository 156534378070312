import { assign, defaultTo, isNil, random, isArray } from 'lodash';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { BitmapText, Container } from '@/pixi';
import * as api from '@/api/casino';
import { slotState } from './SlotState';

export class SlotJackpotBar {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.height = 108;
    this.textColor = 0xF0F0F0;
    this.background = undefined;
    this.backgroundColor = 0x000000;
    this.label = undefined;
    this.labelText = `${slotState.options.translations.jackpotLabel.toUpperCase()} (${this.options.currency})`;
    this.labelTextSize = 32;
    this.jackpot = undefined;
    this.jackpotConfiguration = undefined;
    this.jackpotAnimationInterval = undefined;
    this.jackpotType = 'Progressive';
    this.jackpotTextSize = 48;

    this.setup();
  }

  updateJackpotValue() {
    setInterval(async () => {
      const result = await api.getJackpot(this.jackpotType, this.options.currency);
      if (result.potPrimaryTotalAmount > this.jackpotConfiguration.potPrimaryTotalAmount) {
        clearInterval(this.jackpotAnimationInterval);
        assign(this.jackpotConfiguration, {
          value: 0,
        });
      }
      if (result.potPrimaryTotalAmount > 0 && result.potPrimaryTotalAmount
        !== this.jackpotConfiguration.potPrimaryTotalAmount) {
        assign(this.jackpotConfiguration, result);
        this.animateJackpotValue({ value: 0 }, this.jackpotConfiguration.potPrimaryTotalAmount, 0, 0.01, 1);
      }
    }, 60000);
  }

  updateJackpotValuePosition(start) {
    this.jackpot.text = slotState.getMoneyLabel(start.value);
  }

  animateJackpotValue(start, end, delay, stepValue, diffLimitValue) {
    if (!end) {
      this.updateJackpotValuePosition({ value: 0 });
      return;
    }

    setTimeout(() => {
      const diffAbs = Math.abs(end - start.value);
      const diffLimit = defaultTo(diffLimitValue, 300);

      if (diffAbs > diffLimit) {
        assign(start, {
          value: start.value !== 0 ? start.value : end - diffLimit,
        });
        this.updateJackpotValuePosition(start);
      }

      if (isNil(stepValue)) {
        stepValue = [0.05, 0.11]; // eslint-disable-line no-param-reassign
      }

      const diff = Math.floor(end - start.value);
      const step = isArray(stepValue) ? random(stepValue[0], stepValue[1]) : stepValue;
      const range = diff / step;
      const increment = end > start.value ? step : -step;
      let stepTime = Math.abs(Math.floor(60000 / range));

      if (stepTime === Infinity) {
        stepTime = 0;
      }

      clearInterval(this.jackpotAnimationInterval);
      this.jackpotAnimationInterval = setInterval(() => {
        assign(start, {
          value: start.value + increment,
        });
        this.updateJackpotValuePosition(start);

        if ((increment > 0 && start.value >= end) || (increment < 0 && start.value <= end)) {
          assign(start, {
            value: end,
          });
          this.updateJackpotValuePosition(start);
          clearInterval(this.jackpotAnimationInterval);
        }
      }, stepTime);
    }, defaultTo(delay, 0));
  }

  createBackground() {
    const background = new Graphics();

    background.clear();
    background.beginFill(this.backgroundColor, 0.65);
    background.drawRoundedRect(this.options.calcWidth / -5.5, 0, this.options.calcWidth / 2.75, this.height, 50);
    background.endFill();

    return background;
  }

  async setup() {
    const textStyle = {
      fontName: slotState.options.fontNameBold,
      tint: this.textColor,
    };

    this.jackpotConfiguration = await api.getJackpot(this.jackpotType, this.options.currency);

    this.container.x = this.options.calcWidth / 2;
    this.container.y = this.options.uiPadding;

    this.background = this.createBackground();
    this.container.addChild(this.background);

    this.label = new BitmapText(this.labelText, {
      ...textStyle,
      fontSize: this.labelTextSize,
    });
    this.label.alpha = 0.7;
    this.label.y = this.height / 3.5;
    this.label.anchor.set(0.5);
    this.container.addChild(this.label);

    this.jackpot = new BitmapText(this.jackpot, {
      ...textStyle,
      fontSize: this.jackpotTextSize,
    });
    this.jackpot.y = this.height / 1.5;
    this.jackpot.anchor.set(0.5);
    this.container.addChild(this.jackpot);

    this.animateJackpotValue({ value: 0 }, this.jackpotConfiguration.potPrimaryTotalAmount, 0, 0.01, 1);
    this.updateJackpotValue();
  }
}
