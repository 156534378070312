import { Container } from '../pixi';
import { SlotBaseWinlines } from './SlotBaseWinlines';
import { SlotWinlineTrail } from './SlotWinlineTrail';
import { audio } from './SlotAudio';

export class SlotParticlesWinlines extends SlotBaseWinlines {
  constructor(container, reelsContainer, position, isWithSymbol) {
    super(container, reelsContainer, position, isWithSymbol);

    this.winlineTrail = undefined;
    this.winlineTrailSpeed = 1.0;

    this.start();
    this.startTrail();
  }

  startTrail() {
    this.winlinesAmountContainer = new Container();

    this.winlineTrail = new SlotWinlineTrail(
      this.winlinesContainer,
      this.winlinesAmountContainer,
    );

    this.container.addChild(this.winlinesAmountContainer);
  }

  async showWinLines({
    applyMultiplierOnWin,
    isFree,
    isFreeRoundsEnd,
    isFreeRoundsWon,
    isSyncWinAmount,
    multiplierApplyCount,
    multiplierValue,
    showIndividualWinlines = true,
    winAmount,
    winLines,
  }) {
    if (this.isEmptyWinlines(winLines)) {
      this.enableSymbols();

      if (winAmount > 0) {
        await this.showAllWinLines({
          applyMultiplierOnWin,
          isFree,
          isFreeRoundsEnd,
          isFreeRoundsWon,
          isSyncWinAmount,
          multiplierApplyCount,
          multiplierValue,
          winAmount,
          winLines,
        });
      }

      return false;
    }

    const that = this;
    let isResolved = false;

    return new Promise((resolve) => {
      this.addStartAnimation(winLines, multiplierApplyCount, resolve, () => {
        this.showAllWinLines({
          applyMultiplierOnWin,
          isFree,
          isFreeRoundsEnd,
          isFreeRoundsWon,
          multiplierApplyCount,
          multiplierValue,
          winAmount,
          winLines,
        });
      });

      winLines.forEach((winLine, winlineIndex) => {
        this.winlinesLoopTimeline.to(this.symbolSegments.hit[winlineIndex], {
          delay: this.winlineDelayRepeat,
          duration: this.winlineShowDuration,
          pixi: {
            alpha: 1.0,
          },
          onComplete() {
            if (this.repeat() === 0) {
              that.hideAllWinLines();

              if (!isResolved) {
                resolve(true);
                isResolved = true;
              }
            }

            if (showIndividualWinlines === false) {
              if (!isResolved) {
                resolve(true);
              }
              return;
            }

            that.winlineTrail.play(
              winLine,
              that.getPoints(winLines, winlineIndex),
              that.winlineTrailSpeed,
            );

            this.targets().forEach((symbol) => {
              if (that.isWithSymbol) {
                symbol.$ref.showHit();
              }
            });
          },
        }, '<');

        if (showIndividualWinlines) {
          this.addWinlineEndAnimation(winlineIndex);
        }
      });

      this.addEndAnimation();
    });
  }

  async showAllWinLines({
    applyMultiplierOnWin,
    isFree,
    isFreeRoundsEnd,
    isFreeRoundsWon,
    isSyncWinAmount,
    multiplierApplyCount,
    multiplierValue,
    winAmount,
    winLines,
  }) {
    if (this.isEmptyWinlines(winLines) && winAmount <= 0) {
      return false;
    }

    return new Promise((resolve) => {
      audio.play(this.options.assets.soundReelWinline);

      this.winlinesAmountContainer.alpha = 1;
      this.winlinesAmountContainer.addChild(this.winlinesAmount.container);

      this.winlinesAmount.show({
        animationDuration: this.getWinlineAnimateAllDuration(multiplierApplyCount),
        applyMultiplierOnWin,
        isFree,
        isFreeRoundsEnd,
        isFreeRoundsWon,
        isSyncWinAmount,
        multiplierValue,
        position: this.getMiddlePoint(),
        winAmount,
        resolve,
      });

      if (!isSyncWinAmount) {
        resolve(true);
      }
    });
  }

  hideAllWinLines() {
    this.winlinesAmountContainer.removeChildren();
  }

  async stopWinLines() {
    return new Promise((resolve) => {
      this.stop();
      this.hideAllWinLines();
      this.winlineTrail.stop();
      resolve(true);
    });
  }
}
