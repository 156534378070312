import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, BitmapText } from '@/pixi';

export class Tooltip {
  constructor({
    name = 'Tooltip', // Add different name if more tooltips are used on same parentContainer
    element, // element needs to be interactive for tooltip to work
    parentContainer,
    text,
    textSize = 26,
    fontName = 'RobotoNormal',
    backgroundColor = 0x000000,
    textColor = 0xffffff,
    padding = 10,
    position = 'top', // Possible positions: 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end'
    offset = 10,
  }) {
    this.name = name;
    this.container = new Container();
    this.element = element;
    this.parentContainer = parentContainer;
    this.text = text;
    this.textSize = textSize;
    this.fontName = fontName;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.padding = padding;
    this.position = position;
    this.offset = offset;
    this.label = undefined;
    this.background = undefined;
    this.arrow = undefined;

    this.destroyOldTooltip();
    this.setup();
  }

  setup() {
    const elementBounds = this.element.getLocalBounds();
    const elementCenterX = this.element.x + elementBounds.left + elementBounds.width / 2;
    const elementCenterY = this.element.y + elementBounds.top + elementBounds.height / 2;

    this.container.name = this.name;
    this.setVisibility(false);

    this.label = new BitmapText(this.text, {
      fontName: this.fontName,
      fontSize: this.textSize,
      tint: this.textColor,
    });
    this.label.x = this.padding;
    this.label.y = this.padding;

    this.background = new Graphics()
      .beginFill(this.backgroundColor)
      .drawRoundedRect(0, 0, this.label.width + this.padding * 2, this.label.height + this.padding * 2, 10);

    this.arrow = new Graphics()
      .beginFill(this.backgroundColor)
      .lineTo(15, 0)
      .lineTo(0, 15)
      .lineTo(-15, 0)
      .lineTo(0, 0)
      .endFill();

    this.container.addChild(this.background);
    this.container.addChild(this.arrow);
    this.container.addChild(this.label);

    switch (this.position) {
      case 'bottom':
        this.arrow.x = this.background.width / 2;
        this.arrow.angle = 180;
        this.container.x = elementCenterX - this.container.width / 2;
        this.container.y = elementCenterY + elementBounds.height / 2 + this.arrow.height + this.offset;
        break;
      case 'bottom-start':
        this.arrow.x = elementBounds.width / 2;
        this.arrow.angle = 180;
        this.container.x = elementCenterX - elementBounds.width / 2;
        this.container.y = elementCenterY + elementBounds.height / 2 + this.arrow.height + this.offset;
        break;
      case 'bottom-end':
        this.arrow.x = this.background.width - elementBounds.width / 2;
        this.arrow.angle = 180;
        this.container.x = elementCenterX + elementBounds.width / 2 - this.container.width;
        this.container.y = elementCenterY + elementBounds.height / 2 + this.arrow.height + this.offset;
        break;
      case 'top-start':
        this.arrow.x = elementBounds.width / 2;
        this.arrow.y = this.background.height;
        this.container.x = elementCenterX - elementBounds.width / 2;
        this.container.y = elementCenterY - elementBounds.height / 2 - this.container.height - this.offset;
        break;
      case 'top-end':
        this.arrow.x = this.background.width - elementBounds.width / 2;
        this.arrow.y = this.background.height;
        this.container.x = elementCenterX + elementBounds.width / 2 - this.container.width;
        this.container.y = elementCenterY - elementBounds.height / 2 - this.container.height - this.offset;
        break;
      case 'top':
      default:
        this.arrow.x = this.background.width / 2;
        this.arrow.y = this.background.height;
        this.container.x = elementCenterX - this.container.width / 2;
        this.container.y = elementCenterY - elementBounds.height / 2 - this.container.height - this.offset;
    }

    this.pointerenterEventHandler = () => { this.setVisibility(true); };
    this.element.on('pointerenter', this.pointerenterEventHandler);

    this.pointerleaveEventHandler = () => { this.setVisibility(false); };
    this.element.on('pointerleave', this.pointerleaveEventHandler);

    this.parentContainer.addChild(this.container);
  }

  setVisibility(value) {
    this.container.visible = value;
  }

  destroyOldTooltip() {
    const childIndex = this.parentContainer.children.findIndex((child) => child.name === this.name);

    if (childIndex > -1) {
      this.element.off('pointerenter', this.pointerenterEventHandler);
      this.element.off('pointerleave', this.pointerleaveEventHandler);

      this.parentContainer.children[childIndex].destroy({
        children: true,
        texture: true,
      });
    }
  }
}
