import { assign, reverse } from 'lodash';
import { Container } from '../pixi';
import { SlotBurstEmitter } from './SlotBurstEmitter';
import { slotState } from './SlotState';

/*
This is a component that serves as a container
for elements that animate accros containers,
like progress or collect symbol clones that go
to header or footer.
*/
export class SlotReelsOverlay {
  constructor() {
    this.container = new Container();
    this.elementContainer = new Container();
    this.emitterContainer = new Container();

    this.elementContainer.sortableChildren = true;
    this.emitterContainer.visible = false;

    this.emitter = new SlotBurstEmitter(
      this.emitterContainer,
      slotState.options.winlineColors,
      {
        minScaleMultiplier: 3,
        minLifetime: 0.5,
        maxLifetime: 0.75,
        maxParticles: 100,
        particleFrequency: 0.25,
      },
    );

    this.container.addChild(this.elementContainer);
    this.container.addChild(this.emitterContainer);
  }

  add(element) {
    /*
    Sorting children by zIndex.
    This is required for stacking of elements.
    */
    assign(element.container, {
      zIndex: this.elementContainer.children.length,
    });

    this.elementContainer.addChild(element.container);
  }

  getByIndex(index) {
    return this.elementContainer.children[index];
  }

  getChildren() {
    return this.elementContainer.children;
  }

  clear() {
    this.elementContainer.removeChildren();
  }

  reverse() {
    reverse(this.elementContainer.children);
  }

  showEmitter(position) {
    this.emitterContainer.visible = true;
    this.emitterContainer.position.set(position.x, position.y);
    this.emitter.show();
  }

  hideEmitter() {
    this.emitterContainer.visible = false;
    this.emitter.hide();
  }
}
