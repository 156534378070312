import animate from 'gsap';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, BitmapText } from '@/pixi';

export class Label {
  #container;

  constructor({
    text,
    backgroundColor = 0xffe843,
    textColor = 0x000000,
    fontName = 'RobotoNormal',
    verticalPadding = 8,
    horizontalPadding = 50,
    textSize = 32,
    borderRadius = 10,
  }) {
    const lineWidth = 4;

    this.#container = new Container();
    this.#container.name = 'Label';
    this.#container.eventMode = 'static';

    const label = new BitmapText(text.toUpperCase(), {
      fontName,
      fontSize: textSize,
      tint: textColor,
    });

    const background = new Graphics()
      .lineStyle(lineWidth, backgroundColor)
      .beginFill(backgroundColor)
      .drawRoundedRect(0, 0, label.width + horizontalPadding * 2, label.height + verticalPadding * 2, borderRadius)
      .endFill();

    label.x = background.width / 2 - lineWidth / 2;
    label.y = background.height / 2 - lineWidth / 2;
    label.anchor.set(0.5);

    this.#container.addChild(background, label);
  }

  get container() {
    return this.#container;
  }

  setVisible(visible, destroy) {
    if (visible) {
      animate.to(this.#container, {
        duration: 0.5,
        pixi: {
          alpha: 1,
        },
        onStart: () => {
          this.#container.visible = true;
        },
      });
    } else {
      animate.to(this.#container, {
        duration: 0.5,
        pixi: {
          alpha: 0,
        },
        onComplete: () => {
          this.#container.visible = false;

          if (destroy) {
            this.#container.destroy();
          }
        },
      });
    }
  }
}
