import { Container, Sprite, Texture } from '../pixi';
import { slotState } from './SlotState';
import { Loader } from './shared/Loader';

export class SlotReelsLoader {
  #container;
  #background;
  #loader;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'SlotReelsLoader';
    this.#container.eventMode = 'dynamic';

    this.#background = new Sprite(Texture.WHITE);
    this.#background.tint = 0x000000;
    this.#background.alpha = 0.4;

    this.#loader = new Loader({});

    this.#container.addChild(this.#background, this.#loader.container);
  }

  showLoader() {
    slotState.reels.container.alpha = 0.3;
    slotState.controls.container.alpha = 0.3;
    slotState.controls.controlsHeader.uiBoost?.disable('0.3');
    slotState.symbolDetail.hide();

    if (slotState.reelsFooter.winAmountContainer.alpha) {
      slotState.reelsFooter.winAmountContainer.alpha = 0.3;
    }

    slotState.container.addChild(this.#container);
  }

  hideLoader() {
    if (slotState.container.getChildByName(this.#container.name)) {
      slotState.reels.container.alpha = 1;
      slotState.controls.container.alpha = 1;
      slotState.controls.controlsHeader.uiBoost?.enable();

      if (slotState.reelsFooter.winAmountContainer.alpha === 0.3) {
        slotState.reelsFooter.winAmountContainer.alpha = 1;
      }

      slotState.container.removeChild(this.#container);
    }
  }

  setPosition() {
    const size = slotState.options.size();
    const scale = slotState.container.scale.y;
    const width = size.width / scale;
    const height = size.height / scale;
    const reelBackgroundBounds = slotState.reelBackground.container.getBounds();

    this.#background.width = width;
    this.#background.height = height;

    this.#loader.container.x = reelBackgroundBounds.x / scale + (reelBackgroundBounds.width / scale) / 2;
    this.#loader.container.y = reelBackgroundBounds.y / scale + (reelBackgroundBounds.height / scale) / 2;
  }
}
