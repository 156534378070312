import { defaultTo } from 'lodash';
import animate from 'gsap';
import { Container, AnimatedSprite, Sprite } from '../pixi';
import { slotState } from './SlotState';

export class SlotSymbolHit {
  constructor() {
    this.isAnimated = false;
    this.showDuration = 0.1;
    this.hideDuration = 0.1;
    this.container = new Container();
    this.sprite = undefined;
    this.container.visible = false;
    this.container.alpha = 0;

    this.createSprite();
  }

  get width() {
    return this.container.width;
  }

  get height() {
    return this.container.height;
  }

  createSprite() {
    const textures = slotState.options.assets.symbolHit.resource;

    if (textures.length > 1) {
      this.sprite = new AnimatedSprite(textures);
      this.sprite.animationSpeed = 0.8;
      this.sprite.loop = true;
      this.isAnimated = true;
      this.sprite.anchor.set(0.5);
      this.container.addChild(this.sprite);
    } else if (textures.length === 1) {
      this.sprite = new Sprite(textures[0]);
      this.sprite.anchor.set(0.5);
      this.container.addChild(this.sprite);
    }
  }

  scaleTo(x, y) {
    if (this.sprite) {
      this.sprite.scale.x = x / this.sprite.width;
      this.sprite.scale.y = y / this.sprite.height;
    }
  }

  positionTo(x, y) {
    this.container.x = x;
    this.container.y = y;
  }

  play() {
    if (this.sprite && this.isAnimated) {
      this.sprite.play();
    }
  }

  async show(delay) {
    const that = this;

    return new Promise((resolve) => {
      animate.to(this.container, {
        delay: defaultTo(delay, 0),
        duration: this.showDuration,
        pixi: {
          alpha: 1,
        },
        onComplete() {
          that.container.visible = true;

          if (that.sprite && that.sprite.playing === false) {
            that.sprite.gotoAndPlay(0);
          }

          resolve();
        },
      });
    });
  }

  hide() {
    this.container.alpha = 0;
    this.container.visible = false;

    if (this.sprite) {
      this.sprite.gotoAndStop(0);
    }
  }
}
