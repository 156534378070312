import animate from 'gsap';
import { isNil } from 'lodash';
import { Container, Graphics, Sprite } from '../pixi';
import { registerEventListener } from '../utility/Utility';
import { slotState } from './SlotState';

export class SlotReelBackground {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.sprite = undefined;
    this.spriteFree = undefined;
    this.spriteMask = undefined;
    this.timeline = undefined;
    this.timelineFree = undefined;
    this.toggleDuration = 0.5;

    this.setup();
    this.setupTimelines();
    this.setListeners();
    this.start();
  }

  get width() {
    return this.container.width;
  }

  get height() {
    return this.container.height;
  }

  get bounds() {
    return this.container.getBounds();
  }

  get scale() {
    return this.container.worldTransform.a;
  }

  getMask() {
    const paddingX = Math.ceil(this.options.reelPadding[0] * this.scale);
    const paddingY = Math.ceil(this.options.reelPadding[1] * this.scale);
    const doublePaddingX = paddingX * 2;
    const doublePaddingY = paddingY * 2;

    const mask = this.spriteMask || new Graphics().beginFill(0x000000).drawRect(
      this.bounds.x + paddingX,
      this.bounds.y + paddingY,
      this.bounds.width - doublePaddingX,
      this.bounds.height - doublePaddingY,
    ).endFill();

    return mask;
  }

  setup() {
    const { assets } = this.options;

    this.sprite = new Sprite(assets.reelsBackground.resource);
    this.sprite.visible = false;

    if (assets.reelsBackgroundFree) {
      this.spriteFree = new Sprite(assets.reelsBackgroundFree.resource);
      this.spriteFree.visible = false;
      this.container.addChild(this.spriteFree);
    }

    if (assets.reelsBackgroundMask) {
      this.spriteMask = new Sprite(assets.reelsBackgroundMask.resource);
      this.container.addChild(this.spriteMask);
    }

    this.container.y = slotState.reelsHeader.height;
    this.container.addChild(this.sprite);
  }

  setupTimelines() {
    if (isNil(this.sprite) || isNil(this.spriteFree)) {
      return;
    }

    this.setupTimeline();
    this.setupTimelineFree();
  }

  setupTimeline() {
    const that = this;

    this.timeline = animate.timeline({
      paused: true,
      onStart() {
        that.sprite.visible = true;
        that.spriteFree.visible = true;
      },
      onComplete() {
        that.sprite.visible = false;
      },
    });

    this.timeline.fromTo(this.sprite, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 0,
      },
    });

    this.timeline.fromTo(this.spriteFree, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 1,
      },
    }, '<');
  }

  setupTimelineFree() {
    const that = this;

    this.timelineFree = animate.timeline({
      paused: true,
      onStart() {
        that.sprite.visible = true;
        that.spriteFree.visible = true;
      },
      onComplete() {
        that.spriteFree.visible = false;
      },
    });

    this.timelineFree.fromTo(this.sprite, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 1,
      },
    });

    this.timelineFree.fromTo(this.spriteFree, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 0,
      },
    }, '<');
  }

  setListeners() {
    const source = 'SlotReelBackground';

    registerEventListener('ToggleFreeRounds', () => {
      this.toggle();
    }, source);
  }

  start() {
    this.sprite.alpha = 1;
    this.sprite.visible = true;

    if (this.spriteFree) {
      this.spriteFree.visible = false;
    }
  }

  startFree() {
    this.spriteFree.alpha = 1;
    this.spriteFree.visible = true;
    this.sprite.visible = false;
  }

  toggle() {
    if (isNil(this.spriteFree)) {
      this.sprite.alpha = 1;
      this.sprite.visible = true;
      return;
    }

    const isInFreeRounds = slotState.availableFreeRounds > 0 && !slotState.activePromotion;

    if ((this.spriteFree.visible && isInFreeRounds) || (this.sprite.visible && !isInFreeRounds)) return;

    if (this.spriteFree.visible && !isInFreeRounds) {
      this.timelineFree.play(0);
    } else if (this.sprite.visible && isInFreeRounds) {
      this.timeline.play(0);
    } else if (isInFreeRounds) {
      this.startFree();
    } else {
      this.start();
    }
  }
}
