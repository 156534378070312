import { Checkbox } from './shared/Checkbox';
import { slotState } from './SlotState';

export class SlotCheckbox extends Checkbox {
  constructor(params) {
    super({
      ...SlotCheckbox.getDefaultParams(),
      ...params,
    });
  }

  static getDefaultParams = () => ({
    iconOffAsset: slotState.options.assets.iconOff,
    iconOnAsset: slotState.options.assets.iconOn,
    colorActive: slotState.options.colors.highlightBrighter,
    playTapSound: () => { slotState.playTapSound(); },
  });
}
