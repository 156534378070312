import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { BitmapText, Container } from '@/pixi';

export class DialogContentBetAmount {
  constructor({
    betAmounts, // Required, Array.<Number>
    getActiveBetAmount, // Required, Function, should return Number
    getBalanceAmount, // Required, Function, should return Number
    onChange, // Required, Function
    formatBetAmount, // Optional, Function, should return String
    playTapSound, // Optional, Function
    fontName = 'RobotoNormal',
    betAmountColor = 0x7d7d7d,
    betAmountColorActive = 0xffe843,
    padding = 48,
  }) {
    this.betAmounts = betAmounts;
    this.getActiveBetAmount = getActiveBetAmount;
    this.getBalanceAmount = getBalanceAmount;
    this.onChange = onChange;
    this.formatBetAmount = formatBetAmount;
    this.playTapSound = playTapSound;
    this.betAmountColor = betAmountColor;
    this.betAmountColorActive = betAmountColorActive;
    this.padding = padding;

    this.container = new Container();
    this.labelStyle = {
      fontName,
      fontSize: 48,
      tint: this.betAmountColor,
    };

    this.setup();
  }

  setup() {
    this.betAmounts.forEach((amount) => {
      const container = new Container();
      const label = new BitmapText(
        this.formatBetAmount ? this.formatBetAmount(amount) : amount,
        this.labelStyle,
      );

      label.anchor.set(0.5);

      container.eventMode = 'static';
      container.cursor = 'pointer';
      container.on('pointertap', () => {
        this.playTapSound?.();
        this.setValue.bind(this)(amount);
      });

      container.addChild(label);

      this.container.addChild(container);
    });

    this.container.x = this.padding;
  }

  setPosition({ width }) {
    const columnCount = 3;
    const itemPadding = this.padding;
    const itemWidth = (width - (itemPadding * (columnCount + 1))) / columnCount;
    const itemHeight = itemWidth / 2.5;
    const itemRadius = itemHeight / 2;

    let row = -1;
    let column = 0;

    this.container.children.forEach((item, index) => {
      if (index % columnCount === 0) {
        row += 1;
        column = 0;
      }

      Object.assign(item, {
        x: (itemWidth * column) + (itemPadding * column),
        y: (itemHeight * row) + (itemPadding * row) + this.padding,
      });

      Object.assign(item.children[0], {
        x: itemWidth / 2,
        y: itemHeight / 2,
      });

      if (item.children[0] instanceof Graphics) {
        item.removeChildAt(0);
      }

      item.addChildAt(this.createPill(itemWidth, itemHeight, itemRadius), 0);

      column += 1;
    });

    this.setActiveItem();
  }

  createPill(width, height, radius, isActive = false) {
    const pill = new Graphics();

    pill.settings = {
      width,
      height,
      radius,
    };

    pill.clear();
    pill.lineStyle(3, isActive ? this.betAmountColorActive : this.betAmountColor);
    pill.beginFill(0x000000);
    pill.drawRoundedRect(0, 0, width, height, radius);
    pill.endFill();

    return pill;
  }

  setValue(value) {
    this.onChange(value);

    this.show();

    // Call hide from Dialog.js
    this.dialog.hide();
  }

  resetItems() {
    this.betAmounts.forEach((amount, itemIndex) => {
      const item = this.container.children[itemIndex];
      const pill = item.getChildAt(0);
      const label = item.getChildAt(1);
      const inactivePill = this.createPill(pill.settings.width, pill.settings.height, pill.settings.radius, false);
      const interactive = amount <= this.getBalanceAmount();

      item.removeChildAt(0);
      item.addChildAt(inactivePill, 0);
      item.eventMode = interactive ? 'static' : 'none';
      item.alpha = interactive ? 1 : 0.5;

      label.tint = this.betAmountColor;
    });
  }

  setActiveItem() {
    const activeItemIndex = this.betAmounts.indexOf(this.getActiveBetAmount());

    if (activeItemIndex > -1) {
      const item = this.container.getChildAt(activeItemIndex);
      const pill = item.getChildAt(0);
      const label = item.getChildAt(1);
      const activePill = this.createPill(pill.settings.width, pill.settings.height, pill.settings.radius, true);

      item.eventMode = 'none';
      item.removeChildAt(0);
      item.addChildAt(activePill, 0);

      label.tint = this.betAmountColorActive;
    }
  }

  show() {
    this.resetItems();
    this.setActiveItem();
  }
}
