import animate, { Power4 } from 'gsap';
import { defaultTo, isNil, each, isNumber, isFunction, isEqual, isEmpty } from 'lodash';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import { MotionBlurFilter } from '@pixi/filter-motion-blur';
import { Container, Graphics, Point, Sprite, Texture } from '../pixi';
import { registerEventListener } from '../utility/Utility';
import { SlotNumber } from './SlotNumber';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';

export class SlotReelsMultipliers {
  constructor() {
    this.options = slotState.options;
    this.container = undefined;
    this.multiplier = undefined;
    this.multiplierClone = undefined;
    this.multiplierContainer = undefined;
    this.multiplierValue = defaultTo(this.options.multiplierValue, 1);
    this.multiplierBackground = undefined;
    this.collectMultiplier = undefined;
    this.collectMultiplierClone = undefined;
    this.collectMultiplierBackground = undefined;
    this.collectMultiplierContainer = undefined;
    this.collectMultiplierValue = this.options.collect ? defaultTo(this.options.collect.multiplier, 1) : 1;
    this.multiplierDuration = 0.2;
    this.multiplierScale = [0.3, 0.22, 0.18];
    this.symbolDuration = 0.75;
    this.timeline = undefined;

    this.create();
    this.show();
    this.setListeners();
  }

  create() {
    this.container = new Container();

    this.multiplierContainer = new Container();
    this.multiplier = new SlotNumber(this.multiplierValue, 'x', 0);
    this.multiplierBackground = new Sprite(this.options.assets.reelsMultiplierBackground.resource);
    this.multiplierBackground.anchor.set(0.5);
    this.multiplier.container.scale.set(this.getMultiplierScale(this.multiplierValue));
    this.multiplierContainer.addChild(this.multiplierBackground);
    this.multiplierContainer.addChild(this.multiplier.container);
    this.container.addChild(this.multiplierContainer);

    const { collect } = this.options.config;

    if (collect && !isEqual(collect.multiplierRange, [0, 0])) {
      this.collectMultiplierContainer = new Container();
      this.collectMultiplier = new SlotNumber(this.collectMultiplierValue, 'x', 0);
      this.collectMultiplierBackground = new Sprite(this.options.assets.reelsMultiplierCollectBackground.resource);
      this.collectMultiplierBackground.anchor.set(0.5);
      this.collectMultiplier.container.scale.set(this.getMultiplierScale(this.collectMultiplierValue));
      this.collectMultiplierContainer.addChild(this.collectMultiplierBackground);
      this.collectMultiplierContainer.addChild(this.collectMultiplier.container);
      this.container.addChild(this.collectMultiplierContainer);
    }
  }

  show() {
    const footerSize = slotState.reelsFooter.size;

    this.container.y = footerSize.y;

    this.multiplierContainer.visible = false;
    this.multiplierContainer.eventMode = 'static';
    this.multiplierContainer.x = footerSize.width - (this.multiplierBackground.width * 0.75);
    this.multiplier.container.x = -(this.multiplier.container.width / 2);
    this.multiplier.container.y = -(this.multiplier.container.height / 2);

    if (this.collectMultiplierContainer) {
      this.collectMultiplierContainer.eventMode = 'static';
      this.collectMultiplierContainer.visible = this.options.showCollectMultiplier;
      this.collectMultiplierContainer.x = (this.multiplierBackground.width * 0.75);
      this.collectMultiplier.container.x = -(this.collectMultiplier.container.width / 2);
      this.collectMultiplier.container.y = -(this.collectMultiplier.container.height / 2);
    }

    this.showMultiplier(this.multiplierValue);
  }

  setListeners() {
    const source = 'SlotReelsMultipliers';

    registerEventListener('ReelsStarting', () => {
      this.stopMultiplier();
    }, source);

    registerEventListener('ReelsEnded', () => {
      this.stopMultiplier();
    }, source);

    registerEventListener('ToggleMultiplier', (event) => {
      this.options.showMultiplier = event.detail.show;
      this.show();
    }, source);

    registerEventListener('ToggleCollectMultiplier', (event) => {
      this.options.showCollectMultiplier = event.detail.show;
      this.show();
    }, source);

    registerEventListener('UpdateMultiplier', (event) => {
      this.updateMultiplier(event.detail.multiplier);
    }, source);

    registerEventListener('UpdateCollectMultiplier', (event) => {
      this.updateCollectMultiplier(event.detail.multiplier);
    }, source);
  }

  createMultiplierClone(value, position) {
    const container = new Container();
    const multiplier = new SlotNumber(value, 'x', 0);

    container.x = position.x;
    container.y = position.y;
    multiplier.container.scale.set(this.getMultiplierScale(value));
    multiplier.container.x = -(multiplier.container.width / 2);
    multiplier.container.y = -(multiplier.container.height / 2);

    container.$ref = multiplier;
    container.addChild(multiplier.container);

    return container;
  }

  createMultiplierSymbolClone(symbol) {
    const symbolClone = symbol;
    const symbolExposure = new Sprite(symbolClone.$ref.sprite.texture);
    const symbolExposureMask = new Sprite(Texture.WHITE);
    const symbolCloneMask = new Graphics().beginFill(0x000000).drawRect(-symbolClone.width / 2, -symbolClone.width / 2, symbolClone.width, symbolClone.height).endFill();

    symbolExposureMask.anchor.set(0.5);
    symbolExposureMask.width = symbolClone.width * 2;
    symbolExposureMask.height = symbolClone.height * 0.25;
    symbolExposureMask.y = -(symbolClone.height + symbolExposureMask.height);
    symbolExposureMask.angle = -45;
    symbolExposure.anchor.set(0.5);
    symbolExposure.scale.x = symbolClone.$ref.sprite.scale.x;
    symbolExposure.scale.y = symbolClone.$ref.sprite.scale.y;
    symbolExposure.filters = [new AdjustmentFilter({ brightness: 3 })];

    symbolClone.mask = symbolCloneMask;
    symbolExposure.mask = symbolExposureMask;
    symbolClone.addChild(symbolExposureMask);
    symbolClone.addChild(symbolExposure);
    symbolClone.addChild(symbolCloneMask);

    return {
      symbolClone,
      symbolExposureMask,
    };
  }

  getCollectMultiplierPosition() {
    return {
      x: this.container.x + this.collectMultiplierContainer.x,
      y: this.container.y + this.collectMultiplierContainer.y,
    };
  }

  getMultiplierScale(value) {
    if (value >= 100) {
      return this.multiplierScale[2];
    }

    if (value >= 10) {
      return this.multiplierScale[1];
    }

    return this.multiplierScale[0];
  }

  stopMultiplier() {
    if (this.multiplierClone) {
      this.multiplierClone.visible = false;
      this.multiplierClone = undefined;
    }
  }

  stopCollectMultiplier() {
    if (this.collectMultiplierClone) {
      this.collectMultiplierClone.visible = false;
      this.collectMultiplierClone = undefined;
    }
  }

  async showMultiplier(value) {
    if (slotState.isInFreeRounds
      && !slotState.isPromotionStopped
      && !slotState.isPromotion
      && (value > 1 || slotState.isIncreasingFreeRoundMultiplier)) {
      await this.updateMultiplier(value);
      this.multiplierContainer.visible = true;
    }
  }

  async hideMultiplier() {
    await this.updateMultiplier(1);
    this.multiplierContainer.visible = false;
  }

  async updateMultiplier(value) {
    const previousValue = this.multiplierValue;

    return new Promise((resolve) => {
      if (value !== previousValue) {
        this.multiplierValue = value;
        this.multiplier.progressTo({
          amount: value,
          amountFrom: previousValue,
          duration: this.multiplierDuration,
          parent: this.multiplierContainer,
          onUpdate: () => {
            this.multiplier.container.scale.set(this.getMultiplierScale(this.multiplierValue));
            this.multiplier.container.x = -(this.multiplier.container.width / 2);
            this.multiplier.container.y = -(this.multiplier.container.height / 2);
          },
          onComplete() {
            resolve(true);
          },
        });
      } else {
        resolve(false);
      }
    });
  }

  async updateCollectMultiplier(value) {
    const previousValue = this.collectMultiplierValue;

    return new Promise((resolve) => {
      if (value !== this.collectMultiplierValue) {
        this.collectMultiplierValue = value;
        this.collectMultiplier.progressTo({
          amount: value,
          amountFrom: previousValue,
          duration: this.multiplierDuration,
          parent: this.collectMultiplierContainer,
          onUpdate: () => {
            this.collectMultiplier.container.scale.set(this.getMultiplierScale(this.collectMultiplierValue));
            this.collectMultiplier.container.x = -(this.collectMultiplier.container.width / 2);
            this.collectMultiplier.container.y = -(this.collectMultiplier.container.height / 2);
          },
          onComplete() {
            if (value === 1) {
              slotState.updateCollect({
                multiplier: value,
              });
            }

            resolve(true);
          },
        });
      } else {
        resolve(false);
      }
    });
  }

  async increaseCollectMultiplier(value) {
    return this.updateCollectMultiplier(this.collectMultiplierValue + value);
  }

  applyMultiplier({
    duration,
    timeline,
    timelinePosition,
    resetValue,
  }) {
    if (this.multiplierValue <= 1) {
      return;
    }

    this.multiplierClone = this.createMultiplierClone(this.multiplierValue, this.multiplierContainer.position);

    this.multiplierClone.filters = [
      new MotionBlurFilter([0, 0], 15),
    ];

    const that = this;
    const size = slotState.reelBackground.container.getLocalBounds();
    const multiplierSize = this.multiplierClone.getLocalBounds();
    const multiplierBlur = this.multiplierClone.filters[0];

    const endPoint = {
      x: (size.width / 2) - (multiplierSize.width / 2),
      y: (-size.height / 2) - (multiplierSize.height / 2),
    };

    const middlePoint = {
      x: this.multiplierClone.x - ((this.multiplierClone.x - endPoint.x) / 2),
      y: this.multiplierClone.y,
    };

    timeline.to(this.multiplierClone, {
      duration: duration * 0.5,
      ease: Power4.easeIn,
      pixi: {
        scale: 1.1,
      },
      onStart() {
        if (that.multiplierClone) {
          that.container.addChild(that.multiplierClone);
        }
      },
    }, timelinePosition);

    timeline.to(this.multiplierClone, {
      duration: duration * 0.5,
      ease: Power4.easeIn,
      pixi: {
        scale: 1.4,
      },
      motionPath: {
        path: [middlePoint, endPoint],
      },
      onUpdate() {
        const progress = this.progress();
        const velocity = progress > 0.15 && progress < 0.85 ? progress * 10 : 0;
        multiplierBlur.velocity.y = velocity;
        multiplierBlur.velocity.x = velocity;
      },
      onComplete() {
        audio.play(that.options.assets.soundReelMultiplierHit);
        that.container.removeChild(that.multiplierClone);

        if (isNumber(resetValue)) {
          that.updateMultiplier(resetValue);
        }
      },
    }, '>');
  }

  applyCollectMultiplier({
    duration,
    timeline,
    timelinePosition,
    endPoint,
    value,
    resetValue,
    onComplete,
  }) {
    /*
    We can pass value to get exact collect multiplier
    value since this method can be called before timeline
    executes.
    */
    if (this.collectMultiplierValue <= 1 && (isNil(value) || value <= 0)) {
      return;
    }

    this.collectMultiplierClone = this.createMultiplierClone(
      this.collectMultiplierValue,
      this.collectMultiplierContainer.position,
    );

    const that = this;

    timeline.to(this.collectMultiplierClone, {
      duration: duration[0],
      ease: Power4.easeIn,
      pixi: {
        scale: 1.1,
      },
      onStart() {
        const target = this.targets()[0];

        if (target) {
          if (value) {
            target.$ref.create(that.collectMultiplierValue);
            target.$ref.center();
          }

          that.container.addChild(target);
        }
      },
    }, timelinePosition);

    timeline.to(this.collectMultiplierClone, {
      duration: duration[1],
      ease: Power4.easeIn,
      pixi: {
        scale: 0,
        x: endPoint.x,
        y: endPoint.y,
      },
      onComplete() {
        const target = this.targets()[0];

        audio.play(that.options.assets.soundReelMultiplierHit);
        that.container.removeChild(target);

        if (isFunction(onComplete)) {
          onComplete();
        }

        if (isNumber(resetValue)) {
          that.updateCollectMultiplier(resetValue);
        }
      },
    }, '>');
  }

  async applyMultiplierSymbols(multiplierValue) {
    const that = this;
    const symbolClones = slotState.reelsOverlay.getChildren();
    const multiplierSegmentValue = (multiplierValue - this.multiplierValue) / symbolClones.length;

    this.multiplierContainer.visible = isEmpty(slotState.activePromotion);

    return new Promise((resolve) => {
      this.timeline = animate.timeline({
        paused: true,
        onStart() {
          slotState.reels.winLines.disableSymbols();
          audio.play(that.options.assets.soundMultiplierScale);
        },
        onComplete() {
          slotState.reelsOverlay.clear();
          resolve(true);
        },
      });

      each(symbolClones, (child) => {
        const multiplierSymbolClone = this.createMultiplierSymbolClone(child);

        if (multiplierSymbolClone) {
          const { symbolClone, symbolExposureMask } = multiplierSymbolClone;

          this.timeline.to(symbolClone, {
            pixi: {
              scale: 1.1,
            },
            ease: Power4.easeIn,
            duration: this.symbolDuration,
          }, '<');

          this.timeline.to(symbolExposureMask, {
            pixi: {
              y: symbolClone.height + symbolExposureMask.height,
            },
            duration: this.symbolDuration,
          }, '<');
        }
      });

      each(symbolClones.reverse(), (child, childIndex) => {
        const symbolClone = slotState.reelsOverlay.getByIndex(childIndex);
        const multiplierPosition = this.multiplierContainer.toLocal(new Point(0, 0), slotState.reelsOverlay.container);

        this.timeline.to(symbolClone, {
          pixi: {
            x: -multiplierPosition.x,
            y: -multiplierPosition.y,
            scale: 0,
          },
          ease: Power4.easeIn,
          duration: this.symbolDuration,
          onStart() {
            audio.play(that.options.assets.soundMultiplierMove);
          },
          onComplete() {
            const multiplierIncreament = Math.ceil(that.multiplierValue + multiplierSegmentValue);

            audio.play(that.options.assets.soundMultiplierWin);
            that.updateMultiplier(multiplierIncreament);
          },
        });
      });

      this.timeline.play();
    });
  }
}
