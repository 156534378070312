import animate, { Power4, Elastic } from 'gsap';
import chroma from 'chroma-js';
import { clone, isNil, each, filter, range, reverse, map, defaultTo, keys, min } from 'lodash';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import { AnimatedSprite, Container, Graphics, Sprite, Texture, Ticker } from '../pixi';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';
import { registerEventListener } from '../utility/Utility';

export class SlotProgress {
  constructor(parent) {
    this.options = slotState.options;
    this.parent = parent;
    this.timeline = undefined;
    this.background = new Sprite(this.options.assets.progressBar.resource);
    this.container = new Container();
    this.segments = new Container();
    this.win = undefined;
    this.segmentCount = 7;
    this.segmentDuration = 0.75;
    this.segmentColors = chroma.scale(this.options.winlineColors).colors(this.segmentCount);

    /*
    We are cloning progress from state on initial creation
    since we are updating current progress on spin and we
    wish to do animation on value change. Without clone there
    would be no change in valueFrom and valueTo.
    */
    this.progress = this.options.progress ? clone(this.options.progress.current) : undefined;

    this.setup();
    this.setListeners();
  }

  get value() {
    return this.progress ? this.progress.unitValue : 0;
  }

  set value(value) {
    if (this.progress) {
      this.progress.unitValue = value;
    }
  }

  setListeners() {
    const source = 'SlotProgress';

    registerEventListener('ReelsRevealing', () => {
      this.reveal();
    }, source);

    registerEventListener('UpdateProgress', (event) => {
      const progress = event.detail;
      this.progressTo(progress.current.unitValue);
    }, source);
  }

  setup() {
    const { animations } = this.options.assets.progress.resource;
    const animationsList = [];
    for (let i = 0; i < 10; i++) animationsList.push(`progress_${i}`);
    const animationsCount = filter(keys(animations), (n) => animationsList.includes(n)).length;

    each(range(0, this.segmentCount), (index) => {
      const value = animationsCount === 1 ? 1 : index + 1;
      const segment = new AnimatedSprite(defaultTo(animations[`progress_${value}`], animations[`progress_${animationsCount - 1}`]));

      segment.x = segment.width * index;
      segment.loop = false;
      segment.animationSpeed = 0.4;
      segment.gotoAndStop(0);

      this.segments.addChild(segment);
    });

    this.container.addChild(this.background);
    this.container.addChild(this.segments);

    if (animations.progress_win) {
      this.win = new AnimatedSprite(animations.progress_win);

      this.win.alpha = 0;
      this.win.loop = false;
      this.win.animationSpeed = 0.4;
      this.win.gotoAndStop(0);

      this.container.addChild(this.win);
    }

    slotState.progressBar = this;
    this.isHidden();
  }

  stop() {
    if (this.timeline) {
      this.timeline.progress(1);
      this.timeline.kill();
    }

    this.win.alpha = 0;
  }

  reveal() {
    const segments = filter(this.segments.children, (segment, segmentIndex) => segmentIndex >= 0 && segmentIndex < this.value);

    segments.forEach((segment) => {
      segment.gotoAndPlay(0);
    });
  }

  createProgressSymbol(segmentIndex) {
    const symbolClone = slotState.reelsOverlay.getByIndex(segmentIndex);

    if (isNil(symbolClone)) {
      return undefined;
    }

    const symbolExposure = new Sprite(symbolClone.$ref.sprite.texture);
    const symbolExposureMask = new Sprite(Texture.WHITE);
    const symbolCloneMask = new Graphics().beginFill(0x000000).drawRect(-symbolClone.width / 2, -symbolClone.width / 2, symbolClone.width, symbolClone.height).endFill();

    symbolExposureMask.anchor.set(0.5);
    symbolExposureMask.width = symbolClone.width * 2;
    symbolExposureMask.height = symbolClone.height * 0.25;
    symbolExposureMask.y = -(symbolClone.height + symbolExposureMask.height);
    symbolExposureMask.angle = -45;
    symbolExposure.anchor.set(0.5);
    symbolExposure.scale.x = symbolClone.$ref.sprite.scale.x;
    symbolExposure.scale.y = symbolClone.$ref.sprite.scale.y;
    symbolExposure.filters = [new AdjustmentFilter({ brightness: 3 })];

    symbolClone.mask = symbolCloneMask;
    symbolExposure.mask = symbolExposureMask;
    symbolClone.addChild(symbolExposureMask);
    symbolClone.addChild(symbolExposure);
    symbolClone.addChild(symbolCloneMask);

    return {
      symbolClone,
      symbolExposureMask,
    };
  }

  async progressTo(value) {
    const valueTo = isNil(value) ? this.value : min([value, this.segmentCount]);
    const valueFrom = defaultTo(this.value, 0);

    if (valueTo === valueFrom) {
      return false;
    }

    return new Promise((resolve) => {
      const that = this;

      this.value = valueTo;
      this.stop();

      this.timeline = animate.timeline({
        paused: true,
        onStart() {
          slotState.reels.winLines.disableSymbols();
          audio.play(that.options.assets.soundProgressScale);
        },
        onComplete() {
          slotState.reelsOverlay.clear();

          if (valueTo === that.segmentCount) {
            that.win.alpha = 1;

            that.win.onComplete = () => {
              setTimeout(() => {
                that.win.alpha = 0;
                that.progressTo(0);
              }, slotState.controls.controlsMain.bonusGameWait);
              resolve(true);
            };

            audio.play(that.options.assets.soundProgressComplete);
            that.win.gotoAndPlay(0);
          } else {
            resolve(true);
          }
        },
      });

      if (valueFrom < valueTo) {
        const filteredSegments = filter(this.segments.children, (segment, segmentIndex) => segmentIndex >= valueFrom && segmentIndex < valueTo);

        each(filteredSegments, (segment, segmentIndex) => {
          const progressSymbol = this.createProgressSymbol(segmentIndex);

          if (progressSymbol) {
            const { symbolClone, symbolExposureMask } = progressSymbol;

            this.timeline.to(symbolClone, {
              pixi: {
                scale: 1.1,
              },
              ease: Elastic.easeOut.config(1, 0.5),
              duration: this.segmentDuration,
              onStart() {
                symbolClone.$ref.play();
              },
            }, '<');

            this.timeline.to(symbolExposureMask, {
              pixi: {
                y: symbolClone.height + symbolExposureMask.height,
              },
              duration: this.segmentDuration,
            }, '<');
          }
        });

        each(filteredSegments, (segment, segmentIndex) => {
          const symbolClone = slotState.reelsOverlay.getByIndex(segmentIndex);

          this.timeline.to(symbolClone, {
            pixi: {
              x: segment.x + (segment.width / 2),
              y: segment.y + (segment.height / 2) + that.container.y,
              scale: 0,
            },
            ease: Power4.easeIn,
            duration: this.segmentDuration,
            onStart() {
              audio.play(that.options.assets.soundProgressMove);
            },
          });

          this.timeline.to(symbolClone, {
            duration: segment.totalFrames / Ticker.shared.maxFPS,
            onStart() {
              audio.play(that.options.assets.soundProgressWin);
              segment.gotoAndPlay(0);
            },
          });
        });
      } else if (valueFrom > valueTo) {
        const segmentIndexes = reverse(map(this.segments.children, (n, i) => i));

        each(segmentIndexes, (segmentIndex) => {
          if (segmentIndex < valueFrom && segmentIndex >= valueTo) {
            that.segments.children[segmentIndex].gotoAndStop(0);
          }
        });
      }

      this.timeline.play();
    });
  }

  isHidden() {
    this.container.alpha = slotState.progress?.current?.hidden ? 0 : 1;
  }
}
