import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Sprite, Texture, Rectangle, BitmapText } from '@/pixi';
import * as api from '@/api/casino';
import { triggerEvent } from '@/utility/Utility';
import { Loader } from './shared/Loader';
import { ScrollBox } from './shared/ScrollBox';
import { Accordion } from './shared/Accordion';
import { Table } from './shared/Table';
import { SlotHistoryTable } from './SlotHistoryTable';
import { slotState } from './SlotState';

export class SlotRoundModal {
  #container;
  #background;
  #arrow;
  #line;
  #loader;
  #iframeRoundPreview;
  #scrollBox;
  #table;
  #roundIdText;
  #onClose;
  #headerHeight;
  #horizontalPadding;
  #tableRowHeight;
  #itemsMargin;
  #accordionParams;

  constructor({
    roundId,
    onClose,
  }) {
    this.#onClose = onClose;
    this.#headerHeight = 64 + slotState.options.uiDialogPadding * 2;
    this.#horizontalPadding = slotState.options.uiDialogPadding * 1.5;
    this.#tableRowHeight = 140;
    this.#itemsMargin = 40;
    this.#accordionParams = {
      headerHorizontalPadding: this.#horizontalPadding,
      headerMaxWidth: slotState.options.calcWidth,
      color: slotState.options.colors.text,
      highlightColor: slotState.options.colors.highlightBrighter,
      fontName: slotState.options.fontNameNormal,
      onToggle: this.#onAccordionToggle.bind(this),
      playTapSound: () => { slotState.playTapSound(); },
    };

    // Setup

    const scrollBoxItems = [];

    this.#container = new Container();

    this.#background = new Sprite(Texture.WHITE);
    this.#background.tint = 0x000000;
    this.#background.eventMode = 'dynamic';

    const arrowSize = 20;
    this.#arrow = new Graphics()
      .lineStyle(6, slotState.options.colors.text)
      .moveTo(-arrowSize, -arrowSize / 2)
      .lineTo(0, arrowSize / 2)
      .lineTo(arrowSize, -arrowSize / 2);
    this.#arrow.angle = 90;
    this.#arrow.x = this.#horizontalPadding * 2;
    this.#arrow.y = this.#headerHeight / 2;

    this.#line = new Sprite(Texture.WHITE);
    this.#line.width = slotState.options.calcWidth;
    this.#line.height = 2;
    this.#line.y = this.#headerHeight;
    this.#line.alpha = 0.1;
    this.#line.anchor.set(0, 1);

    this.#loader = new Loader({ color: slotState.options.colors.highlight });
    this.#loader.container.x = slotState.options.calcWidth / 2;

    this.#table = new SlotHistoryTable({
      columnPadding: this.#horizontalPadding,
      rowHeight: this.#tableRowHeight,
    });
    // Set height to header + 1 row
    this.#table.setPosition(this.#tableRowHeight * 2);
    scrollBoxItems.push(this.#table.container);

    this.#roundIdText = new BitmapText(`${slotState.options.translations.historyRound}: ${roundId}`, {
      fontName: slotState.options.fontNameNormal,
      fontSize: 38,
      color: slotState.options.colors.text,
    });
    this.#roundIdText.x = this.#horizontalPadding;
    this.#roundIdText.maxWidth = slotState.options.calcWidth - this.#horizontalPadding * 2;
    scrollBoxItems.push(this.#roundIdText);

    this.#scrollBox = new ScrollBox({
      items: scrollBoxItems,
      itemsMargin: this.#itemsMargin,
      verticalPadding: this.#itemsMargin / 2,
    });
    this.#scrollBox.container.visible = false;

    this.#container.addChild(this.#background, this.#arrow, this.#line, this.#loader.container, this.#scrollBox.container);

    this.setPosition();

    // Set actions

    const hitAreaExtraSpace = 25;
    this.#arrow.eventMode = 'static';
    this.#arrow.cursor = 'pointer';
    this.#arrow.hitArea = new Rectangle(
      -this.#arrow.width / 2 - hitAreaExtraSpace,
      -this.#arrow.height / 2 - hitAreaExtraSpace,
      this.#arrow.width + hitAreaExtraSpace * 2,
      this.#arrow.height + hitAreaExtraSpace * 2,
    );
    this.#arrow.on('pointertap', this.#close.bind(this));

    // Call request

    this.#getRound(roundId);
  }

  get container() {
    return this.#container;
  }

  #close() {
    if (this.#iframeRoundPreview) {
      this.#iframeRoundPreview.style.display = 'none';
    }

    this.#container.destroy();
    this.#onClose();

    slotState.playTapSound();
  }

  #onAccordionToggle() {
    this.#scrollBox.setDimensions({});
  }

  #formatMoneyValue(value) {
    return slotState.getMoneyLabel(value, slotState.options.currencyDisplayEnabled);
  }

  async #getRound(id) {
    const response = await api.getRound({
      id,
      tenantGameId: slotState.options.tenantGameId,
    });

    if (response.isError) {
      triggerEvent('NotificationShow', response);
      this.#loader.container.visible = false;
    } else if (!this.#container.destroyed) {
      this.#addIframeRoundPreview(id);
      this.#table.addRoundsRows([response.round]);
      this.#addGameSummary(response.round);

      if (slotState.options.config.bonus?.bonusType === 'PickPrizeLevels' && response.round.bonusPrizePicks) {
        this.#addPickPrizeLevelsSummary(response.round);
      }

      if (response.round.winLines?.length) {
        this.#addWinLines(response.round.winLines);
      }
    }
  }

  #addIframeRoundPreview(roundId) {
    const queryParams = new URLSearchParams({
      roundId,
      tenantGameId: slotState.options.tenantGameId,
    }).toString();
    const url = `${process.env.APP_ROUND_PREVIEW_URL}?${queryParams}`;
    const iframeId = 'RoundPreview';
    const oldIframe = document.getElementById(iframeId);

    if (!oldIframe) {
      this.#iframeRoundPreview = document.createElement('iframe');
      this.#iframeRoundPreview.id = iframeId;
      this.#iframeRoundPreview.frameBorder = 0;
      this.#iframeRoundPreview.scrolling = 'off';
      this.#iframeRoundPreview.style.position = 'fixed';
      this.#iframeRoundPreview.style.zIndex = 1000;
      this.#iframeRoundPreview.style.userSelect = 'none';

      document.body.append(this.#iframeRoundPreview);
    } else {
      this.#iframeRoundPreview = oldIframe;
    }

    this.#iframeRoundPreview.src = url;

    this.#iframeRoundPreview.onload = () => {
      this.#onIframeRoundPreviewLoad();
    };
  }

  #onIframeRoundPreviewLoad() {
    if (!this.#container.destroyed) {
      this.#iframeRoundPreview.style.display = 'block';
      this.setPosition();
      this.#loader.container.visible = false;
      this.#scrollBox.container.visible = true;
    }
  }

  #addGameSummary({ winAmount, collect, jackpot, bonus, totalWinAmount, winMultiplier }) {
    const items = [];

    if (winAmount > 0) {
      items.push({ columns: [
        slotState.options.translations.historyRoundBaseWinAmount,
        this.#formatMoneyValue(winAmount),
      ] });
    }

    if (collect?.winAmount > 0) {
      items.push({ columns: [
        slotState.options.translations.historyRoundCollectWinAmount,
        this.#formatMoneyValue(collect.winAmount),
      ] }, { columns: [
        slotState.options.translations.historyRoundCollectMultiplier,
        collect.multiplier,
      ] });
    }

    if (jackpot?.winAmount > 0) {
      items.push({ columns: [
        slotState.options.translations.historyRoundJackpotWinAmount,
        this.#formatMoneyValue(jackpot.winAmount),
      ] });
    }

    if (bonus?.winAmount > 0) {
      items.push({ columns: [
        slotState.options.translations.historyRoundBonusWinAmount,
        this.#formatMoneyValue(bonus.winAmount),
      ] });
    }

    if (winMultiplier > 1) {
      items.push({ columns: [
        slotState.options.translations.historyRoundFreeRoundsMultiplier,
        winMultiplier,
      ] });
    }

    if (totalWinAmount > 0) {
      items.push({ columns: [
        slotState.options.translations.historyRoundTotal,
        this.#formatMoneyValue(totalWinAmount),
      ] });
    }

    if (items.length) {
      const summaryTable = new Table({
        columnsAlign: ['left', 'right'],
        width: slotState.options.calcWidth,
        textColor: slotState.options.colors.text,
        textFontSize: 40,
        tableFontName: slotState.options.fontNameNormal,
        columnPadding: this.#horizontalPadding,
        rowHeight: this.#tableRowHeight,
        items,
      });
      summaryTable.setPosition(this.#tableRowHeight * items.length);

      const accordion = new Accordion({
        title: slotState.options.translations.historyRoundGameSummary,
        content: summaryTable.container,
        ...this.#accordionParams,
      });
      this.#scrollBox.addItem(accordion.container);
    }
  }

  #addPickPrizeLevelsSummary({ bonusPrizePicks, bonusPrizeWinAmounts }) {
    const bonusPrizeWinAmountsFormatted = bonusPrizeWinAmounts.map((amount) => this.#formatMoneyValue(amount));
    const container = new Container();
    const pickedPositions = this.#createPickPrizeBox(slotState.options.translations.historyRoundPickedPosition, bonusPrizePicks);
    const winAmounts = this.#createPickPrizeBox(slotState.options.translations.historyRoundPrizeWon, bonusPrizeWinAmountsFormatted);

    winAmounts.y = pickedPositions.height + this.#itemsMargin;

    container.addChild(pickedPositions, winAmounts);

    const accordion = new Accordion({
      title: slotState.options.translations.historyRoundPrizeAdventureBonus,
      content: container,
      ...this.#accordionParams,
    });
    this.#scrollBox.addItem(accordion.container);
  }

  #createPickPrizeBox(titleText, list) {
    const container = new Container();
    const width = slotState.options.calcWidth - this.#horizontalPadding * 2;
    const innerVerticalPadding = 15;
    const innerHorizontalPadding = 30;
    const margin = 25;
    const textMaxWidth = width - innerHorizontalPadding * 2;
    const textStyle = {
      fontName: slotState.options.fontNameNormal,
      fontSize: 40,
      color: slotState.options.colors.text,
    };

    container.x = this.#horizontalPadding;

    const title = new BitmapText(titleText, textStyle);
    title.x = innerHorizontalPadding;
    title.y = innerVerticalPadding;
    title.maxWidth = textMaxWidth;

    const listItems = new BitmapText(list.join(' | '), textStyle);
    listItems.x = innerHorizontalPadding;
    listItems.y = title.y + title.height + margin;
    listItems.maxWidth = textMaxWidth;

    const height = listItems.y + listItems.height + innerVerticalPadding;
    const border = new Graphics()
      .lineStyle(3, 0x494949)
      .drawRoundedRect(0, 0, width, height, 8);

    container.addChild(border, title, listItems);

    return container;
  }

  #addWinLines(paylines) {
    const container = new Container();
    const { rows } = slotState.options.config;
    const columnsPerRow = 2;
    const maxWidth = slotState.options.calcWidth - this.#horizontalPadding * 2;
    const columnMaxWidth = (maxWidth - (columnsPerRow - 1) * this.#horizontalPadding) / columnsPerRow;
    const rowMargin = 50;

    paylines.forEach(({ payline, paylineIndex, winLineAmount }, index) => {
      const paylineContainer = new Container();
      const boxes = new Container();

      const label = new BitmapText(paylineIndex + 1, {
        fontName: slotState.options.fontNameBold,
        fontSize: 38,
        tint: slotState.options.colors.text,
      });

      for (let row = 0; row < rows; row++) {
        payline.forEach((highlightIndex, column) => {
          const square = new Sprite(Texture.WHITE);
          const squareSize = 24;
          const margin = 8;

          square.width = squareSize;
          square.height = squareSize;
          square.x = column * squareSize + margin * column;
          square.y = row * squareSize + margin * row;

          if (highlightIndex === row) {
            square.tint = slotState.options.colors.highlightBrighter;
          } else {
            square.alpha = 0.1;
          }

          boxes.addChild(square);
        });
      }

      const amount = new BitmapText(this.#formatMoneyValue(winLineAmount), {
        fontName: slotState.options.fontNameBold,
        fontSize: 38,
        tint: slotState.options.colors.text,
      });

      paylineContainer.addChild(label, boxes, amount);

      const padding = 20;
      label.y = boxes.height / 2 - label.height / 2;
      boxes.x = label.width + padding;
      amount.x = boxes.x + boxes.width + padding;
      amount.y = label.y;

      const amountMaxWidth = columnMaxWidth - amount.x;

      if (amount.width > amountMaxWidth) {
        amount.scale.set(amountMaxWidth / amount.width);
      }

      if (index % columnsPerRow === 0) {
        paylineContainer.x = maxWidth / 4.25;
      } else if (index % columnsPerRow === 1) {
        paylineContainer.x = maxWidth / 1.33;
      }

      if (index >= columnsPerRow) {
        const containerAbove = container.getChildAt(index - columnsPerRow);
        paylineContainer.y = containerAbove.y + containerAbove.height + rowMargin;
      }

      paylineContainer.pivot.x = boxes.x + boxes.width / 2;

      container.addChild(paylineContainer);
    });

    const accordion = new Accordion({
      title: `${slotState.options.translations.historyWinLabel}   x${paylines.length}`,
      content: container,
      ...this.#accordionParams,
    });
    this.#scrollBox.addItem(accordion.container);
  }

  setPosition() {
    const size = slotState.options.size();
    const scale = slotState.container.scale.y;
    const width = size.width / scale;
    const height = size.height / scale;

    this.#background.width = width;
    this.#background.height = height;

    this.#loader.container.y = (height - this.#headerHeight) / 2;

    if (this.#iframeRoundPreview) {
      const canvas = document.getElementsByTagName('canvas')[0];
      const canvasRect = canvas.getBoundingClientRect();
      const roundPreviewPositionY = this.#headerHeight + this.#itemsMargin;
      const { reels, rows } = slotState.options.config;
      const padding = this.#horizontalPadding * scale * (reels === rows ? 2 : 1);

      this.#iframeRoundPreview.style.left = `${canvasRect.left + window.scrollX + padding}px`;
      this.#iframeRoundPreview.style.top = `${canvasRect.top + window.scrollY + roundPreviewPositionY * scale}px`;
      this.#iframeRoundPreview.style.width = `${canvasRect.width - padding * 2}px`;
      this.#iframeRoundPreview.style.height = `${this.#iframeRoundPreview.clientWidth / (reels / rows) + 3}px`; // 3px added so whole reels is shown

      const roundPreviewHeight = this.#iframeRoundPreview.clientHeight / scale;
      this.#scrollBox.container.y = roundPreviewPositionY + roundPreviewHeight;
      this.#scrollBox.setDimensions({ height: height - this.#scrollBox.container.y });
    }
  }
}
