// Modal for shared Select component
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, BitmapText, Sprite, Texture } from '@/pixi';
import { RadioList } from './RadioList';

export class SelectModal {
  #container;
  #overlay;
  #content;
  #contentBackground;
  #title;
  #radioList;
  #acceptButton;
  #cancelButton;
  #options;
  #selectedOption;
  #getAppWidth;
  #getAppHeight;
  #onClose;
  #onAccept;
  #playTapSound;
  #innerPadding;

  constructor({
    options, // Required, Array<Object>, Object example: { displayName: 'Today', value: 'today' }
    selectedOption, // Optional, value from options Object
    getAppWidth, // Required, Function
    getAppHeight, // Required, Function
    onClose, // Required, Function
    onAccept, // Required, Function
    playTapSound, // Optional, Function
    titleFontName = 'RobotoBold',
    titleText = 'Date range',
    textFontName = 'RobotoNormal',
    textColor = 0xdfe0e5,
    highlightColor = 0xffe843,
    acceptButtonText = 'Accept',
    cancelButtonText = 'Cancel',
  }) {
    this.#options = options;
    this.#selectedOption = selectedOption || options[0].value;
    this.#getAppWidth = getAppWidth;
    this.#getAppHeight = getAppHeight;
    this.#onClose = onClose;
    this.#onAccept = onAccept;
    this.#playTapSound = playTapSound;
    this.#innerPadding = 60;

    // Setup

    const titleFontSize = 52;
    const textFontSize = 42;

    this.#container = new Container();
    this.#container.name = 'SelectModal';

    this.#overlay = new Sprite(Texture.WHITE);
    this.#overlay.tint = 0x000000;
    this.#overlay.alpha = 0.35;

    this.#content = new Container();
    this.#content.eventMode = 'dynamic';

    this.#contentBackground = new Graphics();

    this.#title = new BitmapText(titleText, {
      fontName: titleFontName,
      fontSize: titleFontSize,
      tint: textColor,
    });
    this.#title.x = this.#innerPadding;
    this.#title.y = this.#innerPadding;

    this.#radioList = new RadioList({
      list: this.#options,
      selectedOption: this.#selectedOption,
      textFontName,
      textFontSize,
      textColor,
      highlightColor,
      margin: this.#innerPadding * 1.25,
      playTapSound: this.#playTapSound,
    });
    this.#radioList.container.x = this.#innerPadding;
    this.#radioList.container.y = this.#title.y + this.#title.height + this.#innerPadding * 2.5;

    this.#acceptButton = new BitmapText(acceptButtonText.toUpperCase(), {
      fontName: textFontName,
      fontSize: textFontSize,
      tint: highlightColor,
    });
    this.#acceptButton.y = this.#radioList.container.y + this.#radioList.container.height + this.#innerPadding * 2;

    this.#cancelButton = new BitmapText(cancelButtonText.toUpperCase(), {
      fontName: textFontName,
      fontSize: textFontSize,
      tint: highlightColor,
    });
    this.#cancelButton.y = this.#acceptButton.y;

    this.#content.addChild(this.#contentBackground, this.#title, this.#radioList.container, this.#acceptButton, this.#cancelButton);
    this.#container.addChild(this.#overlay, this.#content);

    this.setPosition();

    // Set actions

    this.#radioList.onChange = (value) => {
      this.#selectedOption = value;
    };

    this.#overlay.eventMode = 'static';
    this.#overlay.cursor = 'pointer';
    this.#overlay.on('pointertap', this.#close.bind(this));

    this.#acceptButton.eventMode = 'static';
    this.#acceptButton.cursor = 'pointer';
    this.#acceptButton.on('pointertap', this.#accept.bind(this));

    this.#cancelButton.eventMode = 'static';
    this.#cancelButton.cursor = 'pointer';
    this.#cancelButton.on('pointertap', this.#close.bind(this));
  }

  get container() {
    return this.#container;
  }

  #close() {
    this.#container.destroy();
    this.#onClose();

    this.#playTapSound?.();
  }

  #accept() {
    this.#onAccept(this.#selectedOption);
    this.#close();
  }

  setPosition() {
    const width = this.#getAppWidth();
    const height = this.#getAppHeight();
    const outerPadding = 30;

    this.#overlay.width = width;
    this.#overlay.height = height;

    this.#contentBackground
      .clear()
      .beginFill(0x191919)
      .drawRoundedRect(0, 0, width - outerPadding * 2, this.#content.height + this.#innerPadding * 2, 8)
      .endFill();

    this.#acceptButton.x = this.#content.width - this.#acceptButton.width - this.#innerPadding;
    this.#cancelButton.x = this.#acceptButton.x - this.#cancelButton.width - this.#innerPadding * 2;

    this.#content.x = width / 2 - this.#content.width / 2;
    this.#content.y = height / 2 - this.#content.height / 2;
  }
}
