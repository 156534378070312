import { defaultTo, isArray, isEmpty, isFunction } from 'lodash';
import chroma from 'chroma-js';
import { utils } from '@/pixi';
import { Component } from '@/components/shared/Component';
import { SlotDialog } from '@/components/SlotDialog';
import { SlotBackground } from '@/components/SlotBackground';
import { SlotContent } from '@/components/SlotContent';
import { SlotReelBackground } from '@/components/SlotReelBackground';
import { SlotReels } from '@/components/SlotReels';
import { SlotReelBonus } from '@/components/SlotReelBonus';
import { SlotSymbolDetail } from '@/components/SlotSymbolDetail';
import { SlotReelsFooter } from '@/components/SlotReelsFooter';
import { SlotReelsHeader } from '@/components/SlotReelsHeader';
import { SlotReelsOverlay } from '@/components/SlotReelsOverlay';
import { SlotReelsMultipliers } from '@/components/SlotReelsMultipliers';
import { SlotCollect } from '@/components/SlotCollect';
import { SlotControls } from '@/components/SlotControls';
import { SlotWinGrading } from '@/components/SlotWinGrading';
import { SlotDialogMenu } from '@/components/SlotDialogMenu';
import { SlotDialogBetAmount } from '@/components/SlotDialogBetAmount';
import { SlotDialogBuy } from '@/components/SlotDialogBuy';
import { SlotOnboardScreen } from '@/components/SlotOnboardScreen';
import { SlotDialogAutoplay } from '@/components/SlotDialogAutoplay';
import { SlotNotification } from '@/components/SlotNotification';
import { SlotDialogWarning } from '@/components/SlotDialogWarning';
import { SlotParser } from '@/utility/SlotParser';
import { SlotParticleType, SlotWinlineType, SlotSpinSpeedType } from '@/models';
import { slotState } from '@/components/SlotState';
import { audio } from '@/components/SlotAudio';
import { noSleep } from '@/plugins';
import { registerEventListener, triggerEvent } from '../utility/Utility';

export class Slot extends Component {
  constructor(params) {
    const { ui, tenantGameId, settings, name } = params.state.game;
    const { player } = params.state;
    const uiPadding = defaultTo(ui.uiPadding, 24);
    const uiDialogPadding = defaultTo(ui.uiDialogPadding, uiPadding * 2);
    const reelPaddingEqual = defaultTo(ui.reelPadding, 12);
    const reelPadding = isArray(ui.reelPadding) ? ui.reelPadding : [reelPaddingEqual, reelPaddingEqual];
    const colorHighlight = defaultTo(ui.colorHighlight, 0xffe843);
    const colorHighlightBrighter = chroma(colorHighlight).brighten().hex();

    const { options } = new SlotParser(params, {
      name,
      autoReveal: true,
      calcWidth: 1080,
      currency: player?.currency || params.tenantCurrency || 'EUR',
      currencyDisplayEnabled: defaultTo(settings.currencyDisplayEnabled, false),
      disabledSymbolsPositions: ui.disabledSymbolsPositions,
      lobbyEnabled: defaultTo(settings.lobbyEnabled, false),
      autoPlayEnabled: defaultTo(settings.autoPlayEnabled, true),
      expandedSymbols: defaultTo(ui.expandedSymbols, []),
      defaultFontFamily: 'Roboto',
      fontNameLight: 'RobotoLight',
      fontNameNormal: 'RobotoNormal',
      fontNameSemiBold: 'RobotoSemiBold',
      fontNameBold: 'RobotoBold',
      isDemo: true,
      isPhone: utils.isMobile.phone,
      isTablet: utils.isMobile.tablet,
      logoOffset: defaultTo(ui.logoOffset, 0),
      mute: false,
      reelPadding,
      smallDeviceHeightLimit: 600,
      spinSpeedType: SlotSpinSpeedType.Normal,
      tenantGameId,
      colors: {
        text: defaultTo(ui.colorText, 0xdfe0e5),
        textDisabled: defaultTo(ui.colorTextDisabled, 0x7d7d7d),
        highlight: colorHighlight,
        highlightBrighter: colorHighlightBrighter,
        highlightText: defaultTo(ui.colorHighlightText, 0x000000),
      },
      translations: {
        bonusGameIntroOutroTopLabel: 'You won',
        bonusGamePressAnywhereLabel: 'Press anywhere to continue',
        demoLabel: 'Demo',
        demoStoreTooltip: 'Store is not available in demo mode.',
        dialogAutoplayLossLabel: 'Loss limit',
        dialogAutoplayStartButton: 'Start',
        dialogAutoplayStopLabel: 'Stop on any win',
        dialogAutoplayTitle: 'Autoplay',
        dialogAutoplayWinLabel: 'Win limit',
        dialogBetAmountTitle: 'Bet Amount',
        dialogMenuHistoryTitle: 'History',
        dialogMenuInfoTitle: 'Rules',
        dialogMenuPaytableTitle: 'Paytable',
        dialogSettingsLightningLabel: 'Lightning spin',
        dialogSettingsSoundLabel: 'Sound',
        dialogSettingsTitle: 'Settings',
        dialogSettingsTurboLabel: 'Turbo spin',
        dialogStoreTitle: 'Store',
        freeSpinsIntroBottomLabel: 'Free spins',
        historyBetLabel: 'Bet',
        historyCurrencyLabel: 'Currency',
        historyDemoModeMessage: 'Tickets history not available in Demo mode.',
        historyNoTicketsMessage: 'There is no tickets for selected filter. Try different filters.',
        historyRound: 'Round',
        historyRoundBaseWinAmount: 'Base win amount',
        historyRoundBonusWinAmount: 'Bonus win amount',
        historyRoundCollectMultiplier: 'Collect multiplier',
        historyRoundCollectWinAmount: 'Collect win amount',
        historyRoundFreeRoundsMultiplier: 'Free rounds multiplier',
        historyRoundGameSummary: 'Game summary',
        historyRoundJackpotWinAmount: 'Jackpot win amount',
        historyRoundPickedPosition: 'Picked position',
        historyRoundPrizeAdventureBonus: 'Prize adventure bonus',
        historyRoundPrizeWon: 'Prize won',
        historyRoundTotal: 'Total',
        historySelectModalAccept: 'Accept',
        historySelectModalCancel: 'Cancel',
        historySelectModalDateRange: 'Date range',
        historySelectToday: 'Today',
        historySelectYesterday: 'Yesterday',
        historySummaryLabel: 'Summary',
        historyTimeLabel: 'Time',
        historyWinLabel: 'Win',
        holdForLightningLabel: 'Hold for lightning mode',
        jackpotLabel: 'Jackpot',
        jackpotCollect: 'Collect',
        jackpotCongratulation: 'Congratulations',
        jackpotWon: 'You have won the jackpot.',
        onboardScreenLabel: 'Click anywhere to start',
        paytableBonusFreeRoundsDynamicPrizeCount: 'Three or more symbols anywhere on the reels activate the free spins bonus game. Symbol distribution in free rounds, as the number of free rounds, depends on the bonus symbols that started the bonus game.', // eslint-disable-line max-len
        paytableBonusFreeRoundsMultiplier: 'Three or more symbols anywhere on the reels activate ${prizeCount} free rounds. The multiplier applies to all winnings from free spins bonus game.',
        paytableBonusFreeRoundsStaticPrizeCount: 'Three or more symbols anywhere on the reels activate ${prizeCount} free rounds. Symbol distribution in free rounds depends on the bonus symbols that started the bonus game.',
        paytableBonusPickPrize: '3 or more symbols anywhere on the reels activate Pick Prize bonus game. Select one item to reveal prizes. The higher the number of bonus symbols, the higher the prizes in the bonus game.',
        paytableBonusPickPrizeLevels: '3 or more symbols anywhere on the reels activate the Prize Adventure bonus game. The game consists of ${numberOfLevels} prize levels. Choose wisely, as each level offers both a prize and a trap. The higher the number of bonus symbols, the higher the prizes in the bonus game.', // eslint-disable-line max-len
        paytableBonusSymbolTitle: 'Bonus Symbol',
        paytableCashSymbolDescription: 'Cash symbol provides a bet multiplier worth ${minDistributionValue}x - ${maxDistributionValue}x your stake.',
        paytableCashSymbolTitle: 'Cash Symbol',
        paytableCollectSymbolDescription: 'Collects all cash values on the screen.',
        paytableCollectSymbolTitle: 'Collect Symbol',
        paytableDynamicMultiplierSymbolDescription: 'Collect multipliers and boost your winnings! Land at least three bonus symbols and trigger free spins where every spin could land you a multiplier.',
        paytableFreeSpinsText: 'Free spins',
        paytableMultiplierSymbolDescription: 'Collect symbols during normal spins and boost your winnings! The collected multiplier amount multiplies each cash symbol and each winline win, once the collect feature is triggered.',
        payTableMultiplierSymbolTitle: 'Multiplier Symbol',
        paytableMultiplierText: 'Multiplier',
        paytablePaylinesTitle: 'Winning bet lines',
        paytableProgressDynamicMultiplierSymbolDescription: 'Collect multipliers and boost your winnings! Fill the progress bar and trigger free spins where every spin could land you a multiplier.',
        paytableProgressFreeRounds: 'The symbol increases your progress level. Once you fill the progress bar ${prizeCount} free rounds trigger.  Symbol distribution in free rounds differs from the one in the base game.',
        paytableProgressPickPrize: 'The symbol increases your progress level. Once you fill the progress bar, Pick Prize bonus game triggers. Select one item to reveal prizes. The higher the number of bonus symbols, the higher the prizes in the bonus game.', // eslint-disable-line max-len
        paytableProgressPickPrizeLevels: 'The symbol increases your progress level. Once you fill the progress bar, the Prize Adventure bonus game triggers. The game consists of ${prizeCount} prize levels. Choose wisely, as each level offers both a prize and a trap. The higher the number of bonus symbols, the higher the prizes in the bonus game.', // eslint-disable-line max-len
        paytableProgressSymbolTitle: 'Progress Symbol',
        paytableTrashSymbolBaseGameDescription: 'No paying symbol. It can only land on reels in base game.',
        paytableTrashSymbolDescription: 'No paying symbol. It can land on reels in free spins.',
        paytableTrashSymbolTitle: 'Trash Symbol',
        paytableWildMultiplierSymbolDescription: 'Wild symbol replace any symbol except Bonus. Multiplier can contain values in between ${minValue} and ${maxValue}.',
        paytableWildSymbolDescription: 'Wild symbol replace any symbol except Bonus.',
        paytableWildSymbolTitle: 'Wild Symbol',
        promotionYouWon: 'You won',
        promotionContinue: 'Continue',
        promotionClose: 'Close',
        promotionDays: 'days',
        promotionEnded: 'Promotion ended',
        promotionEndsIn: 'Ends in',
        promotionFreeSpins: 'Free spins',
        promotionHours: 'hours',
        promotionMinutes: 'minutes',
        promotionSeconds: 'seconds',
        promotionOperatorEnded: 'Operator has ended the promotion',
        promotionPlayNow: 'Play now',
        promotionYouReceived: 'You received',
        promotionClaim: 'Claim',
        reload: 'Reload',
        reloadGameToPlay: 'Reload game to play',
        resetWarningCancelLabel: 'Cancel',
        resetWarningContinueLabel: 'Continue',
        resetWarningMessageBetAmount: 'If you increase your bet, the multiplier/progress level resets to zero.',
        resetWarningMessageBoldPart: 'resets to zero.',
        resetWarningMessageBoldPartCollect: 'resets to one.',
        resetWarningMessageCollectBetAmount: 'If you increase your bet, the multiplier/ resets to one.',
        resetWarningMessageCollectFeatureBuy: 'If you buy the feature, the multiplier/ resets to one.',
        resetWarningMessageFeatureBuy: 'If you buy the feature, the multiplier/progress level resets to zero.',
        resetWarningMessageProgressBetAmount: 'If you increase your bet, progress level/ resets to zero.',
        resetWarningMessageProgressFeatureBuy: 'If you buy the feature, progress level/ resets to zero.',
        resetWarningTitle: 'Warning!',
        /* errors translations */
        actionError: 'Action error',
        actionSuccess: 'Action success',
        activePromotionError: 'Promotion is currently active',
        closeGame: 'Game can not be continued. Please close game.',
        dbReadError: 'Database error',
        dbSaveError: 'Database error',
        expiredPlayerTokenError: 'Expired player token',
        featureNotAvailableError: 'Feature not available',
        fieldNotFoundError: 'Round validation error',
        gameConfigNotFoundError: 'Invalid game configuration',
        gameMultipeInstancesError: 'Invalid parameters',
        gameNotFoundError: 'Game not found',
        insufficientFundsError: 'Insufficient funds',
        integrationRequestError: 'Integration error',
        integrationRequestServiceError: 'Integration error',
        integrationRequestTimeoutError: 'Integration error',
        integrationRequestUrlError: 'Integration error',
        integrationResponseError: 'Integration error',
        invalidDateRANGE: 'Invalid date range',
        invalidGambleGAME: 'Invalid gamble parameters',
        invalidGameError: 'Invalid game parameters',
        invalidInputError: 'Invalid input',
        invalidJackpotQueue: 'Jackpot error',
        invalidParamError: 'Invalid parameters',
        invalidPlayerError: 'Invalid player',
        invalidPlayerTokenError: 'Invalid player token',
        invalidRoundError: 'Invalid round',
        invalidSignatureError: 'Invalid signature',
        invalidStateError: 'An error happened',
        invalidTransactionCurrencyError: 'Transaction error',
        invalidTransactionError: 'Transaction error',
        invalidTransactionTypeError: 'Transaction error',
        jackpotGamesNotFoundError: 'Jackpot error',
        jackpotNotFoundError: 'Jackpot error',
        notImplementedError: 'Feature not implemented',
        openBonusError: 'Bonus game is currently open',
        openGambleError: 'Gamble game is currently open',
        partnerDeactivatedError: 'Game is deactivated',
        partnerSetupError: 'Invalid setup',
        platformUnavailableError: 'Platform unavailable',
        roundNotFoundError: 'Round not found',
        roundStartGambleError: 'Gamble error',
        tenantCurrencyUnsupportedError: 'Unsupported currency',
        tenantDeactivatedError: 'Game or client is deactivated',
        tenantGamesNotFoundError: 'Invalid parameters',
        tenantMismatchError: 'Invalid parameters',
        tenantNotFoundError: 'Invalid parameters',
        transactionErrorError: 'Transaction error',
        transactionInProgressError: 'Existing transaction is in progress',
        transactionNotFoundError: 'Transaction not found',
      },
      uiDialogPadding,
      uiPadding,
      winlineColors: defaultTo(ui.winlineColors, ['#ebeb17', '#e771f7']),
      winlineParticleType: defaultTo(ui.winlineParticleType, SlotParticleType.Comet),
      winlineType: defaultTo(ui.winlineType, SlotWinlineType.SimpleWithSymbols),
      freeSpinsIntroOptions: defaultTo(ui.freeSpinsIntroOptions, { textStyle: {}, textTopStyle: {}, textBottomStyle: {} }),
      freeSpinsOutroOptions: defaultTo(ui.freeSpinsOutroOptions, { textStyle: {} }),
      pickPrizeOutroOptions: defaultTo(ui.pickPrizeOutroOptions, { textStyle: {} }),
      locale: 'en',
    });

    super('slot', options);

    noSleep.init();

    audio.setOptions({
      mute: options.mute,
    });

    slotState.create(options);
    slotState.container = this.container;
    slotState.background = new SlotBackground();
    slotState.content = new SlotContent();
    slotState.reelsHeader = new SlotReelsHeader();
    slotState.reelBackground = new SlotReelBackground();
    slotState.reelBonus = new SlotReelBonus();
    slotState.reels = new SlotReels();
    slotState.symbolDetail = new SlotSymbolDetail();
    slotState.reelsFooter = new SlotReelsFooter();
    slotState.reelsOverlay = new SlotReelsOverlay();
    slotState.reelsMultipliers = new SlotReelsMultipliers();

    if (options.config.collect) {
      slotState.reelsCollect = new SlotCollect();
    }

    slotState.content.container.addChild(slotState.reelBackground.container);
    slotState.content.container.addChild(slotState.reelsFooter.container);
    slotState.content.container.addChild(slotState.reels.container);
    slotState.content.container.addChild(slotState.reelBonus.container);
    slotState.content.container.addChild(slotState.symbolDetail.container);
    slotState.content.container.addChild(slotState.reelsHeader.container);
    slotState.content.container.addChild(slotState.reelsOverlay.container);
    slotState.content.container.addChild(slotState.reelsMultipliers.container);

    this.container.addChild(slotState.background.container);
    this.container.addChild(slotState.content.container);

    slotState.controls = new SlotControls();

    this.container.addChild(slotState.controls.container);

    if (options.config.isWinGradingSupported) {
      slotState.winGrading = new SlotWinGrading();
      this.container.addChild(slotState.winGrading.container);
    }

    slotState.dialogBonusBuy = new SlotDialog({
      title: slotState.options.translations.dialogStoreTitle,
      content: new SlotDialogBuy(),
      backgroundImageAsset: this.options.assets.bonusBuyBackground,
    });

    this.container.addChild(slotState.dialogBonusBuy.container);

    slotState.dialogBetAmount = new SlotDialogBetAmount();

    this.container.addChild(slotState.dialogBetAmount.container);

    slotState.dialogAutoplay = new SlotDialogAutoplay();

    this.container.addChild(slotState.dialogAutoplay.container);

    const dialogMenuContent = new SlotDialogMenu();

    slotState.dialogMenu = new SlotDialog({
      title: slotState.options.translations.dialogSettingsTitle,
      content: dialogMenuContent,
      onBeforeClose: () => {
        dialogMenuContent.hide();
      },
    });

    this.container.addChild(slotState.dialogMenu.container);

    slotState.dialogWarning = new SlotDialog({
      content: new SlotDialogWarning(),
      isWarning: true,
      isTransparent: true,
      isClosable: false,
    });

    this.container.addChild(slotState.dialogWarning.container);

    if (this.options.assets.uiOnboardSound && this.options.assets.onboardIntro) {
      slotState.onboardScreen = new SlotOnboardScreen();
      this.container.addChild(slotState.onboardScreen.container);
    } else {
      triggerEvent('ShowBoostBadge');
    }

    slotState.setSoundAmbient();

    this.state = slotState;
    this.audio = audio;
    this.spin = slotState.reels.spin.bind(slotState.reels);
    this.stop = slotState.reels.stop.bind(slotState.reels);
    this.reveal = slotState.reels.reveal.bind(slotState.reels);

    this.setListeners();
    this.resize();
  }

  onAfterResize() {
    slotState.background.setPosition();
    slotState.content.setPosition();
    slotState.controls.setPosition();

    if (slotState.winGrading) {
      slotState.winGrading.setPosition();
    }

    if (slotState.dialogMenu) {
      slotState.dialogMenu.setPosition();
    }

    slotState.dialogBetAmount.setPosition();
    slotState.dialogBonusBuy.setPosition();
    slotState.dialogAutoplay.setPosition();

    if (isFunction(slotState.reels.setMask)) {
      slotState.reels.setMask.bind(slotState.reels)();
    }

    if (slotState.onboardScreen) {
      slotState.onboardScreen.setPosition();
    }

    if (slotState.dialog) {
      slotState.dialog.setPosition();
    }

    if (!isEmpty(slotState.progress) || !isEmpty(slotState.collect)) {
      slotState.dialogWarning.setPosition();
    }
    slotState.notification?.setPosition();
  }

  setListeners() {
    const source = 'Slot';

    registerEventListener('MuteChanged', (event) => {
      const { isSoundOff } = event.detail;

      if (!isSoundOff) {
        audio.pause(slotState.soundAmbientAsset, slotState.soundAmbient);
        audio.play(slotState.soundAmbientAsset, { loop: true }, slotState.soundAmbient);
      }
    }, source);

    registerEventListener('CloseDialog', () => {
      if (slotState.isWarningDialogOpen) {
        slotState.dialogWarning.hide();
      }

      if (slotState.activeDialog) {
        slotState.activeDialog.hide();
      }
    });

    registerEventListener('ShowNotification', (event) => {
      slotState.setErrorDetails(event.detail);
    });

    registerEventListener('SetNotificationDetails', ({ detail }) => {
      slotState.notification = new SlotNotification(detail);
      this.container.addChild(slotState.notification.container);
    });

    registerEventListener('ResetNotificationDetails', () => {
      slotState.errorDetails = undefined;
    });
  }
}
