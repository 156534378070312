import { triggerEvent } from '@/utility/Utility';
import { Dialog } from './shared/Dialog';
import { slotState } from './SlotState';

export class SlotDialog extends Dialog {
  constructor(params) {
    super({
      labelFontColor: slotState.options.colors.text,
      fontName: slotState.options.fontNameNormal,
      iconCloseAsset: slotState.options.assets.iconClose,
      padding: slotState.options.uiDialogPadding,
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      playTapSound: () => { slotState.playTapSound(); },
      ...params,
      onShow: () => {
        if (params.isWarning) {
          slotState.isWarningDialogOpen = this;
        } else {
          slotState.activeDialog = this;
        }

        triggerEvent('DialogChanged', {
          isOpened: true,
          isFullscreen: this.isFullscreen,
        });

        params.onShow?.();
      },
      onClose: () => {
        if (!slotState.activeDialog) {
          triggerEvent('DialogChanged', {
            isOpened: false,
            isFullscreen: !this.isFluid && !this.isCenter,
          });
        }

        triggerEvent('NotificationShow', undefined);

        params.onClose?.();
      },
      onBeforeClose: () => {
        if (params.isWarning) {
          slotState.isWarningDialogOpen = undefined;
        } else {
          slotState.activeDialog = undefined;
        }

        params.onBeforeClose?.();
      },
    });
  }
}
