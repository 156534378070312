import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Rectangle, BitmapText } from '@/pixi';

export class Accordion {
  #container;
  #header;
  #arrow;
  #label;
  #content;
  #color;
  #highlightColor;
  #playTapSound;
  #onToggle;

  constructor({
    content, // Required
    title, // Required
    headerHorizontalPadding = 0,
    headerMaxWidth = 1080,
    color = 0xdfe0e5,
    highlightColor = 0xffeb3b,
    fontName = 'RobotoNormal',
    onToggle, // Optional, Function
    isOpened = false,
    playTapSound, // Optional, Function
  }) {
    this.#content = content;
    this.#color = color;
    this.#highlightColor = highlightColor;
    this.#onToggle = onToggle;
    this.#playTapSound = playTapSound;

    // Setup

    const arrowSize = 24;
    const margin = 45;

    this.#container = new Container();
    this.#container.name = 'Accordion';

    this.#arrow = new Graphics()
      .lineStyle(8, 0xffffff)
      .moveTo(-arrowSize, -arrowSize / 2)
      .lineTo(0, arrowSize / 2)
      .lineTo(arrowSize, -arrowSize / 2);
    this.#arrow.angle = 270;
    this.#arrow.x = headerHorizontalPadding;
    this.#arrow.tint = highlightColor;

    this.#label = new BitmapText(title, {
      fontName,
      fontSize: 58,
      color,
    });
    this.#label.anchor.set(0, 0.5);
    this.#label.x = this.#arrow.x + this.#arrow.width / 2 + margin;
    this.#label.maxWidth = headerMaxWidth - this.#label.x - headerHorizontalPadding;

    this.#header = new Container();
    this.#header.addChild(this.#arrow, this.#label);

    this.#content.visible = false;
    this.#content.x = -this.#arrow.width / 2;
    this.#content.y = this.#label.height / 2 + margin;

    this.container.pivot.x = -this.#arrow.width / 2;
    this.container.pivot.y = -this.#label.height / 2;

    this.#container.addChild(this.#header, this.#content);

    // Set actions

    const hitAreaExtraSpace = 12;
    const bounds = this.#header.getLocalBounds();
    this.#header.eventMode = 'static';
    this.#header.cursor = 'pointer';
    this.#header.hitArea = new Rectangle(bounds.x - hitAreaExtraSpace, bounds.y, bounds.width + hitAreaExtraSpace * 2, bounds.height + hitAreaExtraSpace);
    this.#header.on('pointertap', () => {
      this.#toggleShow();

      this.#playTapSound?.();
    });

    if (isOpened) {
      this.#toggleShow();
    }
  }

  #toggleShow() {
    this.#content.visible = !this.#content.visible;
    this.#arrow.angle = this.#arrow.angle === 0 ? 270 : 0;
    this.#arrow.tint = this.#arrow.tint === this.#highlightColor ? this.#color : this.#highlightColor;
    this.#onToggle?.();
  }

  get container() {
    return this.#container;
  }

  toggle() {
    this.#toggleShow();
  }
}
