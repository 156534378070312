import { Button } from './shared/Button';
import { slotState } from './SlotState';

export class SlotButton extends Button {
  constructor(params) {
    super({
      ...SlotButton.getDefaultParams(),
      ...params,
    });
  }

  static getDefaultParams = () => ({
    backgroundColor: slotState.options.colors.highlightBrighter,
    textColor: slotState.options.colors.highlightText,
    fontName: slotState.options.fontNameNormal,
    playTapSound: () => { slotState.playTapSound(); },
  });
}
