import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, BitmapText, Rectangle } from '@/pixi';

export class RadioList {
  #container;
  #selectedOption;
  #playTapSound;
  #onChangeCallback;
  #radioElementNames;

  constructor({
    list, // Required, Array<Object>, Object example: { displayName: 'Today', value: 'today' }
    selectedOption, // Optional, value from list Object
    margin = 75,
    textFontName = 'RobotoNormal',
    textFontSize = 42,
    textColor = 0xdfe0e5,
    highlightColor = 0xffe843,
    radioSize = 30,
    playTapSound, // Optional, Function
  }) {
    this.#selectedOption = selectedOption || list[0].value;
    this.#playTapSound = playTapSound;
    this.#radioElementNames = {
      radio: 'radio',
      outerCircle: 'outerCircle',
      innerCircle: 'innerCircle',
    };

    // Setup

    const radioMargin = 25;

    this.#container = new Container();
    this.#container.name = 'RadioList';

    list.forEach((option, index) => {
      const optionContainer = new Container();

      const radio = this.#createRadio(radioSize, option.value === this.#selectedOption, textColor, highlightColor);

      const optionText = new BitmapText(option.displayName, {
        fontName: textFontName,
        fontSize: textFontSize,
        tint: textColor,
      });
      optionText.x = radio.width + radioMargin;
      optionText.anchor.set(0, 0.5);

      if (this.#container.children.length) {
        const previousChild = this.#container.children[index - 1];
        optionContainer.y = previousChild.y + previousChild.height + margin;
      }

      optionContainer.addChild(radio, optionText);

      optionContainer.eventMode = 'static';
      optionContainer.cursor = 'pointer';
      optionContainer.hitArea = new Rectangle(0, -optionContainer.height / 2, optionContainer.width, optionContainer.height);
      optionContainer.on('pointertap', () => {
        if (this.#selectedOption !== option.value) {
          this.#changeOption(option.value, optionContainer, textColor, highlightColor);

          this.#playTapSound?.();
        }
      });

      this.#container.addChild(optionContainer);
    });
  }

  get container() {
    return this.#container;
  }

  set onChange(callback) {
    this.#onChangeCallback = callback;
  }

  #changeOption(value, optionContainer, textColor, highlightColor) {
    this.#selectedOption = value;
    this.#onChangeCallback?.(value);

    // Reset all radios
    this.#container.children.forEach((child) => {
      const childRadio = child.getChildByName(this.#radioElementNames.radio);
      childRadio.getChildByName(this.#radioElementNames.outerCircle).tint = textColor; // eslint-disable-line no-param-reassign
      childRadio.getChildByName(this.#radioElementNames.innerCircle).visible = false; // eslint-disable-line no-param-reassign
    });

    const selectedRadio = optionContainer.getChildByName(this.#radioElementNames.radio);
    selectedRadio.getChildByName(this.#radioElementNames.outerCircle).tint = highlightColor;
    selectedRadio.getChildByName(this.#radioElementNames.innerCircle).visible = true;
  }

  #createRadio(radioSize, isSelected, textColor, highlightColor) {
    const container = new Container();
    container.name = this.#radioElementNames.radio;

    const lineStyleWidth = 2;
    const lineStyleAlignment = 0.5;
    const size = radioSize - lineStyleWidth * lineStyleAlignment;

    const outerCircle = new Graphics()
      .beginFill(0xffffff)
      .lineStyle(lineStyleWidth, 0xffffff, 1, lineStyleAlignment)
      .drawCircle(size, 0, size)
      .beginHole()
      .drawCircle(size, 0, size * 0.85)
      .endHole()
      .endFill();
    outerCircle.name = this.#radioElementNames.outerCircle;
    outerCircle.tint = isSelected ? highlightColor : textColor;

    const innerCircle = new Graphics()
      .beginFill(highlightColor)
      .lineStyle(lineStyleWidth, highlightColor, 1, lineStyleAlignment)
      .drawCircle(0, 0, size / 2)
      .endFill();
    innerCircle.name = this.#radioElementNames.innerCircle;
    innerCircle.x = outerCircle.width / 2 - lineStyleWidth * lineStyleAlignment;
    innerCircle.visible = isSelected;

    container.addChild(outerCircle, innerCircle);

    return container;
  }
}
