import { triggerEvent } from '@/utility/Utility';
import { DialogContentAutoplay } from '@/components/shared/DialogContentAutoplay';
import { SlotDialog } from './SlotDialog';
import { SlotButton } from './SlotButton';
import { SlotCheckbox } from './SlotCheckbox';
import { slotState } from './SlotState';

export class SlotDialogAutoplay extends SlotDialog {
  constructor() {
    const content = new DialogContentAutoplay({
      padding: slotState.options.uiDialogPadding,
      fontName: slotState.options.fontNameNormal,
      color: slotState.options.colors.text,
      highlightColor: slotState.options.colors.highlightBrighter,
      stopLabelText: slotState.options.translations.dialogAutoplayStopLabel,
      lossLimitLabelText: slotState.options.translations.dialogAutoplayLossLabel,
      winLimitLabelText: slotState.options.translations.dialogAutoplayWinLabel,
      startButtonText: slotState.options.translations.dialogAutoplayStartButton,
      formatValue: (value) => slotState.getMoneyLabel(value, slotState.options.currencyDisplayEnabled),
      getBetAmount: () => slotState.betAmount,
      onStart: (selectedSpinValue, stopOnAnyWin, lossLimit, winLimit) => {
        slotState.setAutoplaySettings(stopOnAnyWin, lossLimit, winLimit);
        triggerEvent('ToggleAutoplay', {
          count: selectedSpinValue,
        });
      },
      buttonParams: SlotButton.getDefaultParams(),
      checkboxParams: SlotCheckbox.getDefaultParams(),
    });

    super({
      title: slotState.options.translations.dialogAutoplayTitle,
      content,
      isFluid: true,
    });
  }
}
