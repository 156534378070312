import { Container, Sprite } from '@/pixi';

export class Checkbox {
  constructor({
    onChange,
    isActive = false,
    isEnabled = true,
    iconOffAsset,
    iconOnAsset,
    colorActive = 0xffe843,
    playTapSound,
  }) {
    this.onChange = onChange;
    this.isActive = isActive;
    this.isEnabled = isEnabled;
    this.iconOffAsset = iconOffAsset;
    this.iconOnAsset = iconOnAsset;
    this.colorActive = colorActive;
    this.playTapSound = playTapSound;
    this.container = new Container();

    this.setup();
  }

  setup() {
    this.iconOff = new Sprite(this.iconOffAsset.resource);
    this.iconOff.anchor.set(0);
    this.iconOff.alpha = this.isActive ? 0 : 1;

    this.iconOn = new Sprite(this.iconOnAsset.resource);
    this.iconOn.tint = this.colorActive;
    this.iconOn.anchor.set(0);
    this.iconOn.alpha = this.isActive ? 1 : 0;

    if (this.isEnabled) {
      this.container.eventMode = 'static';
      this.container.cursor = 'pointer';
      this.container.on('pointertap', () => {
        this.toggle();
        this.playTapSound?.();
      });
    }

    this.container.addChild(this.iconOff);
    this.container.addChild(this.iconOn);
  }

  set(value) {
    if (value) {
      this.iconOff.alpha = 0;
      this.iconOn.alpha = 1;
      this.isActive = true;
    } else {
      this.isActive = false;
      this.iconOff.alpha = 1;
      this.iconOn.alpha = 0;
    }
  }

  toggle() {
    if (this.isActive) {
      this.isActive = false;
      this.iconOff.alpha = 1;
      this.iconOn.alpha = 0;
    } else {
      this.iconOff.alpha = 0;
      this.iconOn.alpha = 1;
      this.isActive = true;
    }

    if (this.onChange) {
      this.onChange();
    }
  }
}
