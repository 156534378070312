import { SlotWinlineType } from '../models';
import { SlotParticlesWinlines } from './SlotParticlesWinlines';
import { SlotSimpleWinlines } from './SlotSimpleWinlines';
import { slotState } from './SlotState';

export function SlotWinlines(container, reelsContainer, position) {
  const isWithSymbol = [
    SlotWinlineType.ParticlesWithSymbols,
    SlotWinlineType.SimpleWithSymbols,
    SlotWinlineType.Symbols,
  ].indexOf(slotState.options.winlineType) > -1;

  const params = [
    container,
    reelsContainer,
    position,
    isWithSymbol,
  ];

  switch (slotState.options.winlineType) {
    case SlotWinlineType.Simple:
    case SlotWinlineType.SimpleWithSymbols:
      return new SlotSimpleWinlines(...params);
    case SlotWinlineType.Particles:
    case SlotWinlineType.ParticlesWithSymbols:
    default:
      return new SlotParticlesWinlines(...params);
  }
}
