import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, BitmapText, Rectangle } from '@/pixi';
import { SelectModal } from './SelectModal';

export class Select {
  #container;
  #border;
  #text;
  #arrow;
  #modal;
  #options;
  #selectedOption;
  #textFontName;
  #playTapSound;
  #rootContainer;
  #modalParams;
  #onChangeCallback;

  constructor({
    options, // Required, Array<Object>, Object example: { displayName: 'Today', value: 'today' }
    selectedOption, // Optional, value from options Object
    textFontName = 'RobotoNormal',
    textColor = 0x878787,
    width, // Required, Number
    height = 100,
    borderColor = 0x494949,
    playTapSound, // Optional, Function
    rootContainer, // Required, container to add SelectModal to
    modalParams, // Required
  }) {
    this.#options = options;
    this.#selectedOption = selectedOption;
    this.#textFontName = textFontName;
    this.#playTapSound = playTapSound;
    this.#rootContainer = rootContainer;
    this.#modalParams = modalParams;

    // Setup

    const padding = 50;

    this.#container = new Container();
    this.#container.name = 'Select';

    this.#border = new Graphics()
      .lineStyle(3, borderColor)
      .drawRoundedRect(0, 0, width, height, 8);

    this.#text = new BitmapText(options.find((o) => o.value === selectedOption)?.displayName, {
      fontName: this.#textFontName,
      fontSize: 38,
      tint: textColor,
    });
    this.#text.anchor.set(0, 0.5);
    this.#text.x = padding;
    this.#text.y = height / 2;

    this.#arrow = new Graphics()
      .lineStyle(6, textColor)
      .moveTo(-16, -8)
      .lineTo(0, 8)
      .lineTo(16, -8);
    this.#arrow.x = width - this.#arrow.width / 2 - padding;
    this.#arrow.y = height / 2;

    this.#container.addChild(this.#border, this.#text, this.#arrow);

    // Set actions

    this.#container.eventMode = 'static';
    this.#container.cursor = 'pointer';
    this.#container.hitArea = new Rectangle(0, 0, this.#container.width, this.#container.height);
    this.#container.on('pointertap', this.#openModal.bind(this));
  }

  get container() {
    return this.#container;
  }

  set selectedOption(option) {
    this.#selectedOption = option;
    this.#text.text = this.#options.find((o) => o.value === this.#selectedOption).displayName;
  }

  set onChange(callback) {
    this.#onChangeCallback = callback;
  }

  #openModal() {
    this.#modal = new SelectModal({
      ...this.#modalParams,
      textFontName: this.#textFontName,
      options: this.#options,
      selectedOption: this.#selectedOption,
      playTapSound: this.#playTapSound,
      onClose: this.#onModalClose.bind(this),
      onAccept: this.#selectOption.bind(this),
    });

    this.#rootContainer.addChild(this.#modal.container);

    this.#arrow.angle = 180;

    this.#playTapSound?.();
  }

  #onModalClose() {
    this.#modal = undefined;

    this.#arrow.angle = 0;
  }

  #selectOption(option) {
    if (this.#selectedOption !== option) {
      this.selectedOption = option;
      this.#onChangeCallback?.(option);
    }
  }

  disable() {
    this.#container.eventMode = 'none';
  }

  enable() {
    this.#container.eventMode = 'static';
  }

  setPosition() {
    this.#modal?.setPosition();
  }
}
