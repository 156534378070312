import { isArray } from 'lodash';
import { Container, AnimatedSprite, Sprite, BitmapText, Rectangle } from '@/pixi';
import { triggerAnalyticsEvent } from '@/utility/Utility';

export class ControlsFooter {
  constructor({
    betAmountLabel, // Required, String
    balanceLabel, // Required, String
    onBetAmountClick, // Required, Function
    isBetAmountClickDisabled = false,
    playTapSound, // Optional, Function
    betIconAsset, // Required
    balanceIconAsset, // Required
    appWidth = 1080,
    getAppHeight, // Required, Function, should return Number
    padding = 24,
    fontName = 'RobotoBold',
  }) {
    this.betAmountLabel = betAmountLabel;
    this.balanceLabel = balanceLabel;
    this.onBetAmountClick = onBetAmountClick;
    this.isBetAmountClickDisabled = isBetAmountClickDisabled;
    this.playTapSound = playTapSound;
    this.betIconAsset = betIconAsset;
    this.balanceIconAsset = balanceIconAsset;
    this.appWidth = appWidth;
    this.getAppHeight = getAppHeight;
    this.padding = padding;
    this.fontName = fontName;

    this.container = new Container();
    this.uiBetContainer = undefined;
    this.uiBetIcon = undefined;
    this.uiBetAmount = undefined;
    this.uiBalanceIcon = undefined;
    this.uiBalanceAmount = undefined;

    this.setup();
    this.setActions();
  }

  setup() {
    this.uiBetContainer = new Container();
    this.uiBetContainer.x = this.appWidth - (this.padding * 2);
    this.container.addChild(this.uiBetContainer);

    this.uiBetIcon = isArray(this.betIconAsset.resource) ? new AnimatedSprite(this.betIconAsset.resource) : new Sprite(this.betIconAsset.resource);
    this.uiBetIcon.anchor.set(1);
    this.uiBetContainer.addChild(this.uiBetIcon);

    this.uiBetAmount = new BitmapText(this.betAmountLabel, { fontName: this.fontName, fontSize: 48 });
    this.uiBetAmount.anchor.set(1);
    this.uiBetAmount.x = this.uiBetIcon.x - this.uiBetIcon.width - this.padding;
    this.uiBetContainer.addChild(this.uiBetAmount);

    this.uiBalanceIcon = isArray(this.balanceIconAsset.resource) ? new AnimatedSprite(this.balanceIconAsset.resource) : new Sprite(this.balanceIconAsset.resource);
    this.uiBalanceIcon.anchor.set(0, 1);
    this.uiBalanceIcon.x = this.padding * 2;
    this.container.addChild(this.uiBalanceIcon);

    this.uiBalanceAmount = new BitmapText(this.balanceLabel, { fontName: this.fontName, fontSize: 48 });
    this.uiBalanceAmount.anchor.set(0, 1);
    this.uiBalanceAmount.x = this.uiBalanceIcon.x + this.uiBalanceIcon.width + this.padding;
    this.container.addChild(this.uiBalanceAmount);
  }

  setActions() {
    this.uiBetContainer.eventMode = this.isBetAmountClickDisabled ? 'none' : 'static';
    this.uiBetContainer.alpha = this.isBetAmountClickDisabled ? 0.5 : 1;
    this.uiBetContainer.cursor = 'pointer';
    this.uiBetContainer.on('pointertap', () => {
      this.playTapSound?.();
      this.onBetAmountClick();
      triggerAnalyticsEvent({ name: 'BetAmountButtonClick' });
    });
  }

  setPosition() {
    const y = this.getAppHeight() - (this.padding * 2);

    this.uiBetContainer.y = y;
    this.setUiBetContainerHitArea();

    this.uiBalanceIcon.y = y;
    this.uiBalanceAmount.y = y;
  }

  setBalanceLabel(value) {
    this.uiBalanceAmount.text = value;
  }

  setBetAmountLabel(value) {
    this.uiBetAmount.text = value;
    this.setUiBetContainerHitArea();
  }

  disableBetAmountClick() {
    this.uiBetContainer.eventMode = 'none';
    this.uiBetContainer.alpha = 0.5;
  }

  enableBetAmountClick() {
    this.uiBetContainer.eventMode = 'static';
    this.uiBetContainer.alpha = 1;
  }

  setUiBetContainerHitArea() {
    const bounds = this.uiBetContainer.getLocalBounds();

    this.uiBetContainer.hitArea = new Rectangle(bounds.x, bounds.y, bounds.width, bounds.height);
  }
}
