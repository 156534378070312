import { Label } from '@/components/shared/Label';
import { slotState } from './SlotState';

export class SlotLabel extends Label {
  constructor(params) {
    super({
      backgroundColor: slotState.options.colors.highlightBrighter,
      textColor: slotState.options.colors.highlightText,
      fontName: slotState.options.fontNameNormal,
      ...params,
    });
  }
}
