import { SlotType } from '../models';
import { SlotReelsNormal } from './SlotReelsNormal';
import { SlotReelsCascade } from './SlotReelsCascade';
import { slotState } from './SlotState';

export function SlotReels() {
  switch (slotState.options.type) {
    case SlotType.Cascade:
      return new SlotReelsCascade();
    default:
      return new SlotReelsNormal();
  }
}
