import { Container, BitmapText, Graphics, Sprite, Texture } from '@/pixi';

// Make sure to add Notification.container on app root container, and to call Notification.setPosition on app resize
export class Notification {
  constructor({
    text, // Required, String
    type = 'info', // Possible values: 'info', 'success', 'error', 'warning'
    getAppWidth, // Required, Function, should return Number
    getAppHeight, // Required, Function, should return Number
    iconCloseAsset, // Required
    onClose, // Optional, Function
    playTapSound, // Optional, Function
    fontName = 'RobotoNormal',
  }) {
    this.getAppWidth = getAppWidth;
    this.getAppHeight = getAppHeight;

    this.container = new Container();
    this.labelContainer = new Container();
    this.label = undefined;
    this.labelBackground = undefined;
    this.iconClose = undefined;

    // Setup

    const padding = 25;
    const colors = {
      info: {
        background: 0xff7800,
        text: 0xffffff,
      },
      success: {
        background: 0x000000,
        text: 0xffffff,
      },
      error: {
        background: 0xd13131,
        text: 0xffffff,
      },
      warning: {
        background: 0xffe843,
        text: 0x000000,
      },
    };

    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0.3;

    this.label = new BitmapText(text, {
      fontName,
      fontSize: 48,
      tint: colors[type].text,
    });
    this.label.maxWidth = getAppWidth() - padding * 6;
    this.label.x = padding;
    this.label.y = padding;

    this.iconClose = new Sprite(iconCloseAsset.resource);
    this.iconClose.x = this.label.x + this.label.width + padding;
    this.iconClose.y = padding;
    this.iconClose.tint = colors[type].text;

    this.labelBackground = new Graphics()
      .beginFill(colors[type].background)
      .drawRoundedRect(0, 0, this.label.width + this.iconClose.width + padding * 3, this.label.height + padding * 2, 20)
      .endFill();

    // Set actions

    this.overlay.eventMode = 'dynamic';

    this.iconClose.eventMode = 'static';
    this.iconClose.cursor = 'pointer';
    this.iconClose.on('pointertap', () => {
      playTapSound?.();
      onClose?.();
      this.container.destroy({ children: true });
    });

    this.setPosition();

    this.labelContainer.addChild(this.labelBackground, this.label, this.iconClose);
    this.container.addChild(this.overlay, this.labelContainer);
  }

  setPosition() {
    if (!this.container.destroyed) {
      this.overlay.width = this.getAppWidth();
      this.overlay.height = this.getAppHeight();

      this.labelContainer.x = this.getAppWidth() / 2 - this.labelBackground.width / 2;
      this.labelContainer.y = this.getAppHeight() / 1.5 - this.labelBackground.height / 2;
    }
  }
}
