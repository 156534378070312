import { DialogContentMenuHistory } from '@/components/shared/DialogContentMenuHistory';
import { SlotHistoryTable } from './SlotHistoryTable';
import { SlotRoundModal } from './SlotRoundModal';
import { slotState } from './SlotState';

export class SlotDialogMenuHistory extends DialogContentMenuHistory {
  constructor() {
    super({
      RoundsTableComponent: SlotHistoryTable,
      RoundModalComponent: SlotRoundModal,
      rootContainer: slotState.container,
      isDemo: slotState.options.isDemo,
      tenantGameId: slotState.options.tenantGameId,
      playerToken: slotState.options.playerToken,
      translations: slotState.options.translations,
      width: slotState.options.calcWidth,
      uiDialogPadding: slotState.options.uiDialogPadding,
      textColor: slotState.options.colors.text,
      fontNameNormal: slotState.options.fontNameNormal,
      fontNameBold: slotState.options.fontNameBold,
      highlightColor: slotState.options.colors.highlightBrighter,
      loaderColor: slotState.options.colors.highlight,
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      getDialogMenuFullHeadHeight: () => slotState.dialogMenu.getFullHeadHeight(),
      playTapSound: () => { slotState.playTapSound(); },
    });
  }
}
