import animate from 'gsap';
import { isNil } from 'lodash';
import { Container, AnimatedSprite, Sprite } from '../pixi';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';

/*
This is a component that shows sprite animation
when we wait for bonus symbol required for bonus
win. It is overlayed over one reel.
*/
export class SlotReelBonus {
  constructor() {
    this.options = slotState.options;
    this.isAnimated = false;
    this.showDuration = 0.1;
    this.hideDuration = 0.2;
    this.container = new Container();
    this.sprite = undefined;
    this.container.visible = this.options.config.isBonusSymbol;
    this.container.renderable = false;
    this.container.alpha = 0;

    this.createSprite();
  }

  get width() {
    return this.container.width;
  }

  get height() {
    return this.container.height;
  }

  createSprite() {
    if (this.options.assets.reelBonus) {
      const textures = this.options.assets.reelBonus.resource;

      if (textures.length > 1) {
        this.sprite = new AnimatedSprite(textures);
        this.sprite.animationSpeed = 0.4;
        this.sprite.loop = true;
        this.isAnimated = true;
        this.sprite.anchor.set(0.5, 0);
        this.container.addChild(this.sprite);
      } else if (textures.length === 1) {
        this.sprite = new Sprite(textures[0]);
        this.sprite.anchor.set(0.5, 0);
        this.container.addChild(this.sprite);
      }
    }
  }

  scaleTo(x, y) {
    this.sprite.scale.x = x / this.sprite.width;
    this.sprite.scale.y = y / this.sprite.height;
  }

  positionTo(x, y) {
    this.container.x = x;
    this.container.y = y;
  }

  positionToReel(reelSize) {
    this.container.x = reelSize.x;
    this.container.y = reelSize.y;
    this.container.width = reelSize.width;
    this.container.height = reelSize.height;
  }

  play() {
    if (this.isAnimated) {
      this.sprite.play();
    }
  }

  async show() {
    if (isNil(this.sprite)) {
      return undefined;
    }

    const that = this;

    return new Promise((resolve) => {
      animate.to(this.container, {
        duration: this.showDuration,
        pixi: {
          alpha: 1,
        },
        onComplete() {
          that.container.renderable = true;
          audio.play(that.options.assets.soundReelBonusWait);

          if (that.sprite) {
            that.sprite.play();
          }

          resolve();
        },
      });
    });
  }

  async hide() {
    const that = this;

    return new Promise((resolve) => {
      animate.to(this.container, {
        duration: this.hideDuration,
        pixi: {
          alpha: 0,
        },
        onComplete() {
          that.container.renderable = false;
          audio.stop(that.options.assets.soundReelBonusWait);

          if (that.sprite) {
            that.sprite.gotoAndStop(0);
          }

          resolve(true);
        },
      });
    });
  }
}
