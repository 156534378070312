import animate from 'gsap';
import chroma from 'chroma-js';
import { clone, isArray, isEmpty, merge, take, takeRight } from 'lodash';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, LINE_CAP, LINE_JOIN, Color } from '../pixi';
import { audio } from './SlotAudio';
import { SlotBaseWinlines } from './SlotBaseWinlines';
import { SlotWinlineAmount } from './SlotWinlineAmount';

export class SlotSimpleWinlines extends SlotBaseWinlines {
  constructor(container, reelsContainer, position, isWithSymbol) {
    super(container, reelsContainer, position, isWithSymbol);

    this.winlineLine = undefined;
    this.winlineOutline = undefined;
    this.winlineDefaultColor = 'e5de00';
    this.winlineOutlineColor = '000000';
    this.winlineTimeline = undefined;
    this.winlineDelayShow = 0.3;
    this.winlineDurationShow = (this.winlineDelayRepeat - this.winlineDelayShow) / (this.options.config.reels * 2);

    this.winlineStyle = {
      width: 8,
      cap: LINE_CAP.ROUND,
      join: LINE_JOIN.ROUND,
    };

    this.winlineOutlineStyle = merge({
      ...this.winlineStyle,
      color: new Color(this.winlineOutlineColor).toNumber(),
    }, {
      width: 10,
    });

    this.start();
    this.startLine();
  }

  getWinLineColors(winlineCount) {
    let optionsColors = this.options.winlineColors;

    if (!isArray(optionsColors) || isEmpty(optionsColors)) {
      optionsColors = [this.winlineDefaultColor];
    }

    const winlineColors = [];
    const isSingleColor = optionsColors.length === 1 || (optionsColors.length === 2 && optionsColors[0] === optionsColors[1]);

    let generatedColors;
    if (!isSingleColor) {
      generatedColors = chroma.scale(take(optionsColors, 2)).mode('lch').colors(winlineCount);
    }

    while (winlineColors.length < winlineCount) {
      const color = isSingleColor ? optionsColors[0] : generatedColors[winlineColors.length];
      winlineColors.push(new Color(color).toNumber());
    }

    return winlineColors;
  }

  startLine() {
    this.winlinesAmountContainer = new Container();
    this.winlineLine = new Graphics();
    this.winlineOutline = new Graphics();

    this.container.addChild(this.winlineOutline);
    this.container.addChild(this.winlineLine);
    this.container.addChild(this.winlinesAmountContainer);
  }

  stopWinLineTimeline() {
    if (this.winlineTimeline && this.winlineTimeline.isActive()) {
      this.winlineTimeline.kill();
      this.winlineTimeline = undefined;
    }
  }

  async showWinLines({
    applyMultiplierOnWin,
    isFree,
    isFreeRoundsEnd,
    isFreeRoundsWon,
    isSyncWinAmount,
    multiplierApplyCount,
    multiplierValue,
    showIndividualWinlines = true,
    winAmount,
    winLines,
  }) {
    if (this.isEmptyWinlines(winLines)) {
      this.enableSymbols();

      if (winAmount > 0) {
        await this.showAllWinLines({
          applyMultiplierOnWin,
          isFree,
          isFreeRoundsEnd,
          isFreeRoundsWon,
          isSyncWinAmount,
          multiplierApplyCount,
          multiplierValue,
          winAmount,
          winLines,
        });
      }

      return false;
    }

    const that = this;

    return new Promise((resolve) => {
      this.addStartAnimation(winLines, multiplierApplyCount, resolve, () => {
        this.showAllWinLines({
          applyMultiplierOnWin,
          isFree,
          isFreeRoundsEnd,
          isFreeRoundsWon,
          multiplierApplyCount,
          multiplierValue,
          winAmount,
          winLines,
        });
      });

      const winlineColors = this.getWinLineColors(winLines.length);
      let isResolved = false;

      winLines.forEach((winLine, winlineIndex) => {
        this.winlinesLoopTimeline.to(this.symbolSegments.hit[winlineIndex], {
          delay: this.winlineDelayRepeat,
          duration: this.winlineShowDuration,
          pixi: {
            alpha: 1.0,
          },
          onComplete() {
            if (this.repeat() === 0) {
              that.hideAllWinLines();

              if (!isResolved) {
                resolve(true);
                isResolved = true;
              }
            }

            if (showIndividualWinlines === false) {
              if (!isResolved) {
                resolve(true);
              }
              return;
            }

            const points = that.getPoints(winLines, winlineIndex);
            const pointsCount = points.length;

            that.winlineTimeline = animate.timeline();

            that.winlineLine.clear();
            that.winlineOutline.clear();

            that.winlineLine.lineStyle({
              ...that.winlineStyle,
              color: winlineColors[winlineIndex],
            });

            that.winlineOutline.lineStyle(that.winlineOutlineStyle);

            points.forEach((point, pointIndex) => {
              const currentPoint = clone(point);
              const nextPoint = pointIndex < pointsCount - 1 ? points[pointIndex + 1] : undefined;
              if (nextPoint) {
                that.winlineTimeline.to(currentPoint, {
                  x: nextPoint.x,
                  y: nextPoint.y,
                  duration: that.winlineDurationShow,
                  onStart() {
                    if (pointIndex === Math.floor(pointsCount / 2)) {
                      const pointMiddle = clone(points[pointIndex]);

                      /*
                      Calculate middle position if even reels.
                      */
                      if (pointsCount % 2 === 0) {
                        const pointPrevious = points[pointIndex - 1];
                        pointMiddle.x = pointPrevious.x + ((pointMiddle.x - pointPrevious.x) / 2);
                      }

                      that.winlinesAmountContainer.addChild(
                        new SlotWinlineAmount({
                          options: that.options,
                          winAmount: winLine.winLineCollectAmount > 0 ? winLine.winLineCollectAmount : winLine.winLineAmount,
                          position: pointMiddle,
                          scale: 0.25,
                          isWithBackground: false,
                        }).container,
                      );
                    }
                  },
                  onUpdate() {
                    that.winlineLine.moveTo(point.x, point.y);
                    that.winlineOutline.moveTo(point.x, point.y);
                    that.winlineLine.lineTo(currentPoint.x, currentPoint.y);
                    that.winlineOutline.lineTo(currentPoint.x, currentPoint.y);
                  },
                });
              }
            });

            points.forEach((point, pointIndex) => {
              const currentPoint = clone(point);
              const nextPoint = pointIndex < pointsCount - 1 ? points[pointIndex + 1] : undefined;
              if (nextPoint) {
                that.winlineTimeline.to(currentPoint, {
                  x: nextPoint.x,
                  y: nextPoint.y,
                  duration: that.winlineDurationShow,
                  delay: pointIndex > 0 ? 0 : that.winlineDelayShow,
                  onStart() {
                    if (pointIndex === 2) {
                      that.winlinesAmountContainer.removeChildren();
                    }
                  },
                  onUpdate() {
                    that.winlineLine.clear();
                    that.winlineOutline.clear();

                    that.winlineLine.lineStyle({
                      ...that.winlineStyle,
                      color: winlineColors[winlineIndex],
                    });

                    that.winlineOutline.lineStyle(that.winlineOutlineStyle);

                    that.winlineLine.moveTo(currentPoint.x, currentPoint.y);
                    that.winlineOutline.moveTo(currentPoint.x, currentPoint.y);
                    that.winlineLine.lineTo(nextPoint.x, nextPoint.y);
                    that.winlineOutline.lineTo(nextPoint.x, nextPoint.y);

                    const pointsRest = takeRight(points, pointsCount - (pointIndex + 1));
                    pointsRest.forEach((pointRest, pointRestIndex) => {
                      const nextPointRest = pointRestIndex < pointsRest.length - 1 ? pointsRest[pointRestIndex + 1] : undefined;
                      if (nextPointRest) {
                        that.winlineLine.moveTo(pointRest.x, pointRest.y);
                        that.winlineOutline.moveTo(pointRest.x, pointRest.y);
                        that.winlineLine.lineTo(nextPointRest.x, nextPointRest.y);
                        that.winlineOutline.lineTo(nextPointRest.x, nextPointRest.y);
                      }
                    });
                  },
                });
              }
            });

            this.targets().forEach((symbol) => {
              if (that.isWithSymbol) {
                symbol.$ref.showHit();
              }
            });
          },
        }, '<');

        if (showIndividualWinlines) {
          this.addWinlineEndAnimation(winlineIndex);
        }
      });

      this.addEndAnimation();
    });
  }

  async showAllWinLines({
    applyMultiplierOnWin,
    isFree,
    isFreeRoundsEnd,
    isFreeRoundsWon,
    isSyncWinAmount,
    multiplierApplyCount,
    multiplierValue,
    winAmount,
    winLines,
  }) {
    return new Promise((resolve) => {
      audio.play(this.options.assets.soundReelWinline);

      const winlineColors = this.getWinLineColors(winLines.length);
      const winAmountPosition = this.getMiddlePoint();

      this.winlineLine.clear();
      this.winlineOutline.clear();
      this.winlineOutline.lineStyle(this.winlineOutlineStyle);

      winLines.forEach((winLine, winLineIndex) => {
        const points = this.getPoints(winLines, winLineIndex);

        this.winlineLine.lineStyle({
          ...this.winlineStyle,
          color: winlineColors[winLineIndex],
        });

        this.winlineLine.moveTo(points[0].x, points[0].y);
        this.winlineOutline.moveTo(points[0].x, points[0].y);

        points.forEach((point) => {
          this.winlineLine.lineTo(point.x, point.y);
          this.winlineOutline.lineTo(point.x, point.y);
        });

        this.winlineLine.endFill();
        this.winlineOutline.endFill();
      });

      this.winlinesAmountContainer.addChild(this.winlinesAmount.container);

      this.winlinesAmount.show({
        animationDuration: this.getWinlineAnimateAllDuration(multiplierApplyCount),
        applyMultiplierOnWin,
        isFree,
        isFreeRoundsEnd,
        isFreeRoundsWon,
        isSyncWinAmount,
        multiplierValue,
        position: winAmountPosition,
        winAmount,
        resolve,
      });

      if (!isSyncWinAmount) {
        resolve(true);
      }
    });
  }

  hideAllWinLines() {
    this.winlineLine.clear();
    this.winlineOutline.clear();
    this.winlinesAmountContainer.removeChildren();
  }

  async stopWinLines() {
    return new Promise((resolve) => {
      this.stop();
      this.stopWinLineTimeline();
      this.hideAllWinLines();
      this.winlineLine.clear();
      this.winlineOutline.clear();
      resolve(true);
    });
  }
}
