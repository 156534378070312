/* eslint no-param-reassign: "off" */
import { defaultTo } from 'lodash';

export class Parser {
  constructor(params, defaults) {
    this.options = undefined;
    this.defaults = defaultTo(defaults, {});
    this.params = params;
  }

  getResource(options, name, finalAssets, fromSpritesheet, disableFallback) {
    if (finalAssets) {
      const spriteName = `${name}.png`;
      const spritesheet = defaultTo(fromSpritesheet, 'ui');

      if (finalAssets[spritesheet] && finalAssets[spritesheet].resource.textures[spriteName]) {
        return {
          name,
          resource: finalAssets[spritesheet].resource.textures[spriteName],
        };
      }
    }

    return disableFallback ? undefined : options.resources[name];
  }

  getResourceAnimation(finalAssets, name) {
    if (finalAssets.ui && finalAssets.ui.resource.animations[name]) {
      return {
        name,
        resource: finalAssets.ui.resource.animations[name],
      };
    }

    if (finalAssets.uiOverlay && finalAssets.uiOverlay.resource.animations[name]) {
      return {
        name,
        resource: finalAssets.uiOverlay.resource.animations[name],
      };
    }

    if (finalAssets.grading && finalAssets.grading.resource.animations[name]) {
      return {
        name,
        resource: finalAssets.grading.resource.animations[name],
      };
    }

    return undefined;
  }
}
