import animate from 'gsap';
import { isNil, last } from 'lodash';
import { AnimatedSprite, Container, Sprite, Texture } from '../pixi';
import { SlotNumber } from './SlotNumber';
import { SlotNumberCurrency } from './SlotNumberCurrency';
import { registerEventListener, triggerEvent } from '../utility/Utility';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';

export class SlotWinGrading {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.text = new Container();
    this.title = new Container();
    this.background = new Sprite(Texture.WHITE);
    this.coins = undefined;
    this.number = new SlotNumber(0);
    this.numberCurrency = undefined;
    this.textBackground = undefined;
    this.timeline = undefined;
    this.textScale = undefined;
    this.titleScale = 2.0;
    this.isStopped = false;
    this.isStoppable = true;
    this.winGrades = undefined;

    this.setup();
    this.setListeners();
  }

  setup() {
    this.background.tint = 0x000000;
    this.container.alpha = 0;

    this.title.scale.set(this.titleScale);

    this.text.addChild(this.number.container);

    if (this.options.currencyDisplayEnabled && slotState.areCurrencyTexturesAvailable) {
      this.numberCurrency = new SlotNumberCurrency();
      this.numberCurrency.setScale(0.5);
      this.text.addChild(this.numberCurrency.container);
    }

    this.container.eventMode = 'static';
    this.container.visible = false;
    this.container.on('pointertap', this.stop.bind(this));

    this.container.addChild(this.background);

    if (this.options.assets.totalWinBackground) {
      const textBackgroundTextures = this.options.assets.totalWinBackground.resource;

      this.textBackground = new Sprite(last(textBackgroundTextures));
      this.textBackground.anchor.set(0.5);
      this.container.addChild(this.textBackground);
    }

    if (this.options.assets.winCoin) {
      this.coins = new AnimatedSprite(this.options.assets.winCoin.resource);
      this.coins.loop = false;
      this.coins.animationSpeed = 0.4;
      this.coins.visible = false;
      this.coins.anchor.set(0.5);
      this.container.addChild(this.coins);
    }

    this.container.addChild(this.title);
    this.container.addChild(this.text);
  }

  setPosition() {
    const { width, height } = this.options.size();
    const scale = this.container.parent.scale.y;

    this.background.width = width / scale;
    this.background.height = height / scale;

    this.coins.x = this.background.width / 2;
    this.coins.y = this.background.height / 2;
    this.coins.scale.set(this.background.width / this.coins.texture.width);

    this.setTextPosition();
  }

  setListeners() {
    const source = 'SlotWinGrading';

    registerEventListener('ShowWinGrading', (event) => {
      this.textScale = event.detail.textScale;
      this.winGrades = event.detail.winGrades;
      this.show();
    }, source);
  }

  setTextPosition() {
    const content = slotState.content.container;
    const reels = slotState.reelBackground.container;
    const positionY = content.y + reels.y + (reels.height / 2);

    this.text.x = content.x + (content.width / 2) - (this.text.width / 2);
    this.text.y = positionY - (this.text.height / 2);
    this.title.x = content.x + content.width / 2;
    this.title.y = content.y + (this.title.height / 4);
    this.textBackground.x = content.x + content.width / 2;
    this.textBackground.y = positionY;
  }

  show() {
    if (isNil(this.winGrades)) {
      return;
    }

    let textScaleFactors;
    const maxAmountLenght = last(this.winGrades.grades).amountTo.toString().length;

    if (maxAmountLenght >= 6) {
      textScaleFactors = [1.05, 1.1, 1.15];
    } else if (maxAmountLenght >= 5) {
      textScaleFactors = [1.1, 1.2, 1.3];
    } else {
      textScaleFactors = [1.2, 1.4, 1.5];
    }

    this.isStopped = false;
    this.isStoppable = true;
    this.background.alpha = 0;
    this.number.create(this.winGrades.limit);

    this.text.scale.set(this.textScale);
    this.textBackground.scale.set(this.textScale * 2);

    this.setPosition();

    this.timeline = animate.timeline({
      paused: true,
      onStart: this.start.bind(this),
    });

    this.timeline.to(this.background, {
      duration: 0.5,
      pixi: {
        alpha: 0.8,
      },
      onStart() {
        const background = this.targets()[0];
        background.parent.visible = true;
        background.parent.alpha = 1;
      },
    });

    this.winGrades.grades.forEach((grade, gradeIndex) => {
      const gradeDuration = grade.timeMs / 1000;
      const isLastGrade = gradeIndex === this.winGrades.grades.length - 1;

      this.number.progressTo({
        amount: grade.amountTo,
        amountFrom: grade.amountFrom,
        timeline: this.timeline,
        timelinePosition: gradeIndex > 0 ? undefined : '<',
        duration: gradeDuration,
        onUpdate: () => {
          if (this.numberCurrency) {
            this.numberCurrency.container.x = this.number.container.width / 2 - this.numberCurrency.container.width / 2;
            this.numberCurrency.container.y = this.number.container.height;
          }
        },
      });

      this.timeline.to(this.text, {
        duration: gradeDuration,
        pixi: {
          scale: this.textScale * textScaleFactors[1],
        },
        onStart: this.playGrade.bind(this, gradeIndex, isLastGrade),
        onUpdate: this.setTextPosition.bind(this),
        onComplete: () => {
          if (isLastGrade) {
            this.isStoppable = false;
          } else {
            this.text.scale.set(this.textScale);
            this.setTextPosition();
          }
        },
      }, '<');
    });

    this.timeline.addLabel('stop');

    this.timeline.to(this.text, {
      duration: 2.0,
      pixi: {
        scale: this.textScale * textScaleFactors[2],
      },
      onUpdate: this.setTextPosition.bind(this),
    });

    this.timeline.to(this.text, {
      duration: 0.2,
      pixi: {
        scale: this.textScale * textScaleFactors[0],
      },
      onUpdate: this.setTextPosition.bind(this),
      onStart() {
        audio.play(slotState.options.assets.soundWinGradingOutro);
      },
    });

    this.timeline.to(this.text, {
      duration: 0.1,
      pixi: {
        scale: this.textScale * textScaleFactors[1],
      },
      onUpdate: this.setTextPosition.bind(this),
    });

    this.timeline.to(this.textBackground ? [this.text, this.textBackground] : [this.text], {
      duration: 0.2,
      pixi: {
        scale: 0,
      },
      onUpdate: this.setTextPosition.bind(this),
    });

    this.timeline.to(this.container, {
      duration: 1.0,
      pixi: {
        alpha: 0,
      },
      onUpdate() {
        const { soundWinGradingLoop } = slotState.options.assets;

        if (soundWinGradingLoop) {
          soundWinGradingLoop.resource.volume(1 - this.progress());
        }
      },
      onComplete() {
        this.targets()[0].visible = false;
        slotState.togglePaused();
        audio.stop(slotState.options.assets.soundWinGradingLoop);
        slotState.setSoundAmbientVolume(1);
        triggerEvent('WinGradingEnded');
      },
    });

    this.timeline.play();
  }

  stop() {
    if (this.timeline && !this.isStopped && this.isStoppable) {
      this.isStopped = true;
      this.timeline.seek('stop', false);
    }
  }

  playGrade(gradeIndex) {
    if (this.isStopped && gradeIndex < this.winGrades.length - 1) {
      return;
    }

    const titleAnimation = new AnimatedSprite(this.options.assets.winGrades[gradeIndex].resource);

    titleAnimation.loop = false;
    titleAnimation.animationSpeed = 0.4;
    titleAnimation.anchor.set(0.5, 0);

    this.title.removeChildren();
    this.title.addChild(titleAnimation);

    titleAnimation.play();

    audio.play(this.options.assets.soundWinGradingTitleHit);

    this.coins.visible = true;
    this.coins.onComplete = () => { this.coins.visible = false; };
    this.coins.gotoAndPlay(0);
  }

  start() {
    audio.play(this.options.assets.soundWinGradingLoop);
    slotState.setSoundAmbientVolume(0.2);

    triggerEvent('WinGradingStarted');
  }
}
