import animate from 'gsap';
import { defaultTo, isFunction, isNaN } from 'lodash';
import { AnimatedSprite, Container, Graphics, Sprite } from '../pixi';
import { slotState } from './SlotState';
import { getTextureIndexByChar } from '../utility/Utility';

export class SlotNumber {
  constructor(amount, prefix, decimals = 2) {
    this.options = slotState.options;
    this.decimals = decimals;
    this.prefix = prefix;
    this.amount = amount;
    this.amountText = undefined;
    this.container = new Container();
    this.numberTextures = this.options.assets.generalCounter.resource;
    this.numberDotTexture = this.options.assets.generalDot.resource;
    this.numberCommaTexture = this.options.assets.generalComma.resource;
    this.numberMultiplierTexture = this.options.assets.generalMultiplier.resource;
    this.numberPlusTexture = this.options.assets.generalPlus.resource;
    this.isNumberTextureAnimation = this.numberTextures.length > 1;

    this.create(amount);
  }

  center() {
    this.container.x = -this.container.width / 2;
    this.container.y = -this.container.height / 2;
  }

  create(amount) {
    this.container.removeChildren();

    this.amount = amount;
    this.amountText = this.amount.toLocaleString(this.options.locale, {
      minimumFractionDigits: this.decimals,
      maximumFractionDigits: this.decimals,
    });

    if (this.prefix) {
      this.amountText = `${this.prefix}${this.amountText}`;
    }

    const textChars = this.amountText.split('');
    let lastPositionX = 0;

    textChars.forEach((textChar) => {
      const numberSprite = this.isNumberTextureAnimation ? new AnimatedSprite(this.numberTextures) : new Sprite(this.numberTextures[0]);
      const numberChar = Number(textChar);
      const numberCharIndex = getTextureIndexByChar(this.numberTextures, textChar);

      if (!isNaN(numberChar)) {
        let numberBounds;

        if (this.isNumberTextureAnimation) {
          numberSprite.gotoAndStop(numberCharIndex);
          numberBounds = numberSprite.getBounds();
        } else {
          numberBounds = numberSprite.getBounds();
          const numberOffset = 0;
          const numberHeight = numberBounds.height / 10;
          const numberPositionY = numberCharIndex * numberHeight;
          const numberMask = new Graphics().beginFill(0x000000).drawRect(lastPositionX, numberHeight * numberOffset, numberBounds.width, numberHeight).endFill();

          numberSprite.y = -numberPositionY + (numberHeight * numberOffset);
          numberSprite.mask = numberMask;

          this.container.addChild(numberMask);
        }

        numberSprite.x = lastPositionX;
        lastPositionX += numberBounds.width;

        this.container.addChild(numberSprite);
      } else if (['.', ','].indexOf(textChar) > -1) {
        const numberDotSprite = new Sprite(textChar === ',' ? this.numberCommaTexture : this.numberDotTexture);
        const numberDotBounds = numberDotSprite.getBounds();

        numberDotSprite.x = lastPositionX;
        numberDotSprite.y = 0;

        lastPositionX += numberDotBounds.width;

        this.container.addChild(numberDotSprite);
      } else if (['x', '+'].indexOf(textChar) > -1) {
        const numberMultiplierSprite = new Sprite(textChar === 'x' ? this.numberMultiplierTexture : this.numberPlusTexture);
        const numberMultiplierBounds = numberMultiplierSprite.getBounds();

        numberMultiplierSprite.x = lastPositionX;
        numberMultiplierSprite.y = 0;

        lastPositionX += numberMultiplierBounds.width;

        this.container.addChild(numberMultiplierSprite);
      }
    });
  }

  show() {
    this.container.visible = true;
  }

  hide() {
    this.container.visible = false;
  }

  progressTo({
    amount,
    amountFrom,
    duration,
    parent,
    scale,
    timeline,
    timelinePosition,
    onStart,
    onUpdate,
    onComplete,
  }) {
    const that = this;
    const amountStart = defaultTo(amountFrom, 0);
    const amountAnimate = {
      amount: amountStart,
    };

    const animation = defaultTo(timeline, animate.timeline());

    animation.to(amountAnimate, {
      duration,
      amount,
      onStart() {
        if (isFunction(onStart)) {
          onStart();
        }
      },
      onUpdate() {
        that.create(amountAnimate.amount);

        if (isFunction(onUpdate)) {
          onUpdate();
        }
      },
      onComplete() {
        if (isFunction(onComplete)) {
          onComplete();
        }
      },
    }, defaultTo(timelinePosition, '>'));

    if (parent && scale) {
      animation.to(parent, {
        duration,
        pixi: {
          scale,
        },
        onUpdate() {
          if (isFunction(onUpdate)) {
            onUpdate();
          }
        },
      }, '<');
    }
  }

  scale(value) {
    this.container.scale.set(value);
  }
}
