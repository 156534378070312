import animate from 'gsap';
import numeral from 'numeral';
import * as api from '@/api/casino';
import { assign, clone, defaultTo, first, isNil, get, each, isEmpty } from 'lodash';
import { makeAutoObservable, reaction } from 'mobx';
import { pusher } from '@/plugins';
import { audio } from './SlotAudio';
import { getTextureIndexByChar, getErrorParams, sleep, triggerEvent } from '../utility/Utility';

class SlotState {
  constructor() {
    /* Passed options into component */
    this.options = undefined;
    /* State elements accessible in in entire component */
    this.container = undefined;
    this.background = undefined;
    this.content = undefined;
    this.controls = undefined;
    this.activeDialog = undefined;
    this.dialog = undefined;
    this.dialogMenu = undefined;
    this.dialogBetAmount = undefined;
    this.dialogBonusBuy = undefined;
    this.dialogAutoplay = undefined;
    this.dialogWarning = undefined;
    this.progress = undefined;
    this.progressBar = undefined;
    this.reelBackground = undefined;
    this.reelBonus = undefined;
    this.reels = undefined;
    this.reelsHeader = undefined;
    this.reelsFooter = undefined;
    this.reelsOverlay = undefined;
    this.reelsCollect = undefined;
    this.reelsMultipliers = undefined;
    this.symbolDetail = undefined;
    this.winGrading = undefined;
    this.onboardScreen = undefined;
    this.notification = undefined;
    /* Data state */
    this.activePaylines = 1;
    this.activePromotion = undefined;
    this.availableFreeRounds = 0;
    this.errorOnSocketEvent = undefined;
    this.betAmount = 1;
    this.warningDialogShown = undefined;
    this.featureToPurchase = undefined;
    this.initialBetAmount = undefined;
    this.isPaused = false;
    this.isAutoplay = false;
    this.isBonusBuy = false;
    this.isDialogOpen = false;
    this.isWarningDialogOpen = undefined;
    this.isFree = false;
    this.isPromotion = false;
    this.isPromotionStopped = false;
    this.isPromotionLastSpin = false;
    this.isPromotionOnHold = false;
    this.isIncreasingFreeRoundMultiplier = false;
    this.isBetAmountDisabled = true;
    this.isBonusBuyEnabled = false;
    this.isBonusGameWon = false;
    this.isProgressBuyEnabled = false;
    this.isCollectBuyEnabled = false;
    this.isStateUpdated = false;
    this.isBonusGameActive = false;
    this.isRoundPreviewActive = false;
    this.lastPlayedBetAmount = undefined;
    this.lastRound = undefined;
    this.endPromotion = undefined;
    this.pendingWin = undefined;
    this.moneyFormat = '0,0.00';
    this.moneyFormatDecimalOptional = '0.[00]';
    this.multiplierValue = 1;
    this.pendingBalance = undefined;
    this.pendingBetAmountChange = undefined;
    this.pendingBonusOutroScreen = undefined;
    this.pendingBalanceOnSocketEvent = undefined;
    this.promotionAfterPromotion = false;
    this.promotionType = undefined;
    this.resetFeaturesOnSpin = false;
    this.soundAmbient = undefined;
    this.soundAmbientAlt = undefined;
    this.soundAmbientAsset = undefined;
    this.soundAmbientAssetAlt = undefined;
    this.soundAmbientTimeline = undefined;
    this.totalFreeRoundsCount = undefined;
    this.updateBalanceOnSocketEvent = undefined;
    this.player = undefined;
    this.autoplaySettings = {
      currentBalance: 0,
      stopOnAnyWin: false,
      lossLimit: {
        enabled: false,
        amount: 0,
      },
      winLimit: {
        enabled: false,
        amount: 0,
      },
    };
    this.freeRoundWinAmount = 0;
    this.dynamicBonusReels = undefined;
    this.bonus = undefined;
    this.isOpenBonus = undefined;
    this.jackpot = undefined;
    this.errorDetails = undefined;

    makeAutoObservable(this);
  }

  watch(path, callback) {
    reaction(() => get(this, path), callback);
  }

  get balanceAmount() {
    return this.player.balance.amount;
  }

  get isInFreeRounds() {
    return this.availableFreeRounds > 0;
  }

  get isWinGradingEnabled() {
    return this.options.config.isWinGradingSupported;
  }

  get isFeatureWon() {
    if (this.lastRound) {
      return this.lastRound.isBonusWon || this.lastRound.isJackpotWon || this.lastRound.isProgressWon;
    }

    return false;
  }

  get isSpacebarEnabled() {
    return !this.isAutoplay && !this.isInFreeRounds && !this.isDialogOpen && !this.errorDetails && !this.isWarningDialogOpen && !this.activePromotion && !this.jackpot?.isWon;
  }

  get areCurrencyTexturesAvailable() {
    const currencyCharacters = this.options.currency.split('');
    const textures = this.options.assets.generalCounter.resource;

    return currencyCharacters.every((char) => getTextureIndexByChar(textures, char) > -1);
  }

  get bonusType() {
    return this.options.config.bonus?.bonusType;
  }

  get progressActiveBonusType() {
    return this.progress?.unload?.bonus?.bonusType;
  }

  get progressBonusType() {
    return this.progress?.current?.bonus?.bonusType;
  }

  showDialog(dialog) {
    // If onboard screen is active then add dialog behind it
    if (this.onboardScreen) {
      this.container.addChildAt(dialog.container, this.container.children.length - 1);
    } else {
      this.container.addChild(dialog.container);
    }

    this.dialog = dialog;

    dialog.setPosition();
    dialog.show();
  }

  hideDialog() {
    this.container?.removeChild(this.dialog?.container);
    this.dialog = undefined;
  }

  applyPendingBalance() {
    if (this.pendingBalance) {
      const pendingAmount = this.pendingBalance.amount;
      this.player.balance.amount = pendingAmount > 0 ? pendingAmount : 0;
      this.pendingBalance = undefined;
    }

    this.pendingBalanceOnSocketEvent = undefined;

    this.setBetAmountState();
  }

  create(options) {
    this.options = options;
    this.collect = options.collect;
    this.progress = options.progress;
    this.multiplierValue = options.multiplierValue;
    this.activePromotion = options.activePromotion;
    this.availableFreeRounds = this.activePromotion ? this.activePromotion.prizeCountLeft : options.availableFreeRounds;
    this.totalFreeRoundsCount = options.totalFreeRoundsCount;
    this.isBonusBuyEnabled = options.config.isBonusBuyEnabled;
    this.isProgressBuyEnabled = options.config.isProgressBuyEnabled;
    this.isCollectBuyEnabled = options.config.isCollectBuyEnabled;
    this.isIncreasingFreeRoundMultiplier = options.config.isIncreasingFreeRoundMultiplier;
    this.freeRoundWinAmount = options.freeRoundWinAmount;
    this.dynamicBonusReels = options.dynamicBonusReels;
    this.isOpenBonus = options.config.isOpenBonus;
    this.initialBetAmount = options.state?.betAmount || options.state?.collect?.betAmount || first(options.settings.predefinedBetAmounts) || options.config.activePaylines * options.settings.minBetAmount;
    this.lastPlayedBetAmount = options.state?.betAmount || options.state?.collect?.betAmount;

    if (this.options.isDemo) {
      /* Dummy player */
      this.player = {
        currency: this.options.currency,
        balance: {
          amount: 1000,
        },
      };
    } else {
      this.player = this.options.player;
      pusher.init(this.options.player.id, process.env.APP_PUSHER_KEY, this.options.tenantGameId);
    }

    if (this.options.config.activePaylines) {
      this.activePaylines = this.options.config.activePaylines;
    }

    if (this.options.settings.predefinedBetAmounts) {
      this.betAmount = first(this.options.settings.predefinedBetAmounts);
      this.betAmountDefault = this.betAmount;
    }

    if (this.options.collect && this.options.collect.multiplier > 1) {
      this.setBetAmount(this.options.collect.betAmount);
    }

    if (this.options.freeRoundBetAmount > 0) {
      this.setBetAmount(this.options.freeRoundBetAmount);
    }

    if (this.lastPlayedBetAmount) {
      this.setBetAmount(this.lastPlayedBetAmount);
    }

    this.setBetAmountState();

    this.parseProgressMultiplierSymbol();
  }

  decreaseBetAmountFromBalance(result) {
    const winAmount = result.winAmount || result.collect?.winAmount || 0;

    if (this.isBonusBuy) {
      this.player.balance.amount = result.balance.amount - winAmount;
    } else {
      this.player.balance.amount -= this.betAmount;

      if (this.isAutoplay && (this.autoplaySettings.lossLimit.enabled || this.autoplaySettings.winLimit.enabled)) {
        const newBalance = this.autoplaySettings.currentBalance - this.betAmount;
        this.autoplaySettings.currentBalance = parseFloat(newBalance.toFixed(2));
      }
    }

    return this.getBalanceLabel();
  }

  getBalanceLabel() {
    return this.getMoneyLabel(this.balanceAmount, true);
  }

  getBetAmountLabel() {
    return this.getMoneyLabel(this.betAmount, this.options.currencyDisplayEnabled);
  }

  getMoneyLabel(amount, showCurrency) {
    const value = numeral(amount).format(this.moneyFormat);

    return showCurrency ? `${value} ${this.player.currency}` : value;
  }

  getMoneyDecimalOptionalLabel(amount) {
    return numeral(amount).format(this.moneyFormatDecimalOptional);
  }

  getOptionsFromSpinResult(result) {
    let multiplierValue = result.freeRoundMultiplier;

    if (result.isFreeRoundsEnd) {
      multiplierValue = this.isIncreasingFreeRoundMultiplier ? result.freeRoundMultiplier : this.multiplierValue;
    }

    return {
      applyMultiplierOnWin: result.isFree,
      activePromotion: result.activePromotion,
      availableFreeRounds: result.activePromotion ? result.activePromotion.prizeCountLeft : result.availableFreeRounds,
      totalFreeRoundsCount: result.totalFreeRoundsCount,
      balance: result.balance,
      betAmount: result.betAmount,
      bonus: result.bonus,
      collect: result.collect,
      wildMultiplier: result.wildMultiplier,
      isFree: result.isFree,
      isIncreasingFreeRoundMultiplier: result.isIncreasingFreeRoundMultiplier,
      isBonusBuyEnabled: result.isBonusBuyEnabled,
      isProgressBuyEnabled: result.isProgressBuyEnabled,
      isCollectBuyEnabled: result.isCollectBuyEnabled,
      jackpot: result?.jackpot,
      multiplierValue,
      player: result.player,
      progress: result.progress,
      reelWindow: result.reelWindow,
      reelWindows: result.reelWindows,
      showIndividualWinlines: !result.isFree && !this.isAutoplay,
      winAmount: result.winAmount,
      winGrades: result.winGrades,
      winLines: result.winLines,
      freeRoundWinAmount: result.freeRoundWinAmount,
      dynamicBonusReels: result.dynamicBonusReels,
    };
  }

  getWinGrades() {
    if (this.lastRound && this.lastRound.winGrades && !this.isFeatureWon) {
      const grades = clone(this.lastRound.winGrades);
      const limit = this.lastRound.betAmount * 5;

      grades[0].amountFrom = limit;
      grades[0].timeMs -= 1000;

      return {
        limit,
        grades,
      };
    }

    return undefined;
  }

  isCollectMultiplierLocked() {
    return !isNil(this.collect) && this.collect.multiplier > 1;
  }

  isBetIncreased(betAmount) {
    return this.lastRound ? this.lastRound.betAmount < betAmount : this.initialBetAmount < betAmount;
  }

  updateBalance(balance) {
    if (balance) {
      assign(this.player, { balance });
    }
  }

  updateOptions(options) {
    if (options) {
      assign(this.options, options);
      this.options.isDemo = isNil(this.options.playerToken);
    }
  }

  updateAfterSpin(spinOptions) {
    const isPromotionAfterFreeRounds = this.availableFreeRounds === 1 && spinOptions.activePromotion?.prizeCountLeft && !spinOptions?.bonus?.iswon;
    const isPromotionAfterPromotion = this.activePromotion?.id !== spinOptions?.activePromotion?.id;
    const { isBonusWon, isFreeRoundsWon, isPickPrizeWon, isProgressFreeRoundsWon, isProgressPickPrizeWon } = this.lastRound || {};

    this.collect = spinOptions.collect;
    this.isFree = spinOptions.isFree;
    this.isBonusBuyEnabled = spinOptions.isBonusBuyEnabled;
    this.isProgressBuyEnabled = spinOptions.isProgressBuyEnabled;
    this.isCollectBuyEnabled = spinOptions.isCollectBuyEnabled;
    this.activePromotion = this.isPromotionLastSpin || this.isPromotionStopped || this.isPromotionOnHold ? this.activePromotion : spinOptions.activePromotion;
    this.multiplierValue = spinOptions.multiplierValue || spinOptions.freeRoundMultiplier;
    this.pendingBalance = this.updateBalanceOnSocketEvent ? this.pendingBalanceOnSocketEvent : spinOptions.balance;
    this.progress = spinOptions.progress;
    this.freeRoundWinAmount = spinOptions.freeRoundWinAmount;
    this.dynamicBonusReels = spinOptions.dynamicBonusReels;
    this.bonus = spinOptions.bonus;
    this.isIncreasingFreeRoundMultiplier = spinOptions.isIncreasingFreeRoundMultiplier;
    this.warningDialogShown = false;
    this.totalFreeRoundsCount = spinOptions.totalFreeRoundsCount;
    this.isBonusGameWon = isFreeRoundsWon || isPickPrizeWon || isProgressFreeRoundsWon || isProgressPickPrizeWon || isBonusWon;
    this.jackpot = spinOptions.jackpot;

    if (this.isPromotionLastSpin) {
      this.activePromotion.amountWon = !isNil(this.activePromotion.amountWon) ? this.activePromotion.amountWon : 0;
      this.activePromotion.amountWon += spinOptions.winAmount;
    }

    if (this.isPromotionLastSpin && isPromotionAfterPromotion) {
      this.promotionAfterPromotion = spinOptions?.activePromotion;
    }

    if (this.isBonusGameActive && this.activePromotion && !this.promotionAfterPromotion && this.endPromotion) {
      this.promotionAfterPromotion = this.activePromotion;
    }

    if (isPromotionAfterFreeRounds || this.isPromotionStopped) {
      this.availableFreeRounds = 0;
    } else {
      this.availableFreeRounds = spinOptions.availableFreeRounds;
    }

    this.isPromotionStopped = false;

    if (this.isPromotionStopped) {
      this.reelsMultipliers.hideMultiplier();
    }

    /*
    Reset collect multiplier after spin.
    */
    if (this.collect && this.collect.isWon) {
      this.collect.multiplier = 1;
    }

    this.isStateUpdated = true;
  }

  async updateSlotState() {
    this.controls.disableInSpin();
    this.controls.controlsMain.disableSpinButton();
    this.isBetAmountDisabled = true;

    await sleep(500);

    const timeout = setTimeout(() => {
      this.controls.controlsMain.reelsLoader.showLoader();
    }, this.controls.controlsMain.reelsLoaderTimeout);

    const response = await api.getState(undefined, this.options.tenantGameId, this.options.playerToken);

    clearTimeout(timeout);
    this.controls.controlsMain.reelsLoader.hideLoader();

    if (response.isError) {
      this.setErrorDetails(response);
      this.controls?.enableAfterSpin();
      return;
    }

    this.updateAfterSpin(response);

    this.setLastRound(undefined);

    this.setBetAmountState();

    if (!this.activePromotion) {
      this.controls.enableAfterSpin();
    }
  }

  resetFeatures() {
    if (this.collect?.multiplier >= 1) this.reelsMultipliers.updateCollectMultiplier(1);

    if (this.progress?.current?.unitValue >= 0 && this.featureToPurchase !== 'buyProgress') {
      this.progress.current.unitValue = 0;
      triggerEvent('UpdateProgress', this.progress);
    }

    this.resetFeaturesOnSpin = false;
  }

  setPromotion(promotion, isPromotion) {
    this.activePromotion = isPromotion ? promotion : undefined;
    this.isPromotion = defaultTo(isPromotion, false);
    const prizeCountLeft = this.activePromotion?.prizeCountLeft || this.activePromotion?.prizeCountPerPlayer;
    this.availableFreeRounds = this.isPromotion && !this.isPromotionStopped ? prizeCountLeft : 0;

    if (this.isPromotion) {
      this.reelsMultipliers.hideMultiplier();
    }
  }

  setActivePaylines(value) {
    this.activePaylines = value;
  }

  setBetAmount(value) {
    this.betAmount = value;
  }

  setBetAmountState() {
    let isDisabled = false;

    if (this.availableFreeRounds > 0 || this.isAutoplay || this.isInFreeRounds || this.isPromotion) {
      this.isBetAmountDisabled = true;

      return;
    }

    if (this.lastPlayedBetAmount && this.balanceAmount < this.lastPlayedBetAmount && this.balanceAmount < this.betAmount) {
      const minAmount = this.betAmountDefault || this.options.settings.minBetAmount * this.activePaylines;
      this.setBetAmount(minAmount);
    }

    if (this.options.settings.predefinedBetAmounts && this.balanceAmount < this.options.settings.predefinedBetAmounts[0]) {
      const minAmount = this.options.settings.minBetAmount * this.activePaylines;

      if (this.balanceAmount >= minAmount) {
        this.setBetAmount(minAmount);
      }

      isDisabled = true;
    }

    this.isBetAmountDisabled = isDisabled;
  }

  setSoundAmbient() {
    this.soundAmbientAsset = this.options.showMultiplier && this.options.assets.soundAmbientFree ? this.options.assets.soundAmbientFree : this.options.assets.soundAmbient;
    this.soundAmbient = audio.play(this.soundAmbientAsset, { loop: true });
  }

  setSoundAmbientVolume(value) {
    if (this.soundAmbientAsset) {
      this.soundAmbientAsset.resource.volume(value);
    }
  }

  setSpinSpeedType(type) {
    this.options.spinSpeedType = type;
  }

  toggleSoundAmbient() {
    if (this.soundAmbientAsset === this.options.assets.soundAmbient && this.options.assets.soundAmbientFree) {
      this.soundAmbientAssetAlt = this.options.assets.soundAmbientFree;
    } else if (this.soundAmbientAsset === this.options.assets.soundAmbientFree && this.options.assets.soundAmbient) {
      this.soundAmbientAssetAlt = this.options.assets.soundAmbient;
    } else {
      return;
    }

    const that = this;

    if (this.soundAmbientTimeline && this.soundAmbientTimeline.isActive()) {
      this.soundAmbientTimeline.progress(1);
      this.soundAmbientTimeline.kill();
      this.soundAmbientTimeline = undefined;
    }

    this.soundAmbientTimeline = animate.timeline();
    this.soundAmbientAlt = audio.play(this.soundAmbientAssetAlt, { loop: true, volume: 0 });

    this.soundAmbientTimeline.to(this.soundAmbientAssetAlt.resource, {
      duration: 1.5,
      volume: 1,
    });

    this.soundAmbientTimeline.to(this.soundAmbientAsset.resource, {
      duration: 1.5,
      volume: 0,
      onComplete() {
        audio.pause(that.soundAmbientAsset, that.soundAmbient);
        that.soundAmbientAsset = that.soundAmbientAssetAlt;
        that.soundAmbient = that.soundAmbientAlt;
      },
    });
  }

  togglePaused() {
    this.isPaused = !this.isPaused;
  }

  toggleBonusBuy() {
    this.isBonusBuy = !this.isBonusBuy;
  }

  setDialogOpen(value) {
    this.isDialogOpen = value;
  }

  setAutoplay(value) {
    this.isAutoplay = value;

    // When autoplay is turned off reset settings
    if (!value) {
      this.resetAutoplaySettings();
    }
  }

  setPendingWin(value) {
    this.pendingWin = value;
  }

  updateCollect(collect) {
    if (this.collect) {
      assign(this.collect, collect);
    }
  }

  playTapSound() {
    audio.play(this.options.assets.soundTap);
  }

  setAutoplaySettings(stopOnAnyWin, lossLimit, winLimit) {
    this.autoplaySettings.stopOnAnyWin = stopOnAnyWin;
    this.autoplaySettings.lossLimit.enabled = lossLimit.enabled;
    this.autoplaySettings.lossLimit.amount = lossLimit.enabled ? lossLimit.amount : 0;
    this.autoplaySettings.winLimit.enabled = winLimit.enabled;
    this.autoplaySettings.winLimit.amount = winLimit.enabled ? winLimit.amount : 0;
    this.autoplaySettings.currentBalance = 0;
  }

  resetAutoplaySettings() {
    this.setAutoplaySettings(false, { enabled: false }, { enabled: false });
  }

  setLastRound(value) {
    this.lastRound = value;

    if (value?.winAmount && this.isAutoplay && (this.autoplaySettings.lossLimit.enabled || this.autoplaySettings.winLimit.enabled)) {
      const newBalance = this.autoplaySettings.currentBalance + value.winAmount;
      this.autoplaySettings.currentBalance = parseFloat(newBalance.toFixed(2));
    }
  }

  parseProgressMultiplierSymbol() {
    const progressDynamicMultiplierSymbols = this.progress?.unload?.bonus?.dynamicMultiplierSymbols;
    const { dynamicMultiplierSymbols } = this.options.config;
    let { symbolsListTrash } = this.options.config;

    if (!isEmpty(progressDynamicMultiplierSymbols)) {
      each(progressDynamicMultiplierSymbols, (symbol) => {
        if (!dynamicMultiplierSymbols.includes(symbol)) {
          dynamicMultiplierSymbols.push(symbol);
        }

        symbolsListTrash = symbolsListTrash.filter((trashSymbol) => trashSymbol !== symbol);
      });
    }
  }

  setErrorDetails(details) {
    this.errorDetails = getErrorParams(details, this.options.translations);

    if (this.options.boostTenantId) {
      if (this.errorDetails.isErrorReloadable) {
        triggerEvent('HideBoostBadge');
      } else {
        triggerEvent('DisableBoostBadge');
      }
    }

    if (this.errorDetails.isRciError) return;

    if (this.isInFreeRounds) {
      triggerEvent('ToggleAutoplay', { count: 0 });
    }

    triggerEvent('SetNotificationDetails', this.errorDetails.notification);
  }
}

export const slotState = new SlotState();
