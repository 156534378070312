// Component for vertical scrolling
import { Container, Graphics, Rectangle } from '@/pixi';

export class ScrollBox {
  #container;
  #mask;
  #list;
  #onScrollCallback;
  #height;
  #items;
  #itemsMargin;
  #verticalPadding;
  #horizontalPadding;

  constructor({
    height = 0,
    items = [],
    itemsMargin = 0,
    padding = 0,
    verticalPadding, // Optional, Number
    horizontalPadding, // Optional, Number
  }) {
    this.#height = height;
    this.#items = items;
    this.#itemsMargin = itemsMargin;
    this.#verticalPadding = verticalPadding || padding;
    this.#horizontalPadding = horizontalPadding || padding;

    // Setup

    this.#container = new Container();
    this.#container.name = 'ScrollBox';

    this.#mask = new Graphics();
    this.#container.mask = this.#mask;
    this.#container.addChild(this.#mask);

    this.#list = new Container();
    this.addItems(this.#items);
    this.#list.x = this.#horizontalPadding;
    this.#list.y = this.#verticalPadding;
    this.#container.addChild(this.#list);

    this.#setActions();
  }

  get container() {
    return this.#container;
  }

  set onScroll(callback) {
    this.#onScrollCallback = callback;
  }

  #isScrollable() {
    return this.#list.height + this.#verticalPadding * 2 > this.#height;
  }

  #setActions() {
    this.#list.eventMode = 'static';

    // Wheel event
    this.#list.on('wheel', (e) => {
      if (this.#isScrollable()) {
        const isScrolledDown = e.deltaY > 0;

        this.#scroll(isScrolledDown, Math.abs(e.deltaY));
      }
    });

    // Touch events
    const touchmoveSpeed = 2.5;
    let preventPropagationPointerTap = false;
    let preventPropagationTap = false;
    let touchmoveY;

    const globaltouchmoveCallback = (e) => {
      const oldTouchmoveY = touchmoveY || e.global.y;

      if (e.global.y !== oldTouchmoveY && this.#isScrollable()) {
        const isScrolledDown = e.global.y < oldTouchmoveY;
        const movement = (e.global.y - oldTouchmoveY) * touchmoveSpeed;

        this.#scroll(isScrolledDown, Math.abs(movement));

        preventPropagationPointerTap = true;
        preventPropagationTap = true;
      }

      touchmoveY = e.global.y;
    };

    this.#list.on('touchstart', () => {
      this.#list.addEventListener('globaltouchmove', globaltouchmoveCallback);
    });

    const touchendCallback = () => {
      this.#list.removeEventListener('globaltouchmove', globaltouchmoveCallback);
      touchmoveY = undefined;
    };

    this.#list.on('touchend', touchendCallback);
    this.#list.on('touchendoutside', touchendCallback);

    // Stop propagation of events if globaltouchmove has been triggered

    this.#list.addEventListener('pointertap', (e) => {
      if (preventPropagationPointerTap) {
        e.stopPropagation();
        preventPropagationPointerTap = false;
      }
    }, true);

    this.#list.addEventListener('tap', (e) => {
      if (preventPropagationTap) {
        e.stopPropagation();
        preventPropagationTap = false;
      }
    }, true);
  }

  #scroll(isScrolledDown, movement) {
    if (isScrolledDown) {
      const maxHeight = -1 * (this.#list.height + this.#verticalPadding - this.#height);
      const currentY = this.#list.y;

      if (currentY > maxHeight) {
        const nextY = this.#list.y - movement;
        const scrolledToBottom = nextY <= maxHeight;

        this.#list.y = scrolledToBottom ? maxHeight : nextY;

        this.#onScrollCallback?.({
          movement,
          scrolledDown: true,
          scrolledToBottom,
        });
      } else if (currentY < 0) {
        this.#list.y = maxHeight;
      }
    // Scrolled up
    } else if (this.#list.y < this.#verticalPadding) {
      const nextY = this.#list.y + movement;
      const scrolledToTop = nextY >= this.#verticalPadding;

      this.#list.y = scrolledToTop ? this.#verticalPadding : nextY;

      this.#onScrollCallback?.({
        movement,
        scrolledUp: true,
        scrolledToTop,
      });
    }
  }

  #setItemsPositions() {
    const width = this.#list.width + this.#horizontalPadding * 2;

    this.#items.forEach((item, index) => {
      const previousItem = this.#list.children[index - 1];

      this.#list.children[index].y = previousItem ? previousItem.y + previousItem.height + this.#itemsMargin : 0;
    });

    this.#mask.clear().beginFill(0x000000).drawRect(0, 0, width, this.#height).endFill();

    this.#list.hitArea = new Rectangle(-this.#horizontalPadding, -this.#verticalPadding, width, this.#list.height + this.#verticalPadding * 2);
  }

  #fixEmptySpace() {
    const maxHeight = -1 * (this.#list.height + this.#verticalPadding - this.#height);
    const currentY = this.#list.y;

    if (maxHeight > currentY && maxHeight < this.#verticalPadding) {
      this.scrollToBottom();
    } else if (maxHeight >= this.#verticalPadding) {
      this.scrollToTop();
    }
  }

  addItems(items) {
    if (items.length) {
      if (this.#items !== items) {
        this.#items.push(...items);
      }

      this.#list.addChild(...items);

      this.#setItemsPositions();
    }
  }

  addItem(item) {
    this.addItems([item]);
  }

  removeAllItems() {
    this.#items = [];
    this.#list.removeChildren();

    this.scrollToTop();
  }

  removeItem(item) {
    const index = this.#items.findIndex((i) => i === item);

    if (index > -1) {
      this.#items.splice(index, 1);
      this.#list.removeChild(item);

      this.#setItemsPositions();
      this.#fixEmptySpace();
    }
  }

  scrollToTop() {
    this.#scroll(false, 1000000);
  }

  scrollToBottom() {
    this.#scroll(true, 1000000);
  }

  scrollToItem(item) {
    if (this.#items.find((i) => i === item)) {
      this.#list.y = -item.y;

      this.#fixEmptySpace();
    }
  }

  setDimensions({ height, padding, verticalPadding, horizontalPadding, itemsMargin }) {
    const verPadding = verticalPadding || padding;
    const horPadding = horizontalPadding || padding;

    if (typeof height === 'number') {
      this.#height = height;
    }

    if (typeof verPadding === 'number') {
      this.#verticalPadding = verPadding;
    }

    if (typeof horPadding === 'number') {
      this.#horizontalPadding = horPadding;
      this.#list.x = this.#horizontalPadding;
    }

    if (typeof itemsMargin === 'number') {
      this.#itemsMargin = itemsMargin;
    }

    this.#setItemsPositions();
    this.#fixEmptySpace();
  }
}
