import { ControlsFooter } from '@/components/shared/ControlsFooter';
import { slotState } from './SlotState';

export class SlotControlsFooter extends ControlsFooter {
  constructor() {
    super({
      betAmountLabel: slotState.getBetAmountLabel(),
      balanceLabel: slotState.getBalanceLabel(),
      onBetAmountClick: () => {
        slotState.dialogBetAmount.show();
      },
      isBetAmountClickDisabled: slotState.isBetAmountDisabled,
      playTapSound: () => { slotState.playTapSound(); },
      betIconAsset: slotState.options.assets.uiBetIcon,
      balanceIconAsset: slotState.options.assets.uiBalanceIcon,
      appWidth: slotState.options.calcWidth,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      padding: slotState.options.uiPadding,
      fontName: slotState.options.fontNameBold,
    });
  }

  setBalanceAmountAfterBet(result) {
    if ((!slotState.isFree && !slotState.isInFreeRounds) || slotState.isBonusBuy) {
      this.setBalanceLabel(slotState.decreaseBetAmountFromBalance(result));
    }
  }

  setBalanceAmountAfterWin() {
    slotState.applyPendingBalance();
    this.setBalanceLabel(slotState.getBalanceLabel());
  }

  updateBalance() {
    this.setBalanceLabel(slotState.getBalanceLabel());
  }

  disableInSpin() {
    this.disableBetAmountClick();
  }

  enableAfterSpin() {
    if (!slotState.isBetAmountDisabled) {
      this.enableBetAmountClick();
    }

    this.setBetAmountLabel(slotState.getBetAmountLabel());
  }
}
