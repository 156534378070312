import animate, { Power2 } from 'gsap';
import { defaultTo, findIndex, isFunction, map } from 'lodash';
import { Container, AnimatedSprite, Ticker } from '../pixi';
import { SlotNumber } from './SlotNumber';
import { slotState } from './SlotState';

export class SlotSymbolCascade {
  constructor(value, isDisabled) {
    this.options = slotState.options;
    this.symbolValue = value;
    this.isDisabled = defaultTo(isDisabled, true);
    this.expandedScale = 1.5;

    this.container = undefined;
    this.sprite = undefined;
    this.reveal = undefined;
    this.leave = undefined;
    this.winAmount = undefined;

    this.setup();
  }

  get symbolIndex() {
    return findIndex(this.options.assets.symbols, { value: this.symbolValue });
  }

  get isExpanded() {
    return this.options.expandedSymbols.indexOf(this.symbolValue) > -1;
  }

  get width() {
    return this.container.width;
  }

  get height() {
    return this.container.height;
  }

  setup() {
    const symbolAsset = this.options.assets.symbols[this.symbolIndex];
    const symbolTextures = defaultTo(symbolAsset.animation, map(symbolAsset.assets, (asset) => asset.resource));

    this.sprite = new AnimatedSprite(symbolTextures);
    this.sprite.animationSpeed = 0.4;
    this.sprite.anchor.set(0.5);

    this.container = new Container();
    this.container.addChild(this.sprite);
  }

  setReveal(value) {
    this.reveal = value;
  }

  setLeave(value) {
    this.leave = value;
  }

  setVisible(value) {
    this.container.visible = value;
  }

  setScaleToSize(width, height) {
    this.sprite.scale.x = width / this.sprite.width;
    this.sprite.scale.y = height / this.sprite.height;

    if (this.isExpanded) {
      this.container.zIndex = 100;
      this.container.scale.set(this.expandedScale);
    }
  }

  setPosition(x, y) {
    this.container.x = x;
    this.container.y = y;
  }

  play() {
    this.sprite.gotoAndPlay(0);
  }

  explode(winAmount, explodeDuration, onExplode) {
    const symbol = this;
    const timeline = animate.timeline();
    const playDuration = this.sprite.totalFrames / (Ticker.shared.maxFPS * this.sprite.animationSpeed);

    timeline.to(symbol.container, {
      duration: playDuration,
      onStart() {
        symbol.play();
      },
    });

    timeline.to(symbol.sprite, {
      duration: 0.1,
      pixi: {
        alpha: 0,
      },
      onComplete() {
        if (isFunction(onExplode)) {
          onExplode();
        }
      },
    });

    if (winAmount) {
      this.winAmount = new SlotNumber(winAmount);
      this.winAmount.scale(0.2);
      this.winAmount.center();
      this.winAmount.hide();
      this.container.addChild(this.winAmount.container);

      timeline.to(this.winAmount.container, {
        duration: explodeDuration,
        ease: Power2.easeIn,
        pixi: {
          alpha: 0.5,
          y: `-=${this.winAmount.container.height}`,
        },
        onStart() {
          symbol.winAmount.show();
        },
        onComplete() {
          symbol.setVisible(false);
        },
      });
    }

    return timeline;
  }
}
