import {
  assign,
  clone,
  constant,
  defaultTo,
  each,
  filter,
  find,
  flatten,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isObject,
  merge,
  random,
  range,
  remove,
  shuffle,
  times,
  uniq,
} from 'lodash';
import animate, { Elastic, Sine } from 'gsap';
import { MotionBlurFilter } from '@pixi/filter-motion-blur';
import { Container } from '../pixi';
import { SlotSymbol } from './SlotSymbol';
import { SlotWinlines } from './SlotWinlines';
import { SlotSpinSpeedType } from '../models';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';
import { triggerEvent } from '../utility/Utility';

export class SlotReelsNormal {
  constructor() {
    this.reelsTimeline = undefined;
    this.winlinesTimeline = undefined;
    this.winlineTrail = undefined;
    this.container = undefined;
    this.reelsContainer = undefined;
    this.options = slotState.options;
    this.autoReveal = slotState.options.autoReveal;
    this.reelPadding = slotState.options.reelPadding;
    this.isRevealed = false;
    this.isSpinning = false;
    this.isStopPhase = false;
    this.reelWidth = 0;
    this.reelRowHeight = 0;
    this.revealDuration = 2.5;
    this.revealDurationDelay = 0.1;
    this.revealStackSymbols = 0;
    this.spinStackSymbols = 12;
    this.spinDurationTurbo = 0.5;
    this.spinDuration = 1.5;
    this.spinDurationDelay = 0.1;
    this.spinDurationPull = 0.4;
    this.spinDurationPush = 0.5;
    this.spinDurationTurboDelay = 0.05;
    this.spinDurationTurboPush = 0.25;
    this.spinDurationLightning = 0.15;
    this.spinDurationLightningPush = 0.1;
    this.spinDurationLightningPull = 0.1;
    this.spinDurationStopPush = 0.1;
    this.spinDurationBonusWait = 2.0;
    this.spinBlurMatrix = [[0.0, 0.75, 50], [0.75, 0.95, 5]];
    this.bonusSymbolList = [];

    this.addContainers();
    this.addSymbols();
    this.addWinlines();
    this.setListeners();
    this.setMask();

    if (this.autoReveal) {
      this.reveal();
    }
  }

  addContainers() {
    this.container = new Container();
    this.reelsContainer = new Container();

    this.container.y = slotState.reelsHeader.height;

    this.container.addChild(this.reelsContainer);
  }

  addWinlines() {
    this.winLines = new SlotWinlines(
      this.container,
      this.reelsContainer,
      {
        x: slotState.reelBackground.container.x,
        y: slotState.reelBackground.container.y,
      },
    );
  }

  addSymbols() {
    const reelWidth = (slotState.reelBackground.width - (this.reelPadding[0] * 2)) / this.options.config.reels;
    const reelRowHeight = (slotState.reelBackground.height - (this.reelPadding[1] * 2)) / this.options.config.rows;

    this.createRevealReels().reelWindowStack.forEach((reel, reelIndex) => {
      const reelContainer = new Container();

      reelContainer.filters = [
        new MotionBlurFilter([0, 0], 15),
      ];
      reelContainer.filters[0].enabled = false;

      reel.forEach((symbolValue, rowIndex) => {
        const symbol = new SlotSymbol(symbolValue, this.isSymbolDisabledBySetting(reelIndex, rowIndex));

        symbol.scaleTo(reelWidth, reelRowHeight);
        symbol.positionTo((rowIndex * reelRowHeight) + (reelRowHeight / 2) + this.reelPadding[1]);

        reelContainer.x = (reelIndex * reelWidth) + (reelWidth / 2) + this.reelPadding[0];

        reelContainer.addChild(symbol.container);
      });

      this.reelsContainer.addChild(reelContainer);
    });

    this.reelWidth = reelWidth;
    this.reelRowHeight = reelRowHeight;
  }

  createRevealReels() {
    const reelWindow = [];
    const symbolList = remove(clone(this.options.config.symbolsList), (n) => this.options.config.symbolsListNoDetail.indexOf(n) < 0);
    let symbolIndex = 0;

    range(this.options.config.reels).forEach((reel, reelIndex) => {
      reelWindow.push([]);

      range(this.options.config.rows).forEach(() => {
        reelWindow[reelIndex].push(symbolList[symbolIndex]);
        symbolIndex += 1;

        if (symbolIndex >= symbolList.length - 1) {
          symbolIndex = 0;
        }
      });

      reelWindow[reelIndex] = shuffle(reelWindow[reelIndex]);
    });

    const reelsStack = this.createReelsStack(reelWindow, this.revealStackSymbols);

    return {
      reelWindow,
      ...reelsStack,
    };
  }

  createSpinReels(reelWindow, reelCollect, spinSpeed, isFree) {
    const { bonusSymbol, bonusSymbolWaitOn, dynamicMultiplierSymbols, isBonusSymbol, isIncreasingFreeRoundMultiplier, isProgressSymbol, isWildSymbol, symbolsListProgress } = this.options.config;
    const spinStackSymbols = [];
    const reelDurations = [];
    const reelBonusWaits = [];
    const reelBonusSymbols = [];
    const reelProgressSymbols = [];
    const reelDynamicMultiplierSymbols = [];
    const reelDurationBase = defaultTo(this[`spinDuration${spinSpeed}`], this.spinDuration);

    let reelProgressSymbolsCount = 0;
    let reelSpinStackSymbols = this.spinStackSymbols;
    let reelDuration = reelDurationBase;
    let reelBonusWait = 0;
    let isBonusWait = false;
    let bonusSymbolsRevealed = 0;

    slotState.parseProgressMultiplierSymbol();

    each(reelWindow, (reel, reelIndex) => {
      if (bonusSymbolsRevealed >= bonusSymbolWaitOn && spinSpeed === SlotSpinSpeedType.Normal) {
        isBonusWait = true;
        reelBonusWait += 1;
        reelSpinStackSymbols += (this.spinStackSymbols * 3) + (this.spinStackSymbols * reelBonusWait);
        reelDuration += (this.spinDurationBonusWait + ((this.spinDurationBonusWait / 4) * reelBonusWait));
      }

      reelDurations.push(reelDuration);
      reelBonusWaits.push(reelBonusWait);

      if (spinSpeed !== SlotSpinSpeedType.Lightning) {
        spinStackSymbols.push(reelSpinStackSymbols);
      }

      if (isBonusSymbol) {
        const bonusSymbols = filter(reel, (symbol) => symbol === bonusSymbol);
        const bonusSymbolsCount = bonusSymbols.length;

        reelBonusSymbols.push(bonusSymbolsCount);

        if (bonusSymbolsCount > 0) {
          bonusSymbolsRevealed += bonusSymbolsCount;
        }
      }

      each(reel, (symbol, symbolIndex) => {
        if (isProgressSymbol && symbolsListProgress.indexOf(symbol) > -1) {
          reelProgressSymbolsCount += 1;
          reelProgressSymbols.push([reelIndex, symbolIndex, symbol]);
        } else {
          reelProgressSymbols.push(undefined);
        }

        if ((isIncreasingFreeRoundMultiplier || isWildSymbol) && dynamicMultiplierSymbols.indexOf(symbol) > -1) {
          reelDynamicMultiplierSymbols.push([reelIndex, symbolIndex, symbol]);
        } else {
          reelDynamicMultiplierSymbols.push(undefined);
        }
      });
    });

    const reelsStack = this.createReelsStack(reelWindow, spinStackSymbols, isFree);

    reelsStack.reelWindowStack.forEach((reelWindowStack) => {
      reelWindowStack.reverse();
    });

    return {
      isBonusWait,
      reelCollect,
      reelDurations,
      reelBonusWaits,
      reelBonusSymbols,
      reelDynamicMultiplierSymbols,
      reelProgressSymbols,
      reelProgressSymbolsCount,
      reelWindow,
      ...reelsStack,
    };
  }

  createReelsStack(reelWindow, stackSymbols, isFree) {
    let stackSymbolsCount = stackSymbols;
    const reelWindowStack = [];
    const reelWindowStops = [];

    if (isNumber(stackSymbols)) {
      stackSymbolsCount = times(this.options.config.reels, constant(stackSymbols));
    }

    range(this.options.config.reels).forEach((reel, reelIndex) => {
      const reelStackSymbolsCount = stackSymbolsCount[reel];

      reelWindowStack.push([]);
      reelWindowStack[reelIndex].push(...reelWindow[reelIndex]);

      if (reelStackSymbolsCount > 0) {
        const reelStackRows = this.options.config.rows + reelStackSymbolsCount;
        range(reelStackRows).forEach(() => {
          reelWindowStack[reelIndex].push(this.getRandomSymbol(reelWindow, isFree));
        });
      }

      reelWindowStack[reelIndex].unshift(this.getRandomSymbol(reelWindow, isFree));
      reelWindowStops.push(1);

      if (reelStackSymbolsCount <= 0) {
        reelWindowStack[reelIndex].push(this.getRandomSymbol(reelWindow, isFree));
      }
    });

    return {
      reelWindowStack,
      reelWindowStops,
    };
  }

  createCloneSymbol(reelProgressSymbol) {
    const symbolContainer = this.reelsContainer.children[reelProgressSymbol[0]].children[reelProgressSymbol[1] + 1];
    const symbolBounds = symbolContainer.getLocalBounds();
    const symbolClone = new SlotSymbol(reelProgressSymbol[2], true);

    symbolClone.stopToLast();
    symbolClone.scaleTo(symbolContainer.width, symbolContainer.height);
    symbolClone.container.position.x = (symbolBounds.width * reelProgressSymbol[0]) + (symbolBounds.width / 2) + this.options.reelPadding[0];
    symbolClone.container.position.y = slotState.reelsHeader.container.height + (symbolBounds.height * reelProgressSymbol[1]) + (symbolBounds.height / 2) + this.options.reelPadding[1];

    slotState.reelsOverlay.add(symbolClone);
  }

  getSize() {
    const { bounds } = slotState.reelBackground;

    return {
      width: bounds.width,
      height: bounds.height,
    };
  }

  getRandomSymbol(reelWindow, isFree) {
    let symbolList = this.options.config.symbolsList;

    /*
    We are using reelWindow to get unique symbols included in
    free rounds bonus distribution.
    */
    if (((this.options.config.bonus && this.options.config.bonus.dynamicDistribution) || !isEmpty(this.options.progress)) && reelWindow) {
      symbolList = uniq(flatten(reelWindow).concat(this.bonusSymbolList));
      this.bonusSymbolList = isFree && !slotState.activePromotion ? uniq(symbolList) : [];
    }

    if (slotState.progress?.current?.hidden === false) {
      symbolList = uniq(symbolList.concat(this.options.config.symbolsListProgress));
    }

    const startSymbolIndex = 0;
    const endSymbolIndex = symbolList.length - 1;

    return symbolList[random(startSymbolIndex, endSymbolIndex)];
  }

  getReelWindowSize(reelIndex) {
    const index = defaultTo(reelIndex, 0);
    const reel = this.reelsContainer.children[index];

    return {
      x: reel.x,
      y: this.container.y + this.reelPadding[1],
      width: reel.width,
      height: slotState.reelBackground.height - (this.reelPadding[1] * 2),
    };
  }

  getSymbol(reelIndex, rowIndex) {
    return this.getSymbolContainer(reelIndex, rowIndex).$ref;
  }

  getSymbolContainer(reelIndex, rowIndex) {
    return this.reelsContainer.children[reelIndex].children[rowIndex + 1];
  }

  isSymbolDisabledBySetting(reelIndex, rowIndex) {
    if (rowIndex <= 0 || rowIndex > this.options.config.rows) {
      return true;
    }

    if (this.options.disabledSymbolsPositions) {
      if (find(this.options.disabledSymbolsPositions, (n) => n[0] === reelIndex && n[1] === rowIndex - 1)) {
        return true;
      }
    }

    return false;
  }

  resetReelPosition(reelContainer) {
    const reelRowHeight = (slotState.reelBackground.height - (this.reelPadding[1] * 2)) / this.options.config.rows;

    assign(reelContainer, {
      y: -(reelContainer.height / reelContainer.children.length),
    });

    reelContainer.children.forEach((symbol, rowIndex) => {
      assign(symbol, {
        y: (rowIndex * reelRowHeight) + (reelRowHeight / 2) + (this.reelPadding[1]),
      });

      symbol.$ref.playScale();
    });
  }

  resetSymbolsDisabled() {
    this.reelsContainer.children.forEach((reelContainer, reelIndex) => {
      reelContainer.children.forEach((symbol, rowIndex) => {
        const isDisabled = this.isSymbolDisabledBySetting(reelIndex, rowIndex);
        if (isDisabled && symbol.$ref) {
          symbol.$ref.setEnabled(false);
        }
      });
    });

    this.reelsContainer.eventMode = 'static';
  }

  async reveal() {
    const that = this;

    return new Promise((resolve) => {
      const revealTimeline = animate.timeline({
        paused: true,
        onStart() {
          triggerEvent('ReelsRevealing');
          that.isRevealed = true;
        },
        onComplete() {
          triggerEvent('ReelsRevealed');
          resolve(true);
        },
      });

      this.reelsContainer.children.forEach((reelContainer) => {
        revealTimeline.to(reelContainer, {
          duration: this.revealDuration,
          ease: Elastic.easeOut.config(1.5, 1),
          pixi: {
            y: -(reelContainer.height / reelContainer.children.length),
          },
          onStart() {
            assign(reelContainer, {
              visible: true,
            });
          },
        }, `<+=${this.revealDurationDelay}`);
      });

      revealTimeline.play();
    });
  }

  setListeners() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        if (this.isSpinning) {
          this.stop();
        }
      }
    }, false);
  }

  setMask() {
    this.reelsContainer.mask = slotState.reelBackground.getMask();

    if (!this.isRevealed) {
      this.reelsContainer.children.forEach((reelContainer) => {
        assign(reelContainer, {
          visible: false,
          y: -reelContainer.height,
        });
      });
    }
  }

  async spin(spinOptions, spinResult) {
    audio.stop(this.options.assets.soundReelSpin);
    await this.stopWinLines();

    if (slotState.resetFeaturesOnSpin) {
      slotState.resetFeatures();
    }

    triggerEvent('ReelsStarting');
    this.isSpinning = true;

    slotState.isPromotionStopped = false;

    let isProgressWon = false;
    let isProgressFreeRoundsWon = false;
    let isProgressPickPrizeWon = false;

    const isJackpotWon = !isNil(spinResult.jackpot) && spinResult.jackpot.isWon;
    const isBonusWon = !isNil(spinResult.bonus) && spinResult.bonus.isWon;
    const isFreeRoundsWon = isBonusWon && spinResult.bonus.bonusType === 'FreeRounds';
    const isFreeRoundsEnd = spinResult.isFree && ((spinResult.availableFreeRounds === 0 && isNil(spinResult.activePromotion)) || (!isNil(spinResult.activePromotion) && spinResult.activePromotion.prizeCountLeft === 0));
    const isSyncWinAmount = spinResult.isFree || !isNil(spinResult.winGrades) || isBonusWon || slotState.isAutoplay;
    const isIncreasingFreeRoundMultiplier = defaultTo(spinOptions.isIncreasingFreeRoundMultiplier, slotState.isIncreasingFreeRoundMultiplier);
    const isBonusFreeRoundsEnd = slotState.availableFreeRounds === 1 && !slotState.isPromotion && slotState.bonusType === 'FreeRounds';
    const isPickPrizeWon = isBonusWon && spinResult.bonus.bonusType.includes('PickPrize');

    if (isObject(spinResult)) {
      /* eslint "no-param-reassign": "off" */
      assign(spinResult, {
        isBonusWon,
        isFreeRoundsWon,
        isFreeRoundsEnd,
      });

      spinOptions = assign(slotState.getOptionsFromSpinResult(spinResult), spinOptions);
    }

    const {
      applyMultiplierOnWin,
      betAmount,
      collect,
      wildMultiplier,
      isFree,
      multiplierValue,
      options,
      progress,
      reelWindow,
      showIndividualWinlines,
      spinSpeedType,
      winLines,
      winGrades,
    } = spinOptions;

    let { winAmount } = spinOptions;

    /*
    Overriding global options on spin.
    Winlines particles require reinit on color change.
    This is mostly used in development environment.
    */
    if (isObject(options)) {
      const { mute, winlineType } = this.options;
      const winlineColors = clone(this.options.winlineColors);

      merge(this.options, options);

      if (this.options.mute !== mute) {
        audio.setOptions({
          mute: this.options.mute,
        });
      }

      if (this.options.winlineType !== winlineType || (this.winLines.winlineTrail && !isEqual(this.options.winlineColors, winlineColors))) {
        this.addWinlines();
      }
    }

    /*
    Updating slot state from spin action.
    Make sure to clone original values from state
    if value change is used in spin animations.
    */
    slotState.updateOptions({
      progress,
    });

    slotState.applyPendingBalance();

    slotState.controls.setBalanceAmountAfterBet();

    const that = this;
    const spinSpeed = defaultTo(SlotSpinSpeedType[spinSpeedType], SlotSpinSpeedType.Normal);

    return new Promise((resolve) => {
      this.reelsContainer.eventMode = 'none';
      audio.play(this.options.assets.soundReelsStart);

      const reelsTimeline = animate.timeline();
      const reelsValue = this.createSpinReels(reelWindow, collect, spinSpeed, isFree);
      const reelDurationDelay = defaultTo(this[`spinDuration${spinSpeed}Delay`], this.spinDurationDelay);
      const reelDurationPush = defaultTo(this[`spinDuration${spinSpeed}Push`], this.spinDurationPush);
      const reelDurationPull = defaultTo(this[`spinDuration${spinSpeed}Pull`], this.spinDurationPull);

      this.updateSymbols(reelsValue, showIndividualWinlines === false, wildMultiplier);

      this.reelsContainer.children.forEach((reelContainer, reelContainerIndex) => {
        const reelRowHeight = reelContainer.height / reelContainer.children.length;
        const reelBackHeightIn = reelRowHeight / 2;
        const reelBackHeightOut = reelBackHeightIn / 2;
        const reelEndPosition = reelContainer.height - (reelRowHeight * (this.options.config.rows + 3));
        const reelDuration = reelsValue.reelDurations[reelContainerIndex];
        const reelDelay = reelContainerIndex > 0 ? (reelDurationDelay * reelContainerIndex) : 0;
        const reelTimelineDelay = spinSpeed !== SlotSpinSpeedType.Lightning ? `start+=${reelDelay}` : 'start';
        const reelTimeline = animate.timeline();
        let reelSpinDefaultSound;

        reelTimeline.to(reelContainer, {
          duration: reelDurationPull,
          ease: Sine.easeOut,
          pixi: {
            y: `-=${reelBackHeightIn}`,
          },
        });

        reelTimeline.to(reelContainer, {
          duration: reelDuration,
          pixi: {
            y: reelEndPosition + reelBackHeightOut,
          },
          async onStart() {
            if (spinSpeed !== SlotSpinSpeedType.Lightning) {
              reelSpinDefaultSound = audio.play(that.options.assets.soundReelSpin);
            }

            reelContainer.filters[0].enabled = true;
          },
          onUpdate() {
            const delta = this.progress();
            const blurMatrix = find(that.spinBlurMatrix, (matrix) => delta >= matrix[0] && delta <= matrix[1]);
            const blurVelocityY = blurMatrix ? (delta * blurMatrix[2]) / 2 : 0;

            assign(reelContainer.filters[0].velocity, {
              y: blurVelocityY,
            });
          },
          async onComplete() {
            if (spinSpeed !== SlotSpinSpeedType.Lightning) {
              audio.play(that.options.assets.soundReelStop);
              audio.stop(that.options.assets.soundReelSpin, reelSpinDefaultSound);
            }
            if (
              reelsValue.reelBonusSymbols[reelContainerIndex] > 0
              && spinSpeed !== SlotSpinSpeedType.Lightning
              && showIndividualWinlines
            ) {
              audio.play(that.options.assets.soundReelBonusShow);
            }

            reelContainer.filters[0].enabled = false;

            if (reelsValue.reelBonusWaits[reelContainerIndex + 1] > 0) {
              await slotState.reelBonus.hide();

              if (!that.isStopPhase) {
                slotState.reelBonus.positionToReel(that.getReelWindowSize(reelContainerIndex + 1));
                await slotState.reelBonus.show();
              }
            }
          },
        });

        reelTimeline.addLabel('stop', `-=${(this.spinDurationStopPush / 2) * reelContainerIndex}`);

        reelTimeline.to(reelContainer, {
          duration: reelDurationPush,
          pixi: {
            y: `-=${reelBackHeightOut}`,
          },
          async onComplete() {
            that.isSpinning = false;

            reelContainer.removeChildren(that.options.config.rows + 2);
            that.resetReelPosition(reelContainer);

            if (reelContainerIndex === that.reelsContainer.children.length - 1) {
              that.resetSymbolsDisabled();
              await slotState.reelBonus.hide();

              if (spinSpeed === SlotSpinSpeedType.Lightning) {
                audio.play(that.options.assets.soundReelsStop);
              }

              if (isBonusWon) {
                audio.play(that.options.assets.soundBonusWin);
              }

              if (isFree && isIncreasingFreeRoundMultiplier && reelsValue.reelDynamicMultiplierSymbols) {
                each(reelsValue.reelDynamicMultiplierSymbols, (reelDymanicMultiplierSymbol) => {
                  if (reelDymanicMultiplierSymbol) {
                    that.createCloneSymbol(reelDymanicMultiplierSymbol);
                  }
                });

                await slotState.reelsMultipliers.applyMultiplierSymbols(multiplierValue);
              }

              if (reelsValue.reelCollect) {
                await slotState.reelsCollect.start(reelsValue);
                winAmount += reelsValue.reelCollect.winAmount;
              }

              if (progress && progress.current) {
                let progressUnitValue = progress.current.unitValue;
                isProgressWon = !isNil(slotState.progress) && isNil(slotState.progress.unload) && !isNil(progress.unload);
                isProgressFreeRoundsWon = isProgressWon && slotState.progressBonusType === 'FreeRounds';
                isProgressPickPrizeWon = isProgressWon && slotState.progressBonusType.includes('PickPrize');

                if (isProgressWon || (progressUnitValue === 0 && reelsValue.reelProgressSymbolsCount)) {
                  progressUnitValue = slotState.progress.current.unitValue + reelsValue.reelProgressSymbolsCount;
                }

                /*
                This add property is only for testing without progress
                feature enabled on game.
                */
                if (progress.add) {
                  const progressUnitValueAdd = reelsValue.reelProgressSymbolsCount;
                  progressUnitValue += progressUnitValueAdd > 0 ? progressUnitValueAdd : -1;
                  if (progressUnitValue < 0) progressUnitValue = 0;
                }

                if (reelsValue.reelProgressSymbolsCount) {
                  that.disableSymbols();
                  slotState.symbolDetail.hide();
                }

                each(reelsValue.reelProgressSymbols, (reelProgressSymbol) => {
                  if (reelProgressSymbol) {
                    that.createCloneSymbol(reelProgressSymbol);
                  }
                });

                await slotState.reelsHeader.progress.progressTo(progressUnitValue);

                that.enableSymbols();

                if (isProgressWon) {
                  triggerEvent('ProgressBonusShow', {
                    bonus: progress.unload.bonus,
                  });
                }
              }

              if (isBonusFreeRoundsEnd && isProgressPickPrizeWon) {
                slotState.pendingBonusOutroScreen = {
                  winAmount: spinOptions.freeRoundWinAmount,
                };
              }

              /*
              Save last win for show on next spin
              if spin win animation is interrupted.
              */
              slotState.setPendingWin(winAmount);
              slotState.setLastRound({
                betAmount,
                isBonusWon,
                isFree,
                isFreeRoundsEnd,
                isFreeRoundsWon,
                isJackpotWon,
                isPickPrizeWon,
                isProgressFreeRoundsWon,
                isProgressPickPrizeWon,
                isProgressWon,
                isPromotion: slotState.isPromotion,
                winAmount,
                winGrades,
              });

              if (winAmount > 0) {
                const winlineMethod = async () => {
                  await that.showWinLines({
                    applyMultiplierOnWin,
                    isFree,
                    isFreeRoundsEnd,
                    isFreeRoundsWon,
                    isSyncWinAmount,
                    multiplierValue,
                    showIndividualWinlines,
                    winAmount,
                    winLines,
                  });
                };

                /*
                Do not allow win animation to be stopped
                if we have win grading pending.
                */
                if (isSyncWinAmount) {
                  await winlineMethod();
                } else {
                  winlineMethod();
                }
              } else if (isBonusWon) {
                that.showOnlyBonusSymbols();
              }

              const isFreeRoundsWonInBaseGame = (isFreeRoundsWon || isProgressFreeRoundsWon) && (!slotState.availableFreeRounds || slotState.isPromotion);

              if (isFreeRoundsWonInBaseGame || (!slotState.isPromotion && isFreeRoundsEnd)) {
                slotState.toggleSoundAmbient();
              }

              /*
              Case of ending free rounds inside free rounds
              where multiplier value changes (example: from 10 to 2).
              */
              if (isFree && !isFreeRoundsWon && !isIncreasingFreeRoundMultiplier && slotState.multiplierValue !== spinOptions.multiplierValue) {
                slotState.reelsMultipliers.updateMultiplier(spinOptions.multiplierValue);
              }

              slotState.updateAfterSpin(spinOptions);

              triggerEvent('ReelsEnded', {
                isFree,
                isFreeRoundsWon,
                isFreeRoundsEnd,
                progressSymbolsWon: reelsValue.reelProgressSymbolsCount,
                winAmount,
              });

              resolve(true);
            }
          },
        });

        reelsTimeline.add(reelTimeline, reelTimelineDelay);
      });

      this.isStopPhase = false;
      this.reelsTimeline = reelsTimeline;
      this.reelsTimeline.addLabel('start', 0);
      this.reelsTimeline.smoothChildTiming = true;
      this.reelsTimeline.play();
    });
  }

  async stop() {
    if (this.reelsTimeline && this.reelsTimeline.isActive()) {
      this.isStopPhase = true;
      this.reelsTimeline.getChildren(false, false).forEach((reelTimeline) => {
        if (reelTimeline.progress() < 1) {
          reelTimeline.getChildren()[2].duration(this.spinDurationStopPush);
          reelTimeline.seek('stop', false);
        }
      });
    }

    await this.stopWinLines();
  }

  async showWinLines(winLineParams) {
    let multiplierApplyCount = 0;

    if (winLineParams.applyMultiplierOnWin && winLineParams.multiplierValue > 1) {
      multiplierApplyCount += 1;
    }

    assign(winLineParams, {
      multiplierApplyCount,
    });

    if (slotState.isPromotionStopped) slotState.reelsMultipliers.hideMultiplier();

    await this.winLines.showWinLines(winLineParams);
  }

  async stopWinLines() {
    return this.winLines.stopWinLines();
  }

  updateSymbols(reelsValue, isSymbolDisabled, wildMultiplier) {
    const reelWidth = (slotState.reelBackground.width - (this.reelPadding[0] * 2)) / this.options.config.reels;
    const reelRowHeight = (slotState.reelBackground.height - (this.reelPadding[1] * 2)) / this.options.config.rows;

    reelsValue.reelWindowStack.forEach((reel, reelIndex) => {
      const reelContainer = this.reelsContainer.children[reelIndex];
      const reelStackedSymbols = reel.length - reelsValue.reelWindow[reelIndex].length + (this.options.config.rows - 2);

      reel.forEach((symbolValue, rowIndex) => {
        let symbolNumber;

        if (reelsValue.reelCollect) {
          const rowIndexWindow = reelStackedSymbols - rowIndex;

          const cashSymbol = find(reelsValue.reelCollect.cashSymbolReelPosition, (n) => n[1] === reelIndex && n[2] === rowIndexWindow);
          if (cashSymbol) {
            [symbolNumber] = cashSymbol;
          }

          const multiplierSymbol = find(reelsValue.reelCollect.multiplierSymbolReelPosition, (n) => n[1] === reelIndex && n[2] === rowIndexWindow);
          if (multiplierSymbol) {
            [symbolNumber] = multiplierSymbol;
          }
        }

        /*
        Show wild multipliers if available.
        */
        if (wildMultiplier && wildMultiplier.symbolReelPosition && symbolValue === 0) {
          const rowIndexWindow = reelStackedSymbols - rowIndex;
          const wildMultiplierSymbol = find(wildMultiplier.symbolReelPosition, (n) => n[1] === reelIndex && n[2] === rowIndexWindow);
          if (wildMultiplierSymbol) {
            [symbolNumber] = wildMultiplierSymbol;
          }
        }

        const symbol = new SlotSymbol(symbolValue, isSymbolDisabled, symbolNumber);

        symbol.scaleTo(reelWidth, reelRowHeight);
        symbol.positionTo(-((rowIndex * reelRowHeight) + (reelRowHeight / 2) - this.reelPadding[1]));

        reelContainer.addChildAt(symbol.container, 0);
      });
    });
  }

  disableSymbols() {
    this.reelsContainer.children.forEach((reel) => {
      reel.children.forEach((symbol) => {
        symbol.$ref.setEnabled(false);
      });
    });
  }

  enableSymbols() {
    this.reelsContainer.children.forEach((reel, reelIndex) => {
      reel.children.forEach((symbol, rowIndex) => {
        const isDisabledBySetting = this.isSymbolDisabledBySetting(reelIndex, rowIndex);

        symbol.$ref.setEnabled(!isDisabledBySetting);
      });
    });
  }

  showOnlyBonusSymbols() {
    this.reelsContainer.children.forEach((reelContainer) => {
      reelContainer.children.forEach((symbolContainer) => {
        assign(symbolContainer, {
          alpha: symbolContainer.$ref.symbolValue === slotState.options.config.bonusSymbol ? 1 : 0.35,
        });
      });
    });
  }
}
