import animate from 'gsap';
import { flatten, each, difference, union, isFunction, isEmpty, assign } from 'lodash';
import { Container } from '../pixi';
import { SlotWinlineAmount } from './SlotWinlineAmount';
import { slotState } from './SlotState';

export class SlotBaseWinlines {
  constructor(container, reelsContainer, position, isWithSymbol) {
    this.container = container;
    this.isMultilineLimit = 1;
    this.isEmptyLimit = 0;
    this.isWithSymbol = isWithSymbol;
    this.options = slotState.options;
    this.position = position;
    this.reelsContainer = reelsContainer;
    this.symbolSegments = undefined;
    this.winlineDelayRepeat = 1.5;
    this.winlineShowDuration = 0.1;
    this.winlineShowAllDuration = [1.0, 3.0, 4.0];
    this.winlinesContainer = undefined;
    this.winlinesTimeline = undefined;
    this.winlinesLoopTimeline = undefined;
  }

  getWinlineAnimateAllDuration(multiplierApplyCount) {
    return [this.winlineShowAllDuration[multiplierApplyCount], this.winlineDelayRepeat];
  }

  start() {
    this.winlinesAmount = new SlotWinlineAmount({
      options: this.options,
      isWithBackground: true,
      scale: 0.4,
    });

    this.winlinesContainer = new Container();
    this.winlinesContainer.x = this.position.x;
    this.winlinesContainer.y = this.position.y;

    this.container.addChild(this.winlinesContainer);
  }

  stop() {
    if (this.winlinesTimeline) {
      this.winlinesTimeline.kill();
      this.winlinesTimeline = undefined;

      if (this.winlinesLoopTimeline) {
        this.winlinesLoopTimeline.kill();
        this.winlinesLoopTimeline = undefined;
      }
    }
  }

  addStartAnimation(winLines, multiplierApplyCount, resolve, onStart) {
    const that = this;

    this.symbolSegments = this.getSymbolsSegments(winLines);

    this.winlinesTimeline = animate.timeline({
      delay: this.winlineShowDuration,
      onComplete() {
        resolve(true);
      },
    });

    this.winlinesTimeline.to(this.symbolSegments.hitAll, {
      duration: this.winlineShowAllDuration[multiplierApplyCount],
      pixi: {
        alpha: 1.0,
      },
      onStart() {
        that.symbolSegments.lostAll.forEach((symbol) => {
          assign(symbol, {
            alpha: 0.35,
          });
        });

        this.targets().forEach((symbol) => {
          if (that.isWithSymbol) {
            symbol.$ref.showHit();
          }
        });

        if (isFunction(onStart)) {
          onStart();
        }
      },
    });

    this.winlinesLoopTimeline = animate.timeline({
      repeat: -1,
      repeatRefresh: true,
      onRepeat() {
        if (this.repeat() === 1) {
          resolve(true);
        }
      },
    });
  }

  addWinlineEndAnimation(winLineIndex) {
    const that = this;
    const hasMiss = !isEmpty(this.symbolSegments.miss[winLineIndex]);
    const hasLost = !isEmpty(this.symbolSegments.lost[winLineIndex]);

    if (hasMiss) {
      this.winlinesLoopTimeline.to(this.symbolSegments.miss[winLineIndex], {
        duration: this.winlineShowDuration,
        pixi: {
          alpha: 0.35,
        },
        onComplete() {
          this.targets().forEach((symbol) => {
            if (that.isWithSymbol) {
              symbol.$ref.hideHit();
            }
          });
        },
      }, '<');
    }

    if (hasLost) {
      this.winlinesLoopTimeline.to(this.symbolSegments.lost[winLineIndex], {
        duration: this.winlineShowDuration,
        pixi: {
          alpha: 0.35,
        },
        onComplete() {
          this.targets().forEach((symbol) => {
            if (that.isWithSymbol) {
              symbol.$ref.hideHit();
            }
          });
        },
      }, '<');
    }
  }

  addEndAnimation() {
    this.winlinesTimeline.add(this.winlinesLoopTimeline);
  }

  getSymbolsSegments(winLines) {
    const symbolsHit = [];
    const symbolsLost = [];
    const symbolsMiss = [];
    const symbolsLine = [];
    const isBonusWon = slotState.lastRound && slotState.lastRound.isBonusWon;
    const { bonusSymbol } = slotState.options.config;

    winLines.forEach((winLine, winLineIndex) => {
      symbolsHit[winLineIndex] = [];
      symbolsLost[winLineIndex] = [];
      symbolsMiss[winLineIndex] = [];
      symbolsLine[winLineIndex] = [];

      winLine.payline.forEach((payline, reelIndex) => {
        const symbols = this.reelsContainer.children[reelIndex].children;

        symbols.forEach((symbol, symbolIndex) => {
          if (symbolIndex > 0 && symbolIndex <= this.options.config.rows) {
            const isNotBonusSymbol = !isBonusWon || (isBonusWon && symbol.$ref.symbolValue !== bonusSymbol);

            if (symbolIndex - 1 === payline) {
              if (reelIndex < winLine.symbolHits) {
                symbolsHit[winLineIndex].push(symbol);
              } else if (isNotBonusSymbol) {
                symbolsMiss[winLineIndex].push(symbol);
              }
              symbolsLine[winLineIndex].push(symbol);
            } else if (isNotBonusSymbol) {
              symbolsLost[winLineIndex].push(symbol);
            }
          }
        });
      });
    });

    const symbolsHitAll = flatten(symbolsHit);
    const symbolsMissAll = union(flatten(symbolsLost), flatten(symbolsMiss));
    const symbolsLostAll = difference(symbolsMissAll, symbolsHitAll);

    return {
      line: symbolsLine,
      hit: symbolsHit,
      miss: symbolsMiss,
      lost: symbolsLost,
      hitAll: symbolsHitAll,
      missAll: symbolsMissAll,
      lostAll: symbolsLostAll,
    };
  }

  getMiddlePoint() {
    const symbolCenterY = this.options.config.rows / 2;
    const symbolCenterX = this.options.config.reels / 2;

    return {
      x: this.options.reelPadding[0] + (symbolCenterX * slotState.reels.reelWidth),
      y: this.options.reelPadding[1] + (symbolCenterY * slotState.reels.reelRowHeight),
    };
  }

  getPoints(winLines, winLineIndex) {
    const positions = [];
    this.symbolSegments.line[winLineIndex].forEach((symbol, symbolIndex) => {
      const symbolReelIndex = winLines[winLineIndex].payline[symbolIndex];

      positions.push({
        x: this.options.reelPadding[0] + (symbolIndex * slotState.reels.reelWidth) + (slotState.reels.reelWidth / 2),
        y: this.options.reelPadding[1] + (symbolReelIndex * slotState.reels.reelRowHeight) + (slotState.reels.reelRowHeight / 2),
      });
    });

    return positions;
  }

  isMultiline(winLines) {
    return winLines.length > this.isMultilineLimit;
  }

  isEmptyWinlines(winLines) {
    return winLines.length <= this.isEmptyLimit;
  }

  enableSymbols(isReversed, skipSymbols = []) {
    each(slotState.reels.reelsContainer.children, (reelContainer, reelIndex) => {
      each(reelContainer.children, (symbolContainer, rowIndex) => {
        if (skipSymbols.indexOf(`${reelIndex}-${rowIndex - 1}`) > -1) {
          assign(symbolContainer, {
            alpha: 1,
          });
        } else {
          assign(symbolContainer, {
            alpha: isReversed ? 0.35 : 1,
          });
        }
      });
    });
  }

  disableSymbols(skipSymbols) {
    this.enableSymbols(true, skipSymbols);
  }
}
