import animate, { Power1 } from 'gsap';
import { clone, defaultTo, find, map, reverse } from 'lodash';
import { AnimatedSprite, Container, Sprite, Texture } from '../pixi';
import { registerEventListener } from '../utility/Utility';
import { SlotNumber } from './SlotNumber';
import { slotState } from './SlotState';

export class SlotSymbolDetail {
  constructor() {
    this.options = slotState.options;
    this.contentScale = 0.8;
    this.scalePaytable = 0.2;
    this.overlay = new Container();
    this.container = new Container();
    this.content = new Container();
    this.body = new Container();
    this.symbol = new Container();
    this.paytable = new Container();
    this.overlayBackground = undefined;
    this.contentBackground = undefined;
    this.animation = undefined;

    this.container.visible = false;
    this.container.addChild(this.overlay);
    this.container.addChild(this.content);

    this.createOverlay();
    this.createContent();
    this.setListeners();
    this.setActions();
  }

  createOverlay() {
    this.overlayBackground = new Sprite(Texture.WHITE);
    this.overlayBackground.tint = 0x000000;

    this.overlay.alpha = 0.8;
  }

  createContent() {
    const asset = this.options.assets.symbolDetailBackground;

    if (asset) {
      this.contentBackground = new Sprite(asset.resource);
    } else {
      this.contentBackground = new Sprite(Texture.WHITE);
      this.contentBackground.tint = 0x000000;
      this.contentBackground.alpha = 0.5;
    }

    this.contentBackground.anchor.set(0.5);
  }

  createPaytable(symbolIndex, symbolValue, betAmount) {
    this.paytable.removeChildren();

    const paytable = find(this.options.config.paytable, { symbol: symbolValue });

    if (paytable) {
      let hitAdded = 0;
      let hitValueScale = false;
      const paytableHitsCount = paytable.hits.length;

      reverse(clone(paytable.hits)).forEach((hitValue, hitIndex) => {
        const hitCount = paytableHitsCount - hitIndex;
        const hitAmount = (hitValue * betAmount) / slotState.activePaylines;
        if (hitAmount > 0) {
          const hitCountNumber = new SlotNumber(hitCount, 'x', 0);
          const hitValueNumber = new SlotNumber(hitAmount);
          const hitValueNumberLenght = hitValueNumber.amountText.length;

          if (!hitValueScale) {
            if (hitValueNumberLenght > 12) {
              this.scalePaytable = 0.12;
              hitValueScale = true;
            } else if (hitValueNumberLenght > 10) {
              this.scalePaytable = 0.15;
              hitValueScale = true;
            } else if (hitValueNumberLenght > 8) {
              this.scalePaytable = 0.17;
              hitValueScale = true;
            }
          }

          hitValueNumber.container.y = hitValueNumber.container.height * hitAdded * 1.2;
          hitValueNumber.container.x = hitCountNumber.container.width;
          hitCountNumber.container.scale.set(0.7);
          hitCountNumber.container.y = hitValueNumber.container.y + ((hitValueNumber.container.height - hitCountNumber.container.height) * 0.7);
          hitAdded += 1;

          this.paytable.addChild(hitCountNumber.container);
          this.paytable.addChild(hitValueNumber.container);
        }
      });
    }
  }

  createSymbol(symbolIndex) {
    let symbol;
    const assetSymbol = this.options.assets.symbols[symbolIndex];
    const textures = defaultTo(assetSymbol.animation, map(assetSymbol.assets, (asset) => asset.resource));

    if (textures.length > 1) {
      symbol = new AnimatedSprite(textures);
      symbol.animationSpeed = 0.4;
      symbol.loop = false;
      symbol.play();
    } else {
      symbol = new Sprite(textures[0]);
    }

    this.symbol.removeChildren();
    this.symbol.addChild(symbol);
  }

  setActions() {
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
  }

  setListeners() {
    const source = 'SlotSymbolDetail';

    registerEventListener('SymbolDetailShow', (event) => {
      this.show(event.detail);
    }, source);

    registerEventListener('AppPointerTap', () => {
      this.hide();
    }, source);

    registerEventListener('ReelsStarting', () => {
      this.hide();
    }, source);
  }

  show({ symbolIndex, symbolValue, symbolPosition }) {
    const that = this;
    const size = slotState.reels.getSize();

    this.overlay.addChild(this.overlayBackground);
    this.content.addChild(this.contentBackground);

    const backgroundSize = this.contentBackground.getBounds();

    this.overlayBackground.width = slotState.reelBackground.container.width;
    this.overlayBackground.height = slotState.reelBackground.container.height;
    this.contentBackground.scale.set((size.width / backgroundSize.width) * this.contentScale);

    this.container.x = 0;
    this.container.y = slotState.reelBackground.container.y;

    const contentPadding = this.content.height * 0.05;

    this.createSymbol(symbolIndex);
    this.body.addChild(this.symbol);
    this.symbol.x = 0;
    this.symbol.y = (this.content.height / 2) - (slotState.reels.reelRowHeight / 2);

    this.createPaytable(symbolIndex, symbolValue, slotState.betAmount);
    this.paytable.scale.set(this.scalePaytable);
    this.body.addChild(this.paytable);
    this.paytable.x = this.symbol.x + slotState.reels.reelWidth + (contentPadding * 2);
    this.paytable.y = (this.content.height / 2) - (this.paytable.height / 2);

    this.body.x = -this.body.width / 2;
    this.body.y = -this.content.height / 2;

    this.content.addChild(this.body);
    this.content.scale.set(0.1);
    this.content.alpha = 0.25;

    this.animation = animate.fromTo(this.content, {
      pixi: {
        alpha: 0.25,
        scale: 0.1,
        x: symbolPosition.x,
        y: symbolPosition.y,
      },
    }, {
      duration: 0.2,
      ease: Power1.easeOut,
      pixi: {
        alpha: 1,
        scale: 1,
        x: this.overlay.width / 2,
        y: this.overlay.height / 2,
      },
      onStart() {
        that.container.visible = true;
      },
      onReverseComplete() {
        that.container.visible = false;
        that.overlay.removeChildren();
        that.content.removeChildren();
        that.body.removeChildren();
        that.content.scale.set(1);
        that.contentBackground.scale.set(1);
        that.overlayBackground.scale.set(1);
      },
    });
  }

  hide() {
    if (this.container.visible) {
      this.animation.reverse();
    }
  }
}
