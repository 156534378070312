import { registerEventListener } from '@/utility/Utility';

export class Boost {
  constructor({
    authorizationToken,
    boostTenantId,
    locale = 'en',
    padding = 24,
    profileId,
  }) {
    this.authorizationToken = authorizationToken;
    this.boostBadge = null;
    this.boostBadgeId = 'boostBadgeId';
    this.boostContainer = null;
    this.boostContainerId = 'boostContainerId';
    this.boostContainerWidth = 360;
    this.badgeSize = 36;
    this.boostTenantId = boostTenantId;
    this.language = locale;
    this.padding = padding;
    this.profileId = profileId;
    this.setup();
    this.setListeners();
  }

  createBadgeContainer() {
    this.boostBadge = document.createElement('div');
    this.boostBadge.id = this.boostBadgeId;
    this.boostBadge.style.position = 'fixed';
    this.boostBadge.style.userSelect = 'none';
    this.boostBadge.style.zIndex = 100;
    this.setBadgePosition();

    document.body.append(this.boostBadge);
  }

  setBadgePosition() {
    const canvas = document.getElementsByTagName('canvas')[0];
    const canvasRect = canvas.getBoundingClientRect();
    const scale = canvasRect.width / this.boostContainerWidth;
    const elementOffset = ((this.badgeSize * scale) - this.badgeSize) / 2;

    this.boostBadge.style.transform = `scale(${scale})`;
    this.boostBadge.style.left = `${canvasRect.left + elementOffset + ((this.padding / 3) * scale)}px`;
    this.boostBadge.style.top = `${canvasRect.top + elementOffset + ((this.padding / 3) * scale)}px`;
  }

  createModulesContainer() {
    this.boostContainer = document.createElement('div');
    this.boostContainer.id = this.boostContainerId;
    this.boostContainer.style.position = 'fixed';
    this.boostContainer.style.zIndex = 100;
    this.setModulesContainerPosition();

    document.body.append(this.boostContainer);
  }

  setModulesContainerPosition() {
    const canvas = document.getElementsByTagName('canvas')[0];
    const canvasRect = canvas.getBoundingClientRect();
    const scale = canvasRect.width / this.boostContainerWidth;

    this.boostContainer.style.height = `${canvasRect.height / scale}px`;
    this.boostContainer.style.width = `${this.boostContainerWidth}px`;
    this.boostContainer.style.left = `${canvasRect.left + window.scrollX}px`;
    this.boostContainer.style.top = `${canvasRect.top + window.scrollY}px`;
    this.boostContainer.style.transformOrigin = 'top left';
    this.boostContainer.style.transform = `scale(${scale})`;
  }

  configure() {
    window.NSoftBoost.configure({
      animation: {
        type: 'fade',
        direction: 'top-bottom',
      },
      authorizationToken: this.authorizationToken,
      badgeContainerId: this.boostBadgeId,
      badgeSize: this.badgeSize,
      environment: process.env.APP_BOOST_ENVIRONMENT,
      language: this.language,
      modulesContainerId: this.boostContainerId,
      profileId: this.profileId,
      tenantId: this.boostTenantId,
      widget: 'widgetSlots',
    });
  }

  hide() {
    this.boostBadge.style.visibility = 'hidden';
  }

  show() {
    this.boostBadge.style.visibility = 'visible';
  }

  enable() {
    this.boostBadge.style.pointerEvents = 'auto';
    this.boostBadge.style.opacity = '1';
  }

  disable(opacity = '0.8') {
    this.boostBadge.style.pointerEvents = 'none';
    this.boostBadge.style.opacity = opacity;
  }

  setup() {
    this.createBadgeContainer();
    this.createModulesContainer();
    this.configure();
    this.hide();
  }

  setListeners() {
    registerEventListener('ShowBoostBadge', () => {
      this.show();
    });
    registerEventListener('HideBoostBadge', () => {
      this.hide();
    });
  }
}
