import { Container } from '../pixi';
import { Tabs } from './shared/Tabs';
import { slotState } from './SlotState';
import { SlotDialogMenuSettings } from './SlotDialogMenuSettings';
import { SlotDialogMenuPaytable } from './SlotDialogMenuPaytable';
import { SlotDialogMenuInfo } from './SlotDialogMenuInfo';
import { SlotDialogMenuHistory } from './SlotDialogMenuHistory';

export class SlotDialogMenu {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.tabs = undefined;

    this.setup();
  }

  setup() {
    const contentPaytable = new SlotDialogMenuPaytable();
    const contentInfo = new SlotDialogMenuInfo();
    const contentHistory = new SlotDialogMenuHistory();

    this.tabs = new Tabs({
      items: [
        {
          iconAsset: this.options.assets.iconSettings,
          content: new SlotDialogMenuSettings(),
          title: slotState.options.translations.dialogSettingsTitle,
        },
        {
          iconAsset: this.options.assets.iconPaytable,
          content: contentPaytable,
          title: slotState.options.translations.dialogMenuPaytableTitle,
          onHide: contentPaytable.scrollToTop.bind(contentPaytable),
        },
        {
          iconAsset: this.options.assets.iconInfo,
          content: contentInfo,
          title: slotState.options.translations.dialogMenuInfoTitle,
          onShow: contentInfo.show.bind(contentInfo),
          onHide: contentInfo.hide.bind(contentInfo),
        },
        {
          iconAsset: this.options.assets.iconHistory,
          content: contentHistory,
          title: slotState.options.translations.dialogMenuHistoryTitle,
          onShow: contentHistory.onShow.bind(contentHistory),
        },
      ],
      playTapSound: () => { slotState.playTapSound(); },
      onChange: (item) => {
        slotState.dialogMenu.updateTitle(item.title);
      },
      colorActive: this.options.colors.highlightBrighter,
      padding: this.options.uiDialogPadding,
    });

    this.container.addChild(this.tabs.container);
  }

  setPosition(data) {
    this.tabs.setPosition(data);
  }

  show(tabIndex = 0) {
    this.tabs.showTab(tabIndex);
  }

  hide() {
    this.tabs.hideActiveTab();
  }
}
