import { map, isEqual, template } from 'lodash';
import { Container, BitmapText, AnimatedSprite, Sprite, Texture, Text } from '@/pixi';
import { ScrollBox } from './shared/ScrollBox';
import { slotState } from './SlotState';

export class SlotDialogMenuPaytable {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.scrollBox = undefined;
    this.items = [];
    this.maxWidth = slotState.options.calcWidth - slotState.options.uiDialogPadding * 2;
    this.marginBetweenElements = 50;
    this.marginBetweenSymbolAndText = 25;
    this.wildSymbolObject = slotState.options.config.paytable.find((item) => item.symbol === slotState.options.config.wildSymbol);
    this.wildSymbolContainerName = 'wildSymbol';
    this.mainSymbolsContainerName = 'mainSymbols';

    this.setup();
  }

  get isTrashSymbolInBaseGame() {
    const isFreeRoundsBonus = slotState.bonusType === 'FreeRounds' || slotState.progressBonusType === 'FreeRounds';

    return !isFreeRoundsBonus || (isFreeRoundsBonus && !slotState.isIncreasingFreeRoundMultiplier);
  }

  setup() {
    const { config, progress } = this.options;

    // Bonus symbol
    if (config.bonus) {
      const { bonusType, dynamicPrizeCount, prizeCount, dynamicDistribution, multipliers, levels } = config.bonus;
      let description;
      let numOfSymbolsList;

      if (bonusType === 'FreeRounds') {
        if (dynamicPrizeCount) {
          description = this.options.translations.paytableBonusFreeRoundsDynamicPrizeCount;
          numOfSymbolsList = map(dynamicPrizeCount, (spins, numOfSymbols) => ({
            numOfSymbols,
            text: `${spins} ${this.options.translations.paytableFreeSpinsText.toLowerCase()}`,
            useSeparator: true,
          })).reverse();
        } else if (!dynamicDistribution) {
          description = this.options.translations.paytableBonusFreeRoundsMultiplier.replace('${prizeCount}', prizeCount);
          numOfSymbolsList = map(multipliers, (multiplier, numOfSymbols) => ({
            numOfSymbols,
            text: `x${multiplier} ${this.options.translations.paytableMultiplierText.toLowerCase()}`,
            useSeparator: true,
          })).reverse();
        } else {
          description = this.options.translations.paytableBonusFreeRoundsStaticPrizeCount.replace('${prizeCount}', prizeCount);
        }
      } else if (bonusType === 'PickPrize') {
        description = this.options.translations.paytableBonusPickPrize;
      } else if (bonusType === 'PickPrizeLevels') {
        description = this.options.translations.paytableBonusPickPrizeLevels.replace('${numberOfLevels}', levels.length);
      }

      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.paytableBonusSymbolTitle,
        symbol: this.getSymbolAsset(config.bonusSymbol),
        description,
        numOfSymbolsList,
      }));
    }

    // Progress symbol
    if (progress) {
      const { bonusType, prizeCount, levelsState, dynamicMultiplierSymbols } = progress.current.bonus || progress.unload.bonus;

      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.paytableProgressSymbolTitle,
        symbol: this.getSymbolAsset(config.symbolsListProgress[0]),
        description: this.options.translations[`paytableProgress${bonusType}`].replace('${prizeCount}', levelsState?.length || prizeCount),
      }));

      // Multiplier symbol in progress free rounds
      if (dynamicMultiplierSymbols?.length) {
        this.items.push(this.createSymbolWithDescription({
          title: this.options.translations.payTableMultiplierSymbolTitle,
          symbol: this.getSymbolAsset(dynamicMultiplierSymbols[0]),
          description: this.options.translations.paytableProgressDynamicMultiplierSymbolDescription,
        }));
      }
    }

    // Collect symbols
    if (config.collect) {
      const { cashRange, cashSymbol, collectSymbol, multiplierRange, multiplierSymbol } = config.collect;
      const cashSymbolDescription = this.options.translations.paytableCashSymbolDescription
        .replace('${minDistributionValue}', cashRange[0]).replace('${maxDistributionValue}', cashRange[1]);

      // Cash symbol
      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.paytableCashSymbolTitle,
        symbol: this.getSymbolAsset(cashSymbol),
        description: cashSymbolDescription,
      }));

      // Collect symbol
      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.paytableCollectSymbolTitle,
        symbol: this.getSymbolAsset(collectSymbol),
        description: this.options.translations.paytableCollectSymbolDescription,
      }));

      // Multiplier symbol
      if (!isEqual(multiplierRange, [0, 0])) {
        this.items.push(this.createSymbolWithDescription({
          title: this.options.translations.payTableMultiplierSymbolTitle,
          symbol: this.getSymbolAsset(multiplierSymbol),
          description: this.options.translations.paytableMultiplierSymbolDescription,
        }));
      }
    }

    // Multiplier symbol
    if (config.isIncreasingFreeRoundMultiplier) {
      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.payTableMultiplierSymbolTitle,
        symbol: this.getSymbolAsset(config.dynamicMultiplierSymbols[0]),
        description: this.options.translations.paytableDynamicMultiplierSymbolDescription,
      }));
    }

    // Trash symbol
    if (config.symbolsListTrash?.length) {
      this.items.push(this.createSymbolWithDescription({
        title: this.options.translations.paytableTrashSymbolTitle,
        symbol: this.getSymbolAsset(config.symbolsListTrash[0]),
        description: this.isTrashSymbolInBaseGame
          ? this.options.translations.paytableTrashSymbolBaseGameDescription
          : this.options.translations.paytableTrashSymbolDescription,
      }));
    }

    // Wild symbol
    if (this.wildSymbolObject) {
      this.items.push(this.createWildSymbol());
    }

    this.items.push(this.createLineSeparator());

    // Main symbols
    this.items.push(this.createMainSymbols());

    this.items.push(this.createLineSeparator());

    // Paylines
    this.items.push(new BitmapText(this.options.translations.paytablePaylinesTitle, {
      fontName: this.options.fontNameBold,
      fontSize: 48,
      tint: this.options.colors.text,
    }));
    this.items.push(this.createPaylines());

    this.scrollBox = new ScrollBox({
      items: this.items,
      itemsMargin: this.marginBetweenElements,
      padding: this.options.uiDialogPadding,
    });
    this.container.addChild(this.scrollBox.container);

    this.setListeners();
  }

  getSymbolAsset(value) {
    return this.options.assets.symbols.find((symbol) => symbol.value === value);
  }

  getHitValue(hit) {
    return slotState.getMoneyLabel((hit * slotState.betAmount) / slotState.activePaylines);
  }

  createSymbolWithDescription({ title, symbol, description, numOfSymbolsList, containerName }) {
    const container = new Container();

    if (containerName) {
      container.name = containerName;
    }

    const label = new BitmapText(title, {
      fontName: this.options.fontNameBold,
      fontSize: 48,
      tint: this.options.colors.text,
    });
    container.addChild(label);

    const symbolSprite = new AnimatedSprite(symbol.animation);
    // Set width to 20% of max width
    symbolSprite.scale.set((this.maxWidth / symbolSprite.width) / 5);
    symbolSprite.play();
    symbolSprite.animationSpeed = 0.4;
    container.addChild(symbolSprite);

    const descriptionText = new Text(description, {
      fontFamily: this.options.defaultFontFamily,
      fontSize: 40,
      fontWeight: 300,
      lineHeight: 48,
      fill: this.options.colors.text,
      wordWrap: true,
      wordWrapWidth: this.maxWidth - (symbolSprite.width + this.marginBetweenSymbolAndText),
    });
    container.addChild(descriptionText);

    let numOfSymbolsInfo;
    if (numOfSymbolsList) {
      numOfSymbolsInfo = this.createNumOfSymbolsInfo(numOfSymbolsList);
      container.addChild(numOfSymbolsInfo);
    }

    descriptionText.x = symbolSprite.width + this.marginBetweenSymbolAndText;

    const descriptionHeight = numOfSymbolsInfo ? descriptionText.height + this.marginBetweenSymbolAndText + numOfSymbolsInfo.height : descriptionText.height;
    if (descriptionHeight >= symbolSprite.height) {
      symbolSprite.y = label.height + this.marginBetweenSymbolAndText;
      descriptionText.y = label.height + this.marginBetweenSymbolAndText;
    } else {
      symbolSprite.y = label.height + this.marginBetweenSymbolAndText;
      descriptionText.y = symbolSprite.y + symbolSprite.height / 2 - descriptionText.height / 2;
    }

    if (numOfSymbolsInfo) {
      numOfSymbolsInfo.x = descriptionText.x;
      numOfSymbolsInfo.y = descriptionText.y + descriptionText.height + this.marginBetweenSymbolAndText;
    }

    return container;
  }

  createNumOfSymbolsInfo(numOfSymbolsList) {
    const container = new Container();
    const margin = 15;
    let lastPositionY = 0;

    numOfSymbolsList.forEach(({ numOfSymbols, text, useSeparator }) => {
      const row = new Container();
      const textStyle = {
        fontName: this.options.fontNameBold,
        fontSize: 36,
        tint: this.options.colors.text,
      };

      const left = new BitmapText(numOfSymbols, textStyle);
      left.alpha = 0.6;
      row.addChild(left);

      let separator;
      if (useSeparator) {
        separator = new BitmapText('-', textStyle);
        separator.x = left.width + margin / 2;
        row.addChild(separator);
      }

      const right = new BitmapText(text, textStyle);
      right.x = separator ? separator.x + separator.width + margin / 2 : left.width + margin;
      row.addChild(right);

      row.y = lastPositionY;
      lastPositionY = row.y + row.height;

      container.addChild(row);
    });

    return container;
  }

  createWildSymbol() {
    const numOfSymbolsList = [];

    this.wildSymbolObject.hits.forEach((hit, index) => {
      if (hit) {
        numOfSymbolsList.push({
          numOfSymbols: index + 1,
          text: this.getHitValue(hit),
        });
      }
    });

    const { multiplierRange } = this.options.config.wildMultiplier || {};
    const wildMultiplierDescription = template(this.options.translations.paytableWildMultiplierSymbolDescription)({
      minValue: multiplierRange?.[0] || 1,
      maxValue: multiplierRange?.[1],
    });

    return this.createSymbolWithDescription({
      title: this.options.translations.paytableWildSymbolTitle,
      symbol: this.getSymbolAsset(this.options.config.wildSymbol),
      description: multiplierRange ? wildMultiplierDescription : this.options.translations.paytableWildSymbolDescription,
      numOfSymbolsList: numOfSymbolsList.reverse(),
      containerName: this.wildSymbolContainerName,
    });
  }

  createLineSeparator() {
    const line = new Sprite(Texture.WHITE);

    line.width = this.maxWidth;
    line.height = 6;
    line.alpha = 0.1;

    return line;
  }

  createMainSymbols() {
    const container = new Container();
    const mainSymbols = this.options.config.paytable.filter(({ symbol }) => symbol !== this.options.config.wildSymbol);
    const marginBetweenRows = 15;

    container.name = this.mainSymbolsContainerName;

    mainSymbols.forEach(({ symbol, hits }, index) => {
      const symbolContainer = new Container();

      const symbolSprite = new AnimatedSprite(this.getSymbolAsset(symbol).animation);
      // Set width to 20% of max width
      symbolSprite.scale.set((this.maxWidth / symbolSprite.width) / 5);
      symbolSprite.play();
      symbolSprite.animationSpeed = 0.4;
      symbolContainer.addChild(symbolSprite);

      const numOfSymbolsList = [];
      hits.forEach((hit, i) => {
        if (hit) {
          numOfSymbolsList.push({
            numOfSymbols: i + 1,
            text: this.getHitValue(hit),
          });
        }
      });
      const numOfSymbolsInfo = this.createNumOfSymbolsInfo(numOfSymbolsList.reverse());
      numOfSymbolsInfo.x = symbolSprite.width + this.marginBetweenSymbolAndText;
      numOfSymbolsInfo.y = symbolSprite.height / 2 - numOfSymbolsInfo.height / 2;
      symbolContainer.addChild(numOfSymbolsInfo);

      symbolContainer.x = index % 2 === 0 ? 0 : this.maxWidth / 2;

      if (index >= 2) {
        const containerAbove = container.getChildAt(index - 2);
        symbolContainer.y = containerAbove.y + containerAbove.height + marginBetweenRows;
      }

      container.addChild(symbolContainer);
    });

    return container;
  }

  createPaylines() {
    const container = new Container();
    const { rows, paylineSet } = this.options.config;
    const paylines = paylineSet.paylines.slice(0, this.options.settings.paylinesNumber);
    const paylineContainersPerRow = 4;
    const paylineContainersInLastRow = paylines.length % paylineContainersPerRow;

    paylines.forEach((payline, paylineIndex) => {
      const paylineContainer = new Container();
      const boxes = new Container();
      let columnsPerRow = paylineContainersPerRow;

      // Change columnsPerRow for last row if row length is less then paylineContainersPerRow
      if ((paylines.length - paylineContainersInLastRow) < (paylineIndex + 1)) {
        columnsPerRow = paylineContainersInLastRow;
      }

      const label = new BitmapText(paylineIndex + 1, {
        fontName: this.options.fontNameBold,
        fontSize: 38,
        tint: this.options.colors.text,
      });

      for (let row = 0; row < rows; row++) {
        payline.forEach((highlightIndex, column) => {
          const square = new Sprite(Texture.WHITE);
          const squareSize = 24;
          const margin = 8;

          square.width = squareSize;
          square.height = squareSize;
          square.x = column * squareSize + margin * column;
          square.y = row * squareSize + margin * row;

          if (highlightIndex === row) {
            square.tint = this.options.colors.highlightBrighter;
          } else {
            square.alpha = 0.1;
          }

          boxes.addChild(square);
        });
      }

      label.x = boxes.width / 2 - label.width / 2;
      boxes.y = label.height;

      paylineContainer.addChild(label);
      paylineContainer.addChild(boxes);

      const spaceLeft = this.maxWidth - paylineContainer.width * columnsPerRow;
      const marginBeetWeenColumns = spaceLeft / (columnsPerRow + 1);
      paylineContainer.x = marginBeetWeenColumns + ((paylineContainer.width + marginBeetWeenColumns) * (paylineIndex % columnsPerRow));

      if (paylineIndex >= columnsPerRow) {
        const containerAbove = container.getChildAt(paylineIndex - columnsPerRow);
        paylineContainer.y = containerAbove.y + containerAbove.height + this.marginBetweenElements;
      }

      container.addChild(paylineContainer);
    });

    return container;
  }

  setListeners() {
    slotState.watch('betAmount', () => {
      const mainSymbolsIndex = this.items.findIndex((i) => i.name === this.mainSymbolsContainerName);

      if (this.wildSymbolObject) {
        const wildSymbolIndex = this.items.findIndex((i) => i.name === this.wildSymbolContainerName);

        // Update wild symbol
        const [oldContainer] = this.items.splice(wildSymbolIndex, 1, this.createWildSymbol());
        oldContainer.destroy();
      }

      // Update main symbols
      const [oldContainer] = this.items.splice(mainSymbolsIndex, 1, this.createMainSymbols());
      oldContainer.destroy();

      this.scrollBox.removeAllItems();
      this.scrollBox.addItems(this.items);
    });
  }

  setPosition() {
    const size = this.options.size();
    const scale = slotState.container.scale.y;
    const height = size.height / scale;
    const dialogHeadHeight = slotState.dialogMenu.getFullHeadHeight();

    this.scrollBox.setDimensions({
      height: height - dialogHeadHeight,
    });
  }

  scrollToTop() {
    this.scrollBox.scrollToTop();
  }
}
