import { triggerEvent } from '@/utility/Utility';
import { DialogContentBetAmount } from '@/components/shared/DialogContentBetAmount';
import { SlotDialog } from './SlotDialog';
import { slotState } from './SlotState';

export class SlotDialogBetAmount extends SlotDialog {
  constructor() {
    const content = new DialogContentBetAmount({
      betAmounts: slotState.options.settings.predefinedBetAmounts,
      getActiveBetAmount: () => slotState.betAmount,
      getBalanceAmount: () => slotState.balanceAmount,
      onChange: (value) => {
        triggerEvent('BetAmountChanged', { value });
      },
      formatBetAmount: (value) => slotState.getMoneyLabel(value),
      playTapSound: () => { slotState.playTapSound(); },
      fontName: slotState.options.fontNameNormal,
      betAmountColor: slotState.options.colors.textDisabled,
      betAmountColorActive: slotState.options.colors.highlightBrighter,
      padding: slotState.options.uiDialogPadding,
    });

    super({
      title: slotState.options.translations.dialogBetAmountTitle,
      content,
      isFluid: true,
    });
  }
}
