import animate, { Power2 } from 'gsap';
import { includes, toLower, isNil } from 'lodash';
import { Container, Sprite, Text, BitmapText, AnimatedSprite } from '@/pixi';
import { triggerEvent, isSpriteVideo } from '@/utility/Utility';
import { SlotNumber } from './SlotNumber';
import { SlotNumberCurrency } from './SlotNumberCurrency';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';

export class SlotBonusGameIntroOutroScreen {
  constructor({
    backgroundAsset,
    screenShowSoundAsset,
    number,
    numberIsMoney = false,
    textTop,
    textTopStyle,
    textBottom,
    textBottomStyle,
    type = false,
    gameTitleAsset,
    onClose,
    autoClose = false,
    disableOpenAnimation = false,
    disableCloseAnimation = false,
  }) {
    this.backgroundAsset = backgroundAsset;
    this.screenShowSoundAsset = screenShowSoundAsset;
    this.number = number;
    this.numberIsMoney = numberIsMoney;
    this.textTop = textTop;
    this.textTopStyle = textTopStyle;
    this.textBottom = textBottom;
    this.textBottomStyle = textBottomStyle;
    this.type = type;
    this.gameTitleAsset = gameTitleAsset;
    this.onClose = onClose;
    this.autoClose = autoClose;
    this.disableOpenAnimation = disableOpenAnimation;
    this.disableCloseAnimation = disableCloseAnimation;

    this.container = new Container();
    this.background = undefined;
    this.slotNumberContainer = undefined;
    this.slotNumber = undefined;
    this.slotNumberCurrency = undefined;
    this.labelTop = undefined;
    this.labelBottom = undefined;
    this.gameTitle = undefined;
    this.pressAnywhereLabel = undefined;
    this.timeline = animate.timeline();

    this.setup();
    this.setActions();
  }

  setup() {
    const defaultTextStyle = {
      fontFamily: slotState.options.defaultFontFamily,
      fill: slotState.options.colors.text,
      fontSize: 100,
      trim: true,
    };
    const pressAnywhereLabelText = `${slotState.options.translations.bonusGamePressAnywhereLabel.toUpperCase()}`;
    const pressAnywhereLabelTextStyle = {
      fontName: slotState.options.fontNameBold,
      tint: slotState.options.colors.text,
      fontSize: 44,
    };

    this.background = new Sprite(this.backgroundAsset.resource);

    this.background.anchor.set(0.5);

    this.container.addChild(this.background);

    if (this.gameTitleAsset) {
      this.gameTitle = new AnimatedSprite(this.gameTitleAsset.resource);
      this.gameTitle.anchor.set(0.5);
      this.gameTitle.play();
      // Set full width
      this.gameTitle.scale.set(slotState.options.calcWidth / this.gameTitle.width);
      this.container.addChild(this.gameTitle);
    }

    if (this.number !== undefined) {
      const numberDecimals = this.numberIsMoney ? 2 : 0;
      const defaultScale = this.numberIsMoney ? 0.5 : 0.75;

      this.slotNumberContainer = new Container();
      this.slotNumber = new SlotNumber(this.number, undefined, numberDecimals);
      // Set defaultScale, or 90% screen width if number is too wide
      const slotNumberScale = slotState.options.calcWidth / this.slotNumber.container.width;
      this.slotNumber.scale(slotNumberScale < 0.5 ? slotNumberScale * 0.9 : defaultScale);
      this.slotNumberContainer.addChild(this.slotNumber.container);

      if (this.numberIsMoney && slotState.options.currencyDisplayEnabled && slotState.areCurrencyTexturesAvailable) {
        this.slotNumberCurrency = new SlotNumberCurrency();
        this.slotNumberCurrency.setScale(0.5);
        this.slotNumberContainer.addChild(this.slotNumberCurrency.container);
      }

      this.container.addChild(this.slotNumberContainer);

      if (this.textTop) {
        const textTopStyle = {
          ...defaultTextStyle,
          ...this.textTopStyle,
        };

        this.labelTop = new Text(this.textTop, textTopStyle);
        this.labelTop.anchor.set(0.5, 1);
        const labelTopScale = slotState.options.calcWidth / this.labelTop.width;
        // If text is too wide scale it to 90% of screen
        if (labelTopScale <= 1) {
          this.labelTop.scale.set(labelTopScale * 0.9);
        }
        this.container.addChild(this.labelTop);
      }

      if (this.textBottom) {
        const textBottomStyle = {
          ...defaultTextStyle,
          ...this.textBottomStyle,
        };

        this.labelBottom = new Text(this.textBottom, textBottomStyle);
        this.labelBottom.anchor.set(0.5, 0);
        const labelBottomScale = slotState.options.calcWidth / this.labelBottom.width;
        // If text is too wide scale it to 90% of screen
        if (labelBottomScale <= 1) {
          this.labelBottom.scale.set(labelBottomScale * 0.9);
        }
        this.container.addChild(this.labelBottom);
      }
    }

    this.pressAnywhereLabel = new BitmapText(pressAnywhereLabelText, pressAnywhereLabelTextStyle);
    this.pressAnywhereLabel.anchor.set(0.5);
    this.container.addChild(this.pressAnywhereLabel);
  }

  setActions() {
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
    this.container.on('pointertap', () => {
      slotState.playTapSound();
      this.hide();

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    });

    if (this.autoClose) {
      this.timeout = setTimeout(this.hide.bind(this), 2000);
    }
  }

  setPosition() {
    const size = slotState.options.size();
    const scale = this.container.parent.scale.y;
    const backgroundWidth = size.width / scale;
    const backgroundHeight = size.height / scale;
    const aspectRatio = backgroundHeight / backgroundWidth;
    const backgroundAspectRatio = this.background.texture.height / this.background.texture.width;

    this.background.x = backgroundWidth / 2;
    this.background.y = backgroundHeight / 2;

    // If app aspect ratio is larger then background aspect ratio then set larger background scale
    if (aspectRatio > backgroundAspectRatio) {
      this.background.scale.set(backgroundHeight / this.background.texture.height);
    } else {
      this.background.scale.set(1);
    }

    if (this.gameTitle) {
      this.gameTitle.x = backgroundWidth / 2;
      this.gameTitle.y = backgroundHeight / 2;
    }

    if (this.slotNumberContainer) {
      if (this.slotNumberCurrency) {
        this.slotNumberCurrency.container.x = this.slotNumber.container.width / 2 - this.slotNumberCurrency.container.width / 2;
        this.slotNumberCurrency.container.y = this.slotNumber.container.height;
      }

      this.slotNumberContainer.x = backgroundWidth / 2;
      this.slotNumberContainer.y = backgroundHeight / 2;
      this.slotNumberContainer.pivot.x = (this.slotNumberContainer.width / this.slotNumberContainer.scale.x) / 2;
      this.slotNumberContainer.pivot.y = (this.slotNumberContainer.height / this.slotNumberContainer.scale.y) / 2;
    }

    if (this.labelTop) {
      this.labelTop.x = this.slotNumberContainer.x;
      this.labelTop.y = this.slotNumberContainer.y - this.slotNumberContainer.height / 2 - backgroundHeight / 22;
    }

    if (this.labelBottom) {
      this.labelBottom.x = this.slotNumberContainer.x;
      this.labelBottom.y = this.slotNumberContainer.y + this.slotNumberContainer.height / 2 + backgroundHeight / 22;
    }

    this.pressAnywhereLabel.x = backgroundWidth / 2;
    this.pressAnywhereLabel.y = backgroundHeight * 0.95;
  }

  show() {
    const onStart = () => {
      if (isSpriteVideo(this.background)) {
        const { source } = this.background.texture.baseTexture.resource;

        source.loop = true;
        source.play();
      }
    };
    const onComplete = () => {
      if (this.screenShowSoundAsset) {
        audio.play(this.screenShowSoundAsset);
      }
    };

    setTimeout(() => {
      triggerEvent('DialogChanged', {
        isOpened: true,
        isFullscreen: true,
        type: this.type,
      });
    }, 0);

    if (!this.disableOpenAnimation) {
      this.timeline.fromTo(this.container, {
        pixi: {
          alpha: 0,
        },
      }, {
        duration: 0.5,
        ease: Power2.easeOut,
        pixi: {
          alpha: 1,
        },
        onStart,
        onComplete,
      });
    } else {
      onStart();
      onComplete();
    }
  }

  hide() {
    const onComplete = async () => {
      let hasPromotion;
      let isFreeRoundsEnd;

      if (includes(toLower(this.type), 'outro') && !slotState.pendingBonusOutroScreen) {
        await slotState.updateSlotState();
        hasPromotion = slotState.activePromotion;
        isFreeRoundsEnd = isNil(slotState.activePromotion) && !slotState.availableFreeRounds;
      }

      if (isFreeRoundsEnd) {
        slotState.isFree = false;
      }

      if (isSpriteVideo(this.background)) {
        const { source } = this.background.texture.baseTexture.resource;

        source.pause();
      }

      setTimeout(() => {
        triggerEvent('DialogChanged', {
          isOpened: false,
          isFullscreen: true,
          type: this.type,
          hasPromotion,
        });
      }, 0);

      this.onClose?.();
    };

    if (!this.disableCloseAnimation) {
      this.timeline.clear();

      this.timeline.to(this.container, {
        duration: 0.5,
        ease: Power2.easeIn,
        pixi: {
          alpha: 0,
        },
        onComplete,
      });
    } else {
      onComplete();
    }
  }
}
