import { Container } from '../pixi';
import { slotState } from './SlotState';

export class SlotContent {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
  }

  setPosition() {
    const appSize = this.options.size();
    const scale = this.container.parent.scale.y;
    const backgroundWidth = appSize.width / scale;
    const backgroundHeight = appSize.height / scale;
    const controlsHeaderHeightWithPadding = slotState.controls.controlsHeader.container.height + slotState.options.uiPadding;
    const controlsMainHeight = slotState.controls.controlsMain.container.height;
    const controlsFooterHeightWithPadding = slotState.controls.controlsFooter.container.height + slotState.options.uiPadding;
    const controlsHeight = controlsHeaderHeightWithPadding + controlsMainHeight + controlsFooterHeightWithPadding;
    const contentHeight = this.container.height / this.container.scale.y;
    const margin = 10;
    const fullHeight = controlsHeight + contentHeight + margin * 3;
    const spaceLeft = backgroundHeight - controlsHeight - margin * 3;

    if (fullHeight > backgroundHeight) {
      this.container.scale.set(spaceLeft / contentHeight);
    } else {
      this.container.scale.set(1);
    }

    this.container.y = controlsHeaderHeightWithPadding + margin + spaceLeft / 2 - this.container.height / 2 - slotState.options.logoOffset * this.container.scale.y;
    this.container.x = backgroundWidth / 2 - this.container.width / 2;
  }

  getBottomPosition() {
    return this.container.y + slotState.options.logoOffset * this.container.scale.y + this.container.height;
  }
}
