import { Container, Sprite } from '../pixi';
import { SlotProgress } from './SlotProgress';
import { slotState } from './SlotState';

/*
This is a component that can show
different components inside, like
progress bar.
*/
export class SlotReelsHeader {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.logo = undefined;
    this.progress = undefined;

    this.setup();
  }

  get height() {
    return this.container.getLocalBounds().height;
  }

  setup() {
    const logoResource = this.options.assets.logo.resource;

    if (logoResource) {
      this.logo = new Sprite(logoResource);
      this.logo.anchor.set(0.5, 0);
      this.logo.y = this.options.logoOffset;
      this.logo.x = this.options.calcWidth / 2;

      if (this.options.isPhone) {
        this.logo.x += 1;
      }

      this.container.addChild(this.logo);
    }

    if (this.options.progress) {
      this.progress = new SlotProgress(this.container);

      if (this.logo) {
        this.progress.container.y = this.logo.height;
      }

      this.container.addChild(this.progress.container);
    }
  }
}
