import { SlotParticleType } from '../models';
import { getParticleColorBehavior } from '../utility/Utility';
import { slotState } from './SlotState';

export function SlotWinlineTrailEmitter(type, colors = []) {
  switch (type) {
    case SlotParticleType.Comet:
    default:
      return {
        addAtBack: false,
        emitterLifetime: -1,
        frequency: 0.001,
        maxParticles: 1000,
        spawnChance: 1,
        particlesPerWave: 1,
        behaviors: [
          getParticleColorBehavior(colors),
          {
            type: 'alpha',
            config: {
              alpha: {
                list: [
                  {
                    value: 1,
                    time: 0,
                  },
                  {
                    value: 0,
                    time: 0.8,
                  },
                  {
                    value: 0,
                    time: 1,
                  },
                ],
              },
            },
          },
          {
            type: 'scale',
            config: {
              scale: {
                list: [
                  {
                    value: 0.15,
                    time: 0,
                  },
                  {
                    value: 0.25,
                    time: 1,
                  },
                ],
              },
              minMult: 2,
            },
          },
          {
            type: 'rotationStatic',
            config: {
              min: -90,
              max: 90,
            },
          },
          {
            type: 'rotation',
            config: {
              minStart: 0,
              maxStart: 180,
              minSpeed: 30,
              maxSpeed: 190,
              accel: 0,
            },
          },
          {
            type: 'spawnShape',
            config: {
              type: 'torus',
              data: {
                x: 0,
                y: 0,
                radius: 1,
              },
            },
          },
          {
            type: 'textureOrdered',
            config: {
              textures: [
                slotState.options.assets.particleLine.resource,
                slotState.options.assets.particleSpark.resource,
              ],
            },
          },
        ],
        lifetime: {
          min: 1,
          max: 1,
        },
      };
  }
}
