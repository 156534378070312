import animate, { Power2 } from 'gsap';
import { assign, defaultTo } from 'lodash';
import { Container, Sprite, Texture } from '@/pixi';

export class Tabs {
  constructor({
    items = [],
    playTapSound,
    onChange,
    colorActive = 0xffe843,
    padding = 48,
  }) {
    this.items = items;
    this.playTapSound = playTapSound;
    this.onChange = onChange;
    this.colorActive = colorActive;
    this.padding = padding;

    this.container = new Container();
    this.tabNavigation = new Container();
    this.tabNavigationLineBottom = this.createLine();
    this.tabNavigationLineActive = this.createLine(4, 1, this.colorActive);
    this.tabNavigationItems = new Container();
    this.tabContent = new Container();
    this.tabIndex = 0;
    this.tabItemWidth = 0;

    this.setup();
  }

  createLine(height, alpha, color) {
    const line = new Sprite(Texture.WHITE);

    line.height = defaultTo(height, 2);
    line.alpha = defaultTo(alpha, 0.1);

    if (color) {
      line.tint = color;
    }

    return line;
  }

  setup() {
    this.tabNavigation.addChild(this.tabNavigationItems);
    this.tabNavigation.addChild(this.tabNavigationLineBottom);
    this.tabNavigation.addChild(this.tabNavigationLineActive);
    this.container.addChild(this.tabNavigation);
    this.container.addChild(this.tabContent);

    this.items.forEach((item, itemIndex) => {
      const container = new Container();
      const icon = new Sprite(item.iconAsset.resource);
      const hitArea = new Sprite(Texture.EMPTY);

      icon.anchor.set(0.5, 0);
      hitArea.eventMode = 'static';
      hitArea.cursor = 'pointer';
      hitArea.on('pointertap', () => {
        if (this.tabIndex !== itemIndex) {
          this.playTapSound?.();
          this.showTab.bind(this)(itemIndex);
        }
      });

      container.addChild(icon);
      container.addChild(hitArea);

      this.tabNavigationItems.addChild(container);

      if (item.content) {
        this.tabContent.addChild(item.content.container);
      }
    });
  }

  setPosition({ width, height }) {
    const itemsCount = this.tabNavigationItems.children.length;

    this.tabItemWidth = (width / itemsCount);

    this.items.forEach((item, itemIndex) => {
      const itemContainer = this.tabNavigationItems.children[itemIndex];
      const itemSprite = itemContainer.children[0];
      const itemHitArea = itemContainer.children[1];

      itemContainer.x = this.tabItemWidth * itemIndex;
      itemHitArea.width = this.tabItemWidth;
      itemHitArea.height = itemSprite.height + (this.padding * 2);
      itemSprite.y = this.padding;
      itemSprite.x = this.tabItemWidth / 2;

      if (item.content) {
        item.content.setPosition({ width, height });
      }
    });

    this.tabNavigationLineBottom.width = width;
    this.tabNavigationLineBottom.y = this.tabNavigationItems.height;
    this.tabNavigationLineActive.width = this.tabItemWidth;
    this.tabNavigationLineActive.y = -this.tabNavigationLineActive.height / 2;
    this.tabContent.y = this.tabNavigationLineBottom.y + this.tabNavigationLineBottom.height;
  }

  setTabIconColor(tabIndex, color = 0xffffff) {
    this.tabNavigationItems.children[tabIndex].children[0].tint = color;
  }

  showTab(itemIndex) {
    const tabIndex = defaultTo(itemIndex, this.tabIndex);

    this.items.forEach((item, index) => {
      if (item.content) {
        assign(item.content.container, {
          visible: index === tabIndex,
        });
      }

      this.setTabIconColor(index);
    });

    this.items[tabIndex].content?.setData?.();
    this.items[tabIndex].onShow?.();

    if (this.tabIndex !== tabIndex) {
      animate.to(this.tabNavigationLineActive, {
        duration: 0.2,
        ease: Power2.easeOut,
        pixi: {
          x: this.tabItemWidth * tabIndex,
        },
      });

      this.items[this.tabIndex].onHide?.();

      this.onChange?.(this.items[tabIndex]);

      this.setTabIconColor(tabIndex, this.colorActive);

      this.tabIndex = tabIndex;
    }
  }

  hideActiveTab() {
    this.items[this.tabIndex].onHide?.();
  }
}
