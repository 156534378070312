import { defaultTo, merge } from 'lodash';
import * as particles from '@pixi/particle-emitter';
import { getParticleColorBehavior } from '../utility/Utility';
import { slotState } from './SlotState';

export class SlotBurstEmitter {
  constructor(container, colors = [], options = {}) {
    const settings = merge({
      maxLifetime: 1.0,
      maxParticles: 1000,
      minLifetime: 0.5,
      minScaleMultiplier: 15,
      particleFrequency: 0.1,
    }, options);

    this.emitter = new particles.Emitter(container, {
      autoUpdate: true,
      emit: false,
      addAtBack: false,
      emitterLifetime: 0.3,
      frequency: settings.particleFrequency,
      maxParticles: settings.maxParticles,
      spawnChance: 1,
      spawnType: 'burst',
      particlesPerWave: 15,
      behaviors: [
        getParticleColorBehavior(colors),
        {
          type: 'alpha',
          config: {
            alpha: {
              list: [
                {
                  value: 0.8,
                  time: 0,
                },
                {
                  value: 0.2,
                  time: 1,
                },
              ],
            },
          },
        },
        {
          type: 'scale',
          config: {
            scale: {
              list: [
                {
                  value: 0.5,
                  time: 0,
                },
                {
                  value: 1.0,
                  time: 1,
                },
              ],
            },
            minMult: settings.minScaleMultiplier,
          },
        },
        {
          type: 'spawnShape',
          config: {
            type: 'torus',
            data: {
              x: 0,
              y: 0,
              radius: 1,
            },
          },
        },
        {
          type: 'textureOrdered',
          config: {
            textures: [
              slotState.options.assets.particleLine.resource,
              slotState.options.assets.particleSpark.resource,
            ],
          },
        },
      ],
      lifetime: {
        min: settings.minLifetime,
        max: settings.maxLifetime,
      },
    });
  }

  show(position) {
    const spawnPosition = defaultTo(position, { x: 0, y: 0 });
    this.emitter.updateSpawnPos(spawnPosition.x, spawnPosition.y);
    this.emitter.emit = true;
  }

  hide() {
    this.emitter.emit = false;
  }
}
